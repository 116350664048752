import React, { createContext, useContext, useReducer } from 'react';
import reducer from '../reducer/CommonReducer';
// Define an initial state
const initialState = {
  count: 1000,
  username: 'test user name',
  userid: '',
  uname: '',
  uphone: '',
  uphoto: '',
  uemail: '',
  userData: null,
  locationList: [],
  // Rent post location
  locationArea: '',
  locationAreaName: '',
  androidAPK: false
};

// Create the context
const AppContext = createContext();

// Create a context provider component
export const AppProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <AppContext.Provider value={{ state, dispatch }}>
      {children}
    </AppContext.Provider>
  );
};

export default AppContext;