// PhoneSignIn.js
import React from 'react';

const Registration = (props) => {
  return (
      <iframe 
          src={`https://baribodol.com/registration_v2.php?phone_number=88${props.phone_number}`}
          // src={`https://baribodol.com/php-redirect.php`}
          title="Rent details loader" 
          width="100%"
          height="800px"
        />

  );
};

export default Registration;
