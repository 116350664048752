import React, { useEffect, useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Image from 'react-bootstrap/Image';
import Spinner from 'react-bootstrap/Spinner';

import AppContext from '../../../context/AppContext';

// Component
import Header from '../../layout/Header';

import { getUserDataFromLS, updateLocalStorageProperty } from '../../../helpers/utility';
import { updatePersonalInfo } from '../../api/Api'; // Import your API functions
import { Button } from 'react-bootstrap';


function Profile() {
  const [userid, setUserId] = useState('');
  const [uname, setUname] = useState('');
  const [unameTemp, setUnameTemp] = useState('');
  const [uphone, setUphone] = useState('');
  const [uphoto, setUphoto] = useState('');
  const [uemail, setUemail] = useState('');
  const [spinner, setSpinner] = useState(false);

  const { state } = useContext(AppContext);

  const getUserData = () => {
    setUserId(getUserDataFromLS("userid"));
    setUname(getUserDataFromLS("uname"));
    setUnameTemp(getUserDataFromLS("uname"));
    setUphone(getUserDataFromLS("uphone"));
    setUphoto(getUserDataFromLS("uphoto"));
    setUemail(getUserDataFromLS("uemail"));
    spinner && setSpinner(false);
  };

  useEffect(() => {
    getUserData();
  }, []);

  const handleInputChange = (e, type) => {
    if(type === "uname") {
      setUname(e.target.value);
    } else if (type === "uemail") {
      setUemail(e.target.value);
    }
  };

  const handleSubmit = async (event) => {
    // event.preventDefault(); // Prevent the default form submission behavior
    setSpinner(true);
    try {
      // Assuming userid, uname, uphone, and uemail are state variables
      await updatePersonalInfo(userid, uname, uphone, uemail);
      setSpinner(false);
      updateLocalStorageProperty('uname', uname);
      updateLocalStorageProperty('uemail', uemail);
    } catch (error) {
      // Handle errors, e.g., show an error message
      console.error('Error updating personal info:', error);
    }
  };



  return (
    <Container>
          <Row>
            <Col md="3">
              <Header />
            </Col>
            <Col md="6">
              <div className="content-section pt-4 ps-4 profile">
                <div className="d-flex justify-content-center align-items-center flex-column">
                    <Image className="uphoto mb-3" src={`https://baribodol.com/images/profilephoto/${uphoto}`} fluid roundedCircle />
                    <h2 className="greetings mb-4">স্বাগতম, {unameTemp}</h2>
                </div>
                <Form>
                  <FloatingLabel
                      controlId="floatingInput"
                      label="নাম"
                      className="mb-3"
                  >
                      <Form.Control type="text" placeholder="আপনার নাম" value={uname} onChange={(event) => handleInputChange(event, 'uname')}/>
                  </FloatingLabel>

                  <FloatingLabel
                      controlId="floatingInput"
                      label="মোবাইল নম্বর *"
                      className="mb-3"
                  >
                      <Form.Control type="text" placeholder="মোবাইল নম্বর" value={uphone} readOnly />
                  </FloatingLabel>

                  <FloatingLabel
                      controlId="floatingInput"
                      label="ইমেইল এড্রেস"
                      className="mb-3"
                  >
                      <Form.Control type="email" placeholder="ইমেইল এড্রেস" value={uemail} onChange={(event) => handleInputChange(event, 'uemail')}/>
                  </FloatingLabel>
                  <Button variant="dark" className="btn-outline" onClick={()=>handleSubmit()} disabled={(uname === '' || uemail === '') || spinner}>
                      সাবমিট {spinner && <Spinner size="sm" />}
                  </Button>
                </Form>
              </div>
            </Col>
        </Row>
    </Container>
  )
}

export default Profile;
