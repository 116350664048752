// Define your reducer function
const reducer = (state, action) => {
    switch (action.type) {
        case 'UPDATE_USERDATA':
          return { ...state, userData: action.payload };
        case 'UPDATE_LOCATIONLIST':
          return { ...state, locationList: action.payload };
        case 'UPDATE_LOCATION_AREA':
          return { ...state, locationArea: action.payload };
        case 'UPDATE_LOCATION_AREA_NAME':
          return { ...state, locationAreaName: action.payload };
        case 'SET_ANDROID_APK':
          return { ...state, androidAPK: action.payload };
      default:
        return state;
    }
  };
  
  export default reducer;
  