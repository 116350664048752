import React, { useEffect, useState, useContext } from 'react';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import ListGroup from 'react-bootstrap/ListGroup';
import axios from 'axios';
import qs from 'qs';
import Modal from 'react-bootstrap/Modal';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { Link, useParams } from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import Badge from 'react-bootstrap/Badge';
import CloseButton from 'react-bootstrap/CloseButton';
import AppContext from '../../context/AppContext';
import { getIsAndroidAPKFromLS } from '../../helpers/utility';

// Content Loader
import Catalog from '../loader/Catalog';

// Component
import Header from '../layout/Header';
import NoData from '../status/NoData';
import RentDetails from './RentDetails';
import Keywords from './staticPages/Keywords';

function RentList() {

  const { device } = useParams();
  // const[ device, setDevice ] = useState(null);

  const { state, dispatch } = useContext(AppContext);

  const[rentList, setRentList] = useState([]);
  const[rentId, setRentId] = useState('');
  const[title, setTitle] = useState('');

  const[totalPage, setTotalPage] = useState('');
  const[currentPage, setCurrentPage] = useState(1);
  
  
  // Filter button stats temp
  const [segmentTemp, setSegmentTemp] = useState('');
  const [flatTypeTemp, setFlatTypeTemp] = useState('');
  const [rentTypeTemp, setRentTypeTemp] = useState('');
  const [bedRoomTemp, setBedRoomTemp] = useState('');
  const [washRoomTemp, setWashRoomTemp] = useState('');
  const [rentStartMonthTemp, setRentStartMonthTemp] = useState('');
  const [minRentCostTemp, setMinRentCostTemp] = useState('');
  const [maxRentCostTemp, setMaxRentCostTemp] = useState('');

  // Rent list filters
  const [flatType, setFlatType] = useState('');
  const [rentType, setRentType] = useState('');
  const [segment, setSegmant] = useState('');
  const [bedRoom, setBedRoom] = useState('');
  const [washRoom, setWashRoom] = useState('');
  const [rentStartMonth, setRentStartMonth] = useState('');
  const [minRentCost, setMinRentCost] = useState('');
  const [maxRentCost, setMaxRentCost] = useState('');

  // Filter counts
  const [filterCounts, setFilterCounts] = useState(0);



  const [show, setShow] = useState(false);
  const [showFilters, setShowFilters] = useState(false);

  const [showContentLoader, setShowContentLoader] = useState(true);
  
  
  const [searchText, setSearchText] = useState('');

  // Visibility for location list while searching
  const [locationArea, setLocationArea] = useState('');
  const [locationAreaName, setLocationAreaName] = useState('');
  const [visibleLocationList, setVisibleLocationList] = useState(false);


  const handleInputChangeRentCost = (event, inputType) => {
    const newValue = event.target.value;

    if (inputType === 'min') {
      setMinRentCostTemp(newValue);
      handleFilterCounts(minRentCostTemp);
    } else if (inputType === 'max') {
      setMaxRentCostTemp(newValue);
      handleFilterCounts(maxRentCostTemp);
    }
  };

  const handleClose = () => setShow(false);
  const handleShow = (rentid, title) => {
    setShow(true);
    setRentId(rentid);
    setTitle(title);
    // alert(1);
  };

  const handleCloseFilters = () => setShowFilters(false);
  const handleShowFilters = () => {
    setShowFilters(true)
  };

  const [isSectionVisible, setSectionVisibility] = useState(true);


  // Reset All
  const handleResetAll = () => {
    handleResetFilterBtn();
    setFlatType('');
    setSegmant(0);
  };
  

  // Reset filter
  const handleResetFilterBtn = () => {
    setSegmentTemp('');
    setFlatTypeTemp('');
    setRentTypeTemp('');
    setBedRoomTemp('');
    setWashRoomTemp('');
    setRentStartMonthTemp('');
    setMinRentCostTemp('');
    setMaxRentCostTemp('');
    setFilterCounts(0);
  };
  // Submit search filter
  const handleSubmitFilterBtn = () => {
    setSegmant(segmentTemp);
    setFlatType(flatTypeTemp);
    setRentType(rentTypeTemp);
    setBedRoom(bedRoomTemp);
    setWashRoom(washRoomTemp);
    setRentStartMonth(rentStartMonthTemp);
    setMinRentCost(minRentCostTemp);
    setMaxRentCost(maxRentCostTemp);
    handleCloseFilters();
    setShowContentLoader(true);
  };


  const handlePage = (type) => {
    if (type === "prev" && currentPage > 1) {
      setCurrentPage(currentPage - 1);
      setShowContentLoader(true);
    } else if (type === "next" && totalPage > currentPage) {
      setCurrentPage(currentPage + 1);
      setShowContentLoader(true);
    }
}

  const handleFilterBtn = (type) => {
      // setFlatType(type);
      let newFlatType = type === flatType ? '' : type;
      setFlatType(newFlatType);

      setSegmant(0);
      setCurrentPage(1);
  }

  // const handleFilterBtnRentType = (type) => {
  //     setRentType(type);
  //     setFlatType('');
  //     setCurrentPage(1);
  // }

  const handleFilterBtnSegment = (type) => {
    // setSegmant(type);

    let newSegment = type === segment ? '' : type;
    setSegmant(newSegment);

    setFlatType('');
    setCurrentPage(1);
  }

  const handleFilterCounts = (type) => {
    type === '' &&
    setFilterCounts(filterCounts + 1)
  }

  const handleFilterBtnClick = (type) => {
    let newSegmentTemp = type === segmentTemp ? '' : type;
    setSegmentTemp(newSegmentTemp);
    handleFilterCounts(segmentTemp);
  }

  const handleFilterBtnClickFlatType = (type) => {
    let newFlatTypeTemp = type === flatTypeTemp ? '' : type;
    setFlatTypeTemp(newFlatTypeTemp);
    handleFilterCounts(flatTypeTemp);
  }

  const handleFilterBtnClickRentType = (type) => {
    let newRentTypeTemp = type === rentTypeTemp ? '' : type;
    setRentTypeTemp(newRentTypeTemp);
    handleFilterCounts(rentTypeTemp);
  }

  const handleFilterBtnClickBedRoom = (type) => {
    let newBedRoomTemp = type === bedRoomTemp ? '' : type;
    setBedRoomTemp(newBedRoomTemp);
    handleFilterCounts(bedRoomTemp);
  }

  const handleFilterBtnClickWashRoom = (type) => {
    let newWashRoomTemp = type === washRoomTemp ? '' : type;
    setWashRoomTemp(newWashRoomTemp);
    handleFilterCounts(washRoomTemp);
  }

  const handleSelectChange = (event) => {
    const selectedValue = event.target.value;
    setRentStartMonthTemp(selectedValue);
    handleFilterCounts(rentStartMonthTemp);
  };

  const handleFilterControlRemove = (type) => {
    // setFilterCounts(filterCounts > 0 && filterCounts - 1);
    // minRentCostTemp !== '' && maxRentCostTemp !== '' && setFilterCounts(filterCounts > 0 && filterCounts - 2);
    setFilterCounts(
      filterCounts > 0 ? (minRentCostTemp !== '' && maxRentCostTemp !== '' ? filterCounts - 2 : filterCounts - 1) : filterCounts
    );
    
    switch (type) {
      case 'locationArea':
        setLocationArea('');
        break;
      case 'segmentTemp':
        setSegmentTemp('');
        setSegmant('');
        break;
      case 'flatTypeTemp':
        setFlatTypeTemp('');
        setFlatType('');
        break;
      case 'rentTypeTemp':
        setRentTypeTemp('');
        setRentType('');
        break;
      case 'bedRoomTemp':
          setBedRoomTemp('');
          setBedRoom('');
          break;
      case 'washRoomTemp':
        setWashRoomTemp('');
        setWashRoom('');
        break;
      case 'rentStartMonthTemp':
        setRentStartMonthTemp('');
        setRentStartMonth('');
        break;
      case 'rentConstTemp':
          setMinRentCostTemp('');
          setMaxRentCostTemp('');
          setMinRentCost('');
          setMaxRentCost('');
          break;
      default:
        // Handle other cases if needed
    }
    // handleSubmitFilterBtn();
    // fetchData();
  };
  
  // Define a mapping object for rentType labels

  const segmentLabels = {
    0 : 'বাসা ভাড়া',
    1 : 'ভাড়া খুঁজছি',
    2 : 'কমার্সিয়াল স্পেস',
    3 : 'দোকান',
    4 : 'গ্যারেজ',
    5 : 'অফিস',
    10 : 'অন্যান্য',
  };

  const flatTypeLabels = {
    'flat,': 'ফ্লাট ভাড়া',
    'seat,': 'সিট ভাড়া',
    'sublet,': 'সাবলেট ভাড়া',
  };


  const rentTypeLabels = {
    'bachelor,': 'ব্যাচেলর',
    'family,': 'ফ্যামিলি',
    'smallfamily,': 'ছোট ফ্যামিলি',
    'onlymalestudent,': 'শুধুমাত্র ছাত্র',
    'onlyfemalestudent,': 'শুধুমাত্র ছাত্রী',
    'onlymale,': 'শুধুমাত্র পুরুষ',
    'onlyfemale,': 'শুধুমাত্র মহিলা',
    'malejobholder,': 'পুরুষ চাকরিজীবী',
    'femalejobholder,': 'মহিলা চাকরিজীবী',
  };

  const bedRoomtLabels = {
    1 : '১',
    2 : '২',
    3 : '৩',
    4 : '৪',
    5 : '৫',
  };

  const washRoomtLabels = {
    1 : '১',
    2 : '২',
    3 : '৩',
    4 : '৪',
    5 : '৫',
  };

  const rentStartMonthLabels = {
    'January' : 'জানুয়ারী',
    'February' : 'ফেব্রুয়ারী',
    'March' : 'মার্চ',
    'April' : 'এপ্রিল',
    'May' : 'মে',
    'June' : 'জুন',
    'July' : 'জুলাই',
    'August' : 'আগস্ট',
    'September' : 'সেপ্টেম্বর',
    'October' : 'অক্টোবর',
    'November' : 'নভেম্বর',
    'December' : 'ডিসেম্বর',
  };

const catalogComponents = [];

for (let i = 0; i < 5; i++) {
  catalogComponents.push(<Catalog key={i} />);
}

  // eslint-disable-next-line react-hooks/exhaustive-deps
  async function fetchData() {
    setShowContentLoader(true);
    try {
      // Make an HTTP GET request using Axios
      const URL = "https://baribodol.com/api/rentsearch_promo.php?locationArea=" + locationArea + "&flatType=" + flatType + "&rentType=" + rentType +"&page=" + currentPage + '&rentStartMonth=' + rentStartMonth + '&minRentCost=' + minRentCost + '&maxRentCost=' + maxRentCost + '&bedroom=' + bedRoom + '&washroom=' + washRoom + '&segment=' + segment;
      // https://baribodol.com/api/rentsearch_promo.php?locationArea=' + locationid + '&flatType=' + flatType + '&rentType=' + rentType + '&rentStartMonth=' + rentStartMonth + '&minRentCost=' + minRentCost + '&maxRentCost=' + maxRentCost + '&bedroom=' + bedroom + '&washroom=' + washroom + '&page=' + currentPage + '&keyword&itemPerPage&rentsort=' + rentSort + '&segment=' + segment
      
      const response = await axios.get(URL);
      // Check if the response status code is 200 (OK)
      if (response.status === 200) {
        // Access the data from the response
        const data = response.data;
        setRentList(data.data);
        setTotalPage(data.totalpages);
        setShowContentLoader(false);
        // console.log('Data:', data.totalpages);
      } else {
        console.error('Unexpected response status:', response.status);
      }
    } catch (error) {
      // Handle errors, including network errors and HTTP errors
      console.error('An error occurred:', error);
    }
  }

  const saveAndroidAPKTOLS = (androidData) => {
    try {
      // Convert the user data to a JSON string
      const androidDataString = JSON.stringify(androidData);
  
      // Store the JSON string in LocalStorage with a specific key
      localStorage.setItem('androidAPK', androidDataString);
    } catch (error) {
      // Handle errors, e.g., when LocalStorage is full or inoperative
      console.error('Error saving user data to LocalStorage:', error);
    }
  };

  useEffect(() => {
    // Check if the custom HTTP header 'X-From-APK' is present in the request headers
    const fromApk = !!navigator.userAgent.includes('Android') && navigator.userAgent.includes('wv');

    if (fromApk) {
      // dispatch({ type: 'SET_ANDROID_APK', payload: true });
      // Perform actions specific to APK loading
      saveAndroidAPKTOLS(true);
    } else {
      // setAndroidAPK('Website is loaded from a mobile browser');
      // Perform actions specific to mobile browser loading
      saveAndroidAPKTOLS(false);
    }
  }, []);

  useEffect(() => {
    const locationList = localStorage.getItem('locationList');
    if (locationList === null) {
      fetchDataLocationList();
    } else {
      dispatch({ type: 'UPDATE_LOCATIONLIST', payload: JSON.parse(locationList) });
    }
    
    
    
    // Function to handle window resize
    const handleResize = () => {
      // Check the window width and update the state accordingly
      if (window.innerWidth < 991) {
        setSectionVisibility(false);
      } else {
        setSectionVisibility(true);
      }
    };

    // Add a window resize event listener
    window.addEventListener('resize', handleResize);

    // Call handleResize initially to set the initial state
    handleResize();

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    // Side effect code goes here
    // It will run after the component renders
    // setFlatType('');
    // if (paramDevice !== undefined ) {
      // setDevice(paramDevice);
    // }
    
    fetchData();
    // Return a cleanup function if needed
    return () => {
      // Cleanup code goes here
      // It will run when the component unmounts or when dependencies change
    };
  }, [locationArea, flatType, rentType, segment, bedRoom, washRoom, rentStartMonth, minRentCost, maxRentCost, currentPage]);

  useEffect(() => {
    // window.scrollTo(0, 0)
    window.scrollTo({
      top: 0,
      behavior: 'smooth' // Optional: Add smooth scrolling animation
    });
  }, [currentPage])

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
      
    // Get specific parameter values
    const device = queryParams.get('d');
    

    // Log the values to the console
    // console.log(`d: ${device}`);
  }, [])

  function addEllipsis(str, maxLength) {
    if (str.length > maxLength) {
      return str.substring(0, maxLength) + '...';
    }
    return str;
  }

  const saveLocationListToLocalStorage = (data) => {
    try {
      // Convert the user data to a JSON string
      const userDataString = JSON.stringify(data);
  
      // Store the JSON string in LocalStorage with a specific key
      localStorage.setItem('locationList', userDataString);
    } catch (error) {
      // Handle errors, e.g., when LocalStorage is full or inoperative
      console.error('Error saving user data to LocalStorage:', error);
    }
  };

  async function fetchDataLocationList() {
    try {
      // Make an HTTP POST request using Axios
      const URL = 'https://baribodol.com/api/getlocationlist.php';
  
      const response = await axios.get(URL);
  
      if (response.status === 200) {
        // console.log(response.data.data);
        // alert('l')
        saveLocationListToLocalStorage(response.data.data);
        dispatch({ type: 'UPDATE_LOCATIONLIST', payload: response.data.data });
      } else {
        console.error('Unexpected response status:', response.status);
      }
    } catch (error) {
      // Handle errors, including network errors and HTTP errors
      console.error('An error occurred:', error);
    }
  }

  const handleInputChange = (e) => {
    setSearchText(e.target.value);
    // console.log(e.target.value.length);
    e.target.value.length > 2 && setVisibleLocationList(true);
  };

  const handleLocationArea = (locationid, locationNameBangla) => {
    setSearchText('');
    setLocationArea(locationid);
    setLocationAreaName(locationNameBangla);
    setVisibleLocationList(false);
  };

  

  // const filteredLocations = state.locationList.filter((location) =>
  //   location.locationName.toLowerCase().includes(searchText.toLowerCase()) ||
  //   location.locationNameBangla.includes(searchText)
  // );
  

  const locationList = state.locationList;

  const filteredLocations = locationList.filter((location) =>
    location.locationName.toLowerCase().includes(searchText.toLowerCase()) ||
    location.locationNameBangla.includes(searchText)
  );



  const rentsAll = rentList.map((item) =>
      // <Card className="border-0 text-start rounded-0 rent-default" onClick={() => handleShow(item.rentid, item.title)} key={item.rentid}>
    <>
    <Card className="border-0 text-start rounded-0 rent-default">
      <Link className="p-0 text-start border-0 btn-card" variant="" to={`/bn/rent/${item.rentid}`}>
        <div className="img-wrapper position-relative">
          <Card.Img variant="top" src={item.thumb} alt={item.title} />
          <div className="thumb-wrapper position-absolute">
            <ListGroup className="d-inline-block">
              {item.totalimages !== 0 &&
                <ListGroup.Item className="bg-transparent border-0 p-0 d-inline-block me-2">
                  <div className="thumb-count text-white">
                  <svg className="icon me-1" height="16" width="16" viewBox="0 0 32 32"><path fill="#ffffff" d="M 2 5 L 2 27 L 30 27 L 30 5 Z M 4 7 L 28 7 L 28 20.90625 L 22.71875 15.59375 L 22 14.875 L 17.46875 19.40625 L 11.71875 13.59375 L 11 12.875 L 4 19.875 Z M 24 9 C 22.894531 9 22 9.894531 22 11 C 22 12.105469 22.894531 13 24 13 C 25.105469 13 26 12.105469 26 11 C 26 9.894531 25.105469 9 24 9 Z M 11 15.71875 L 20.1875 25 L 4 25 L 4 22.71875 Z M 22 17.71875 L 28 23.71875 L 28 25 L 23.03125 25 L 18.875 20.8125 Z"/></svg>
                    {item.totalimages}
                  </div>
                </ListGroup.Item>
              }

              <ListGroup.Item className="bg-transparent border-0 p-0 d-inline-block">
                <div className="thumb-count text-white">
                  <svg className="icon me-1" height="16" width="16" viewBox="0 0 24 24"><path fill="#ffffff" d="M23.5,17L18.5,22L15,18.5L16.5,17L18.5,19L22,15.5L23.5,17M12,9A3,3 0 0,1 15,12A3,3 0 0,1 12,15A3,3 0 0,1 9,12A3,3 0 0,1 12,9M12,4.5C17,4.5 21.27,7.61 23,12C22.75,12.65 22.44,13.26 22.08,13.85C21.5,13.5 20.86,13.25 20.18,13.12L20.82,12C19.17,8.64 15.76,6.5 12,6.5C8.24,6.5 4.83,8.64 3.18,12C4.83,15.36 8.24,17.5 12,17.5L13.21,17.43C13.07,17.93 13,18.46 13,19V19.46L12,19.5C7,19.5 2.73,16.39 1,12C2.73,7.61 7,4.5 12,4.5Z"></path></svg>
                  {item.viewcount}
                </div>
              </ListGroup.Item>
            </ListGroup>
            </div>
          </div>
          <Card.Body className="px-0 pb-0">
            <Card.Title className="mb-2">
              <h2 className="fs-6">{addEllipsis(item.title, 50)}</h2>
            </Card.Title>
            <Card.Subtitle className='mb-2'>{item.location !== null && addEllipsis(item.location, 40)}</Card.Subtitle>
            <Card.Subtitle className='mb-2'>{item.month}</Card.Subtitle>
            <Card.Text>{item.cost} {item.costduration}</Card.Text>
          </Card.Body>
      </Link>
    </Card>
    <hr className="my-4 bg-light"/>
    </>
  );


  return (
    <div>
      <Modal show={showFilters} fullscreen onHide={handleCloseFilters} className="bg-blur">
          <Modal.Header>
            <Button variant="link" className="p-0" onClick={handleCloseFilters}>
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">   <path d="M9.96082 5.08575L11.0393 6.16425L5.95357 11.25H21.0008V12.75H5.95357L11.0393 17.8358L9.96082 18.9143L3.58582 12.5393L3.06982 12L3.58582 11.4608L9.96082 5.08575Z" fill="#333333"/> </svg>
            </Button>
            <Modal.Title className="fs-20 m-auto w-100 text-center">সার্চ ফিল্টার</Modal.Title>
          </Modal.Header>
          <Modal.Body>
          <Container>
            <div className="filters-all">
              <div className="filter-property-type mb-4">
                <Button variant="light" className={`btn-filter-outline btn-rounded me-2 ${segmentTemp === '' && 'active'}`} onClick={()=>handleFilterBtnClick('')}>যেকোনো</Button>
                <Button variant="light" className={`btn-filter-outline btn-rounded me-2 ${segmentTemp === 0 && 'active'}`} onClick={()=>handleFilterBtnClick(0)}>বাসা ভাড়া</Button>
                <Button variant="light" className={`btn-filter-outline btn-rounded me-2 ${segmentTemp === 1 && 'active'}`} onClick={()=>handleFilterBtnClick(1)}>ভাড়া খুঁজছি</Button>
                <Button variant="light" className={`btn-filter-outline btn-rounded me-2 ${segmentTemp === 2 && 'active'}`} onClick={()=>handleFilterBtnClick(2)}>কমার্সিয়াল স্পেস</Button>
                <Button variant="light" className={`btn-filter-outline btn-rounded me-2 ${segmentTemp === 3 && 'active'}`} onClick={()=>handleFilterBtnClick(3)}>দোকান</Button>
                <Button variant="light" className={`btn-filter-outline btn-rounded me-2 ${segmentTemp === 4 && 'active'}`} onClick={()=>handleFilterBtnClick(4)}>গ্যারেজ</Button>
                <Button variant="light" className={`btn-filter-outline btn-rounded me-2 ${segmentTemp === 5 && 'active'}`} onClick={()=>handleFilterBtnClick(5)}>অফিস</Button>
                <Button variant="light" className={`btn-filter-outline btn-rounded ${segmentTemp === 10 && 'active'}`} onClick={()=>handleFilterBtnClick(10)}>অন্যান্য</Button>
              </div>
              <div className="filter-flat-type mb-4">
                <h2 className="fs-18 mb-3">বাসার ধরন</h2>
                <Button variant="light" className={`btn-filter-outline btn-rounded me-2 ${flatTypeTemp === '' && 'active'}`} onClick={()=>handleFilterBtnClickFlatType('')}>যেকোনো</Button>
                <Button variant="light" className={`btn-filter-outline btn-rounded me-2 ${flatTypeTemp === 'flat,' && 'active'}`} onClick={()=>handleFilterBtnClickFlatType('flat,')}>ফ্লাট ভাড়া</Button>
                <Button variant="light" className={`btn-filter-outline btn-rounded me-2 ${flatTypeTemp === 'seat,' && 'active'}`} onClick={()=>handleFilterBtnClickFlatType('seat,')}>সিট ভাড়া</Button>
                <Button variant="light" className={`btn-filter-outline btn-rounded ${flatTypeTemp === 'sublet,' && 'active'}`} onClick={()=>handleFilterBtnClickFlatType('sublet,')}>সাবলেট ভাড়া</Button>
              </div>
              <div className="filter-rent-type mb-4">
                <h2 className="fs-18 mb-3">ভাড়ার ধরন</h2>
                <Button variant="light" className={`btn-filter-outline btn-rounded me-2 mb-2 ${rentTypeTemp === '' && 'active'}`} onClick={()=>handleFilterBtnClickRentType('')}>যেকোনো</Button>
                <Button variant="light" className={`btn-filter-outline btn-rounded me-2 mb-2 ${rentTypeTemp === 'bachelor,' && 'active'}`} onClick={()=>handleFilterBtnClickRentType('bachelor,')}>ব্যাচেলর</Button>
                <Button variant="light" className={`btn-filter-outline btn-rounded me-2 mb-2 ${rentTypeTemp === 'family,' && 'active'}`} onClick={()=>handleFilterBtnClickRentType('family,')}>ফ্যামিলি</Button>
                <Button variant="light" className={`btn-filter-outline btn-rounded me-2 mb-2 ${rentTypeTemp === 'smallfamily,' && 'active'}`} onClick={()=>handleFilterBtnClickRentType('smallfamily,')}>ছোট ফ্যামিলি</Button>
                <Button variant="light" className={`btn-filter-outline btn-rounded me-2 mb-2 ${rentTypeTemp === 'onlymalestudent,' && 'active'}`} onClick={()=>handleFilterBtnClickRentType('onlymalestudent,')}>শুধুমাত্র ছাত্র</Button>
                <Button variant="light" className={`btn-filter-outline btn-rounded me-2 mb-2 ${rentTypeTemp === 'onlyfemalestudent,' && 'active'}`} onClick={()=>handleFilterBtnClickRentType('onlyfemalestudent,')}>শুধুমাত্র ছাত্রী</Button>
                <Button variant="light" className={`btn-filter-outline btn-rounded me-2 mb-2 ${rentTypeTemp === 'onlymale,' && 'active'}`} onClick={()=>handleFilterBtnClickRentType('onlymale,')}>শুধুমাত্র পুরুষ</Button>
                <Button variant="light" className={`btn-filter-outline btn-rounded me-2 mb-2 ${rentTypeTemp === 'onlyfemale,' && 'active'}`} onClick={()=>handleFilterBtnClickRentType('onlyfemale,')}>শুধুমাত্র মহিলা</Button>
                <Button variant="light" className={`btn-filter-outline btn-rounded me-2 mb-2 ${rentTypeTemp === 'malejobholder,' && 'active'}`} onClick={()=>handleFilterBtnClickRentType('malejobholder,')}>পুরুষ চাকরিজীবী</Button>
                <Button variant="light" className={`btn-filter-outline btn-rounded me-2 mb-2 ${rentTypeTemp === 'femalejobholder,' && 'active'}`} onClick={()=>handleFilterBtnClickRentType('femalejobholder,')}>মহিলা চাকরিজীবী</Button>
              </div>
              <Row className="mb-4">
                <Col md={6}>
                <div className="filter-bedroom">
                  <h2 className="fs-18 mb-3">বেড রুম</h2>
                  <div>
                    <Button variant="light" className={`btn-filter-outline btn-rounded me-2 mb-2 ${bedRoomTemp === '' && 'active'}`} onClick={()=>handleFilterBtnClickBedRoom('')}>যেকোনো</Button>
                    <Button variant="light" className={`btn-filter-outline btn-rounded me-2 mb-2 ${bedRoomTemp === 1 && 'active'}`} onClick={()=>handleFilterBtnClickBedRoom(1)}>১</Button>
                    <Button variant="light" className={`btn-filter-outline btn-rounded me-2 mb-2 ${bedRoomTemp === 2 && 'active'}`} onClick={()=>handleFilterBtnClickBedRoom(2)}>২</Button>
                    <Button variant="light" className={`btn-filter-outline btn-rounded me-2 mb-2 ${bedRoomTemp === 3 && 'active'}`} onClick={()=>handleFilterBtnClickBedRoom(3)}>৩</Button>
                    <Button variant="light" className={`btn-filter-outline btn-rounded me-2 mb-2 ${bedRoomTemp === 4 && 'active'}`} onClick={()=>handleFilterBtnClickBedRoom(4)}>৪</Button>
                    <Button variant="light" className={`btn-filter-outline btn-rounded me-2 mb-2 ${bedRoomTemp === 5 && 'active'}`} onClick={()=>handleFilterBtnClickBedRoom(5)}>৫</Button>
                  </div>
                </div>
                </Col>
                <Col md={6}>
                <div className="filter-washroom">
                  <h2 className="fs-18 mb-3">ওয়াশ রুম</h2>
                  <div>
                    <Button variant="light" className={`btn-filter-outline btn-rounded me-2 mb-2 ${washRoomTemp === '' && 'active'}`} onClick={()=>handleFilterBtnClickWashRoom('')}>যেকোনো</Button>
                    <Button variant="light" className={`btn-filter-outline btn-rounded me-2 mb-2 ${washRoomTemp === 1 && 'active'}`} onClick={()=>handleFilterBtnClickWashRoom(1)}>১</Button>
                    <Button variant="light" className={`btn-filter-outline btn-rounded me-2 mb-2 ${washRoomTemp === 2 && 'active'}`} onClick={()=>handleFilterBtnClickWashRoom(2)}>২</Button>
                    <Button variant="light" className={`btn-filter-outline btn-rounded me-2 mb-2 ${washRoomTemp === 3 && 'active'}`} onClick={()=>handleFilterBtnClickWashRoom(3)}>৩</Button>
                    <Button variant="light" className={`btn-filter-outline btn-rounded me-2 mb-2 ${washRoomTemp === 4 && 'active'}`} onClick={()=>handleFilterBtnClickWashRoom(4)}>৪</Button>
                    <Button variant="light" className={`btn-filter-outline btn-rounded me-2 mb-2 ${washRoomTemp === 5 && 'active'}`} onClick={()=>handleFilterBtnClickWashRoom(5)}>৫</Button>
                  </div>
                </div>
                </Col>
              </Row>
              <label className="fs-18 fw-md mb-3" for="rentStartMonth">মাস নির্বাচন করুন</label>
              <FloatingLabel
                controlId="floatingSelectGrid"
                label="মাস"
              >
              <Form.Select className="mb-4" aria-label="Default select example" id="rentStartMonth" value={rentStartMonthTemp} onChange={handleSelectChange}>
                <option>নির্বাচন করুন</option>
                <option value="January">জানুয়ারী</option>
                <option value="February">ফেব্রুয়ারী</option>
                <option value="March">মার্চ</option>
                <option value="April">এপ্রিল</option>
                <option value="May">মে</option>
                <option value="June">জুন</option>
                <option value="July">জুলাই</option>
                <option value="August">আগস্ট</option>
                <option value="September">সেপ্টেম্বর</option>
                <option value="October">অক্টোবর</option>
                <option value="November">নভেম্বর</option>
                <option value="December">ডিসেম্বর</option>
              </Form.Select>
              </FloatingLabel>
            </div>

            <label className="fs-18 fw-md mb-3" for="rentStartMonth">ভাড়ার পরিমান</label>
            <Row>
              <Col md={6}>
                <FloatingLabel
                  controlId="floatingInput"
                  label="মিনিমাম ভাড়া"
                  className="mb-3"
                >
                  <Form.Control type="number" placeholder="ভাড়ার পরিমাণ লিখুন" value={minRentCostTemp} onChange={(event) => handleInputChangeRentCost(event, 'min')} />
                </FloatingLabel>
              </Col>
              <Col md={6}>
                <FloatingLabel
                  controlId="floatingInput"
                  label="ম্যাক্সিমাম ভাড়া"
                  className="mb-3"
                >
                  <Form.Control type="number" placeholder="ভাড়ার পরিমাণ লিখুন" value={maxRentCostTemp} onChange={(event) => handleInputChangeRentCost(event, 'max')} />
                </FloatingLabel>
              </Col>
            </Row>
          </Container>
          </Modal.Body>

          <Modal.Footer className="d-flex justify-content-between">
            <Button variant="link" onClick={handleResetFilterBtn}>
              রিসেট
            </Button>
            <Button variant="primary" onClick={handleSubmitFilterBtn}>
              সার্চ করুন
            </Button>
          </Modal.Footer>
      </Modal>
      
        

        <Container>
          <Row>
            <Col md="3">
              {/* {console.log(device)} */}
              {device === undefined &&
                <Header />
              }
            </Col>
            <Col md="6">
              <div className="content-section mt-5 mt-md-0 pt-3 pt-md-4 ps-md-4">
                  {
                    showContentLoader ? 
                      catalogComponents : 
                        rentList.length > 0 ?
                          rentsAll : <NoData />
                  }
              </div>
            </Col>
            <Col md="3">
            <div className="sidebar-right h-100 position-fixed pt-3 ps-4">
                  <div className="d-flex">
                    {/* {console.log(state.locationList)} */}
                      <Form>
                        <Form.Group className="location-search inner-icon position-relative me-2" controlId="exampleForm.ControlInput1">
                          {/* <svg className="icon position-absolute" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M7.4707 1.6875C10.571 1.6875 13.0957 4.21216 13.0957 7.3125C13.0957 8.65942 12.6233 9.89429 11.8301 10.8633L16.3125 15.3457L15.5039 16.1543L11.0215 11.6719C10.0525 12.4651 8.81763 12.9375 7.4707 12.9375C4.37036 12.9375 1.8457 10.4128 1.8457 7.3125C1.8457 4.21216 4.37036 1.6875 7.4707 1.6875ZM7.4707 2.8125C4.979 2.8125 2.9707 4.8208 2.9707 7.3125C2.9707 9.8042 4.979 11.8125 7.4707 11.8125C9.9624 11.8125 11.9707 9.8042 11.9707 7.3125C11.9707 4.8208 9.9624 2.8125 7.4707 2.8125Z" fill="black"/>
                          </svg> */}
                          <svg className="icon position-absolute" width="17" height="16" viewBox="0 0 17 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M8.07477 0C3.62734 0 0 3.62734 0 8.07477C0 9.33645 0.511683 10.7839 1.20561 12.3645C1.89953 13.9451 2.79322 15.6238 3.70094 17.1869C5.51636 20.3166 7.3458 22.9346 7.3458 22.9346L8.07477 24L8.80374 22.9346C8.80374 22.9346 10.6332 20.3166 12.4486 17.1869C13.3563 15.6238 14.25 13.9451 14.9439 12.3645C15.6379 10.7839 16.1495 9.33645 16.1495 8.07477C16.1495 3.62734 12.5222 0 8.07477 0ZM8.07477 1.79439C11.5549 1.79439 14.3551 4.59463 14.3551 8.07477C14.3551 8.79322 13.9696 10.153 13.3178 11.6355C12.6659 13.118 11.7652 14.7617 10.8785 16.2897C9.46963 18.722 8.59346 20.0082 8.07477 20.7757C7.55608 20.0082 6.67991 18.722 5.27103 16.2897C4.38435 14.7617 3.48365 13.118 2.83178 11.6355C2.17991 10.153 1.79439 8.79322 1.79439 8.07477C1.79439 4.59463 4.59463 1.79439 8.07477 1.79439ZM8.07477 6.28037C7.08294 6.28037 6.28038 7.08294 6.28038 8.07477C6.28038 9.06659 7.08294 9.86916 8.07477 9.86916C9.06659 9.86916 9.86916 9.06659 9.86916 8.07477C9.86916 7.08294 9.06659 6.28037 8.07477 6.28037Z" fill="black"/>
                          </svg>
                          <Form.Control 
                            type="text"
                            className="btn-rounded border-0 location"
                            placeholder="এলাকা" 
                            value={searchText}
                            onChange={handleInputChange}
                          />
                          {/* {console.log(state.locationList)} */}
                        </Form.Group>
                      </Form>

                      <Button variant="light" className={`btn-filter-main d-flex text-center m-0 position-relative ${filterCounts > 0 && 'active'}`} onClick={handleShowFilters}>
                          {filterCounts > 0 && <Badge pill bg="dark" className="counts position-absolute">{filterCounts}</Badge>}
                          <svg className="icon" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clip-rule="evenodd" d="M14.1667 4.16663C14.1667 3.70639 14.5398 3.33329 15 3.33329C15.4602 3.33329 15.8333 3.70639 15.8333 4.16663C15.8333 4.62686 15.4602 4.99996 15 4.99996C14.5398 4.99996 14.1667 4.62686 14.1667 4.16663ZM12.6423 3.33325H3.33333C2.8731 3.33325 2.5 3.70635 2.5 4.16659C2.5 4.62682 2.8731 4.99992 3.33333 4.99992H12.6422C12.9854 5.97093 13.9115 6.66663 15 6.66663C16.3807 6.66663 17.5 5.54734 17.5 4.16663C17.5 2.78591 16.3807 1.66663 15 1.66663C13.9115 1.66663 12.9855 2.36228 12.6423 3.33325ZM2.5 9.99996C2.5 9.53972 2.8731 9.16663 3.33333 9.16663H7.64227C7.98548 8.19565 8.9115 7.5 10 7.5C11.0885 7.5 12.0145 8.19565 12.3577 9.16663H16.6668C17.1271 9.16663 17.5002 9.53972 17.5002 9.99996C17.5002 10.4602 17.1271 10.8333 16.6668 10.8333H12.3578C12.0146 11.8043 11.0885 12.5 10 12.5C8.91147 12.5 7.98543 11.8043 7.64224 10.8333H3.33333C2.8731 10.8333 2.5 10.4602 2.5 9.99996ZM7.35777 15C7.0146 14.029 6.08855 13.3333 5 13.3333C3.61929 13.3333 2.5 14.4525 2.5 15.8333C2.5 17.214 3.61929 18.3333 5 18.3333C6.08849 18.3333 7.0145 17.6376 7.35772 16.6667H16.6668C17.1271 16.6667 17.5002 16.2936 17.5002 15.8333C17.5002 15.3731 17.1271 15 16.6668 15H7.35777ZM10 9.16667C9.53976 9.16667 9.16667 9.53976 9.16667 10C9.16667 10.4602 9.53976 10.8333 10 10.8333C10.4602 10.8333 10.8333 10.4602 10.8333 10C10.8333 9.53976 10.4602 9.16667 10 9.16667ZM4.16667 15.8333C4.16667 15.373 4.53976 14.9999 5 14.9999C5.46024 14.9999 5.83333 15.373 5.83333 15.8333C5.83333 16.2935 5.46024 16.6666 5 16.6666C4.53976 16.6666 4.16667 16.2935 4.16667 15.8333Z" fill="#0C0310"/>
                          </svg>
                          <span className="d-none d-md-block">ফিল্টার</span>
                      </Button>
                  </div>
                  {visibleLocationList &&
                    <ListGroup as="ul" className="location-list shadow mt-1 position-absolute w-100">
                      {filteredLocations.map((location) => (
                        <ListGroup.Item as="li" className="border-0" key={location.locationid} onClick={() => handleLocationArea(location.locationid, location.locationNameBangla)}>
                          <svg className="icon me-3" width="13" height="18" viewBox="0 0 13 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M6.05607 0C2.7205 0 0 2.7205 0 6.05608C0 7.00234 0.383762 8.08791 0.904206 9.27337C1.42465 10.4588 2.09492 11.7179 2.7757 12.8902C4.13727 15.2374 5.50935 17.2009 5.50935 17.2009L6.05607 18L6.6028 17.2009C6.6028 17.2009 7.97488 15.2374 9.33645 12.8902C10.0172 11.7179 10.6875 10.4588 11.2079 9.27337C11.7284 8.08791 12.1121 7.00234 12.1121 6.05608C12.1121 2.7205 9.39165 0 6.05607 0ZM6.05607 1.34579C8.66618 1.34579 10.7664 3.44597 10.7664 6.05608C10.7664 6.59492 10.4772 7.61478 9.98832 8.72664C9.49942 9.83849 8.82389 11.0713 8.15888 12.2173C7.10222 14.0415 6.44509 15.0061 6.05607 15.5818C5.66706 15.0061 5.00993 14.0415 3.95327 12.2173C3.28826 11.0713 2.61273 9.83849 2.12383 8.72664C1.63493 7.61478 1.34579 6.59492 1.34579 6.05608C1.34579 3.44597 3.44597 1.34579 6.05607 1.34579ZM6.05607 4.71028C5.31221 4.71028 4.71028 5.31221 4.71028 6.05608C4.71028 6.79994 5.31221 7.40187 6.05607 7.40187C6.79994 7.40187 7.40187 6.79994 7.40187 6.05608C7.40187 5.31221 6.79994 4.71028 6.05607 4.71028Z" fill="black"/>
                          </svg>
                          {location.locationNameBangla}
                          </ListGroup.Item>
                      ))}
                    </ListGroup>
                  }
                  <div className="search-control-wrapper mt-1">

                  {locationArea !== '' && (
                      <Button
                        variant="light"
                        className="btn-filter-outline btn-sm mb-2 me-2 px-2"
                        onClick={() => handleFilterControlRemove('locationArea')}
                      >
                        {locationAreaName}
                        <CloseButton className="btn-sm p-0 ms-2" />
                      </Button>
                    )}

                    {segmentTemp !== '' && (
                      <Button
                        variant="light"
                        className="btn-filter-outline btn-sm mb-2 me-2 px-2"
                        onClick={() => handleFilterControlRemove('segmentTemp')}
                      >
                        {segmentLabels[segmentTemp]}
                        <CloseButton className="btn-sm p-0 ms-2" />
                      </Button>
                    )}

                    {flatTypeTemp !== '' && (
                      <Button
                        variant="light"
                        className="btn-filter-outline btn-sm mb-2 me-2 px-2"
                        onClick={() => handleFilterControlRemove('flatTypeTemp')}
                      >
                        {flatTypeLabels[flatTypeTemp]}
                        <CloseButton className="btn-sm p-0 ms-2" />
                      </Button>
                    )}

                    {rentTypeTemp !== '' && (
                      <Button
                        variant="light"
                        className="btn-filter-outline btn-sm mb-2 me-2 px-2"
                        onClick={() => handleFilterControlRemove('rentTypeTemp')}
                      >
                        {rentTypeLabels[rentTypeTemp]}
                        <CloseButton className="btn-sm p-0 ms-2" />
                      </Button>
                    )}

                    {bedRoomTemp !== '' && (
                      <Button
                        variant="light"
                        className="btn-filter-outline btn-sm mb-2 me-2 px-2"
                        onClick={() => handleFilterControlRemove('bedRoomTemp')}
                      >
                        বেড রুম: {bedRoomtLabels[bedRoomTemp]}
                        <CloseButton className="btn-sm p-0 ms-2" />
                      </Button>
                    )}

                    {washRoomTemp !== '' && (
                      <Button
                        variant="light"
                        className="btn-filter-outline btn-sm mb-2 me-2 px-2"
                        onClick={() => handleFilterControlRemove('washRoomTemp')}
                      >
                        ওয়াস রুম: {washRoomtLabels[washRoomTemp]}
                        <CloseButton className="btn-sm p-0 ms-2" />
                      </Button>
                    )}

                    {rentStartMonthTemp !== '' && (
                      <Button
                        variant="light"
                        className="btn-filter-outline btn-sm mb-2 me-2 px-2"
                        onClick={() => handleFilterControlRemove('rentStartMonthTemp')}
                      >
                        {rentStartMonthLabels[rentStartMonthTemp]}
                        <CloseButton className="btn-sm p-0 ms-2" />
                      </Button>
                    )}

                    {minRentCostTemp !== '' && (
                      <Button
                        variant="light"
                        className="btn-filter-outline btn-sm mb-2 me-2 px-2"
                        onClick={() => handleFilterControlRemove('rentConstTemp')}
                      >
                        ভাড়ার পরিমান: 
                        {`${minRentCostTemp}${maxRentCostTemp !== '' ? `-${maxRentCostTemp}` : ''}`}
                        <CloseButton className="btn-sm p-0 ms-2" />
                      </Button>
                    )}

                  </div>

                  <div className="mt-4 d-none d-md-block">
                    <a href="https://play.google.com/store/apps/details?id=com.baribodol" target="_blank">
                      <img className="img-fluid" src="https://baribodol.com/images/banner/web/300x250.jpg" alt="baribodol.com mobile app download" />
                    </a>
                    <Keywords />
                  </div>
              </div>
            </Col>
          </Row>
          <Row>
            <div className="d-flex justify-content-center my-5">

              {currentPage > 1 &&
                <Button className="me-3" variant="dark" disabled={currentPage === 1} onClick={() => handlePage('prev')}>
                  আগের বিজ্ঞাপন
                </Button>
              }
              {currentPage < totalPage &&
                <Button variant="dark" disabled={currentPage === totalPage} onClick={() => handlePage('next')}>
                  পরবর্তী বিজ্ঞাপন
                </Button>
              }
            </div>
          </Row>
        </Container>

        <Modal show={show} fullscreen onHide={handleClose} className="bg-blur">
          <Modal.Header className="justify-content-start border-0 position-fixed">
            <Button variant="link" className="p-0" onClick={handleClose}>
              <svg class="icon" width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="36" height="36" rx="18" fill="#EBEBEB"/>
                <path d="M20.2734 9.21094L12.0234 17.4609L11.5078 18L12.0234 18.5391L20.2734 26.7891L21.3516 25.7109L13.6406 18L21.3516 10.2891L20.2734 9.21094Z" fill="black"/>
              </svg>
            </Button>
          </Modal.Header>
          <Modal.Body className="p-0">
            <iframe 
              // src={`/bn/rent/${rentId}/${title.replace(/\//g, "-")}?iframe=true`}
              src={`/bn/rent/${rentId}?iframe=true`}
              title="Rent details loader" 
              width="100%"
              height="100%"
            />
            {/* <RentDetails /> */}
          </Modal.Body>
        </Modal>
    </div>
  );
}

export default RentList;
