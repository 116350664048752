import React, { useEffect, useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ListGroup from 'react-bootstrap/ListGroup';
import Modal from 'react-bootstrap/Modal';
import Toast from 'react-bootstrap/Toast';
import ToastContainer from 'react-bootstrap/ToastContainer';
import Spinner from 'react-bootstrap/Spinner';

import AppContext from '../../../context/AppContext';

// Component
import Header from '../../layout/Header';
import NoData from '../../status/NoData';

// Content Loader
import Catalog from '../../loader/Catalog';

import { getIsAndroidAPKFromLS, getUserDataFromLS } from '../../../helpers/utility'

import { getFavRents, addToFavRent } from '../../api/Api'; // Import your API functions
import { Button } from 'react-bootstrap';



function FavRents() {
  const [rentList, setRentList] = useState([]);
  const [showContentLoader, setShowContentLoader] = useState(true);
  

  const { state } = useContext(AppContext);

  const [show, setShow] = useState(false);
  const[rentId, setRentId] = useState('');

  // Toast message
  const [showToast, setShowToast] = useState(false);
  const toggleShowToast = () => setShowToast(!showToast);



  function addEllipsis(str, maxLength) {
    if (str.length > maxLength) {
      return str.substring(0, maxLength) + '...';
    }
    return str;
  }

  const handleFavRent = (rentid, action) => {
    // setShowContentLoader(true);
    addToFavRent(getUserDataFromLS("userid"), rentid, action)
    .then((response) => {
        response.data.success === 1 && userFavRents();
        setShowToast(true);
    })
    .catch((error) => {
        console.error('API error:', error);
    });
};


  const catalogComponents = [];

  for (let i = 0; i < 5; i++) {
    catalogComponents.push(<Catalog key={i} />);
  }


  const userFavRents = () => {
    getFavRents(getUserDataFromLS("userid"))
    .then((response) => {
      response.data.success !== 0 ? setRentList(response.data.data) : setRentList([]);
      setShowContentLoader(false);
    })
    .catch((error) => {
      console.error('API error:', error);
    });
  };

  useEffect(() => {
    userFavRents();
  }, []);

  const handleClose = () => {
    // setShowContentLoader(true);
    userFavRents();
    setShow(false);
  };
  const handleShow = (rentid) => {
    setShow(true)
    setRentId(rentid)
  };


  const rentsAll = rentList.map((item) =>
    <Card className="border-0 text-start rounded-0 rent-default" key={item.rentid}>
    <Link className="p-0 text-start border-0 btn-card position-relative" onClick={() => handleShow(item.rentid)}>
      
      <div className="img-wrapper position-relative">
        <Card.Img variant="top" src={item.thumb} />

        <div className="thumb-wrapper position-absolute">
          <ListGroup className="d-inline-block">
            {item.totalimages !== 0 &&
              <ListGroup.Item className="bg-transparent border-0 p-0 d-inline-block me-2">
                <div className="thumb-count text-white">
                <svg className="icon me-1" height="16" width="16" viewBox="0 0 32 32"><path fill="#ffffff" d="M 2 5 L 2 27 L 30 27 L 30 5 Z M 4 7 L 28 7 L 28 20.90625 L 22.71875 15.59375 L 22 14.875 L 17.46875 19.40625 L 11.71875 13.59375 L 11 12.875 L 4 19.875 Z M 24 9 C 22.894531 9 22 9.894531 22 11 C 22 12.105469 22.894531 13 24 13 C 25.105469 13 26 12.105469 26 11 C 26 9.894531 25.105469 9 24 9 Z M 11 15.71875 L 20.1875 25 L 4 25 L 4 22.71875 Z M 22 17.71875 L 28 23.71875 L 28 25 L 23.03125 25 L 18.875 20.8125 Z"/></svg>
                  {item.totalimages}
                </div>
              </ListGroup.Item>
            }

            <ListGroup.Item className="bg-transparent border-0 p-0 d-inline-block">
              <div className="thumb-count text-white">
                <svg className="icon me-1" height="16" width="16" viewBox="0 0 24 24"><path fill="#ffffff" d="M23.5,17L18.5,22L15,18.5L16.5,17L18.5,19L22,15.5L23.5,17M12,9A3,3 0 0,1 15,12A3,3 0 0,1 12,15A3,3 0 0,1 9,12A3,3 0 0,1 12,9M12,4.5C17,4.5 21.27,7.61 23,12C22.75,12.65 22.44,13.26 22.08,13.85C21.5,13.5 20.86,13.25 20.18,13.12L20.82,12C19.17,8.64 15.76,6.5 12,6.5C8.24,6.5 4.83,8.64 3.18,12C4.83,15.36 8.24,17.5 12,17.5L13.21,17.43C13.07,17.93 13,18.46 13,19V19.46L12,19.5C7,19.5 2.73,16.39 1,12C2.73,7.61 7,4.5 12,4.5Z"></path></svg>
                {item.viewcount}
              </div>
            </ListGroup.Item>
          </ListGroup>

          
          
          </div>
      </div>
      
      
      <Card.Body className="px-0 pb-0 mb-3">
        <Card.Title className="mb-2">{addEllipsis(item.title, 50)}</Card.Title>
        <Card.Subtitle className='mb-2'>{item.location !== null && addEllipsis(item.location, 40)}</Card.Subtitle>
        <Card.Subtitle className='mb-2'>{item.month}</Card.Subtitle>
        <Card.Text>{item.cost} {item.costduration}</Card.Text>
      </Card.Body>
      </Link>
      <div className="d-flex">
        <Button variant="danger" className="btn-sm btn-outline me-2" onClick={()=> handleFavRent(item.rentid, 0)}>
          <svg className="icon me-1" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 18 18" fill="none">
              <path d="M12.6562 2.8125C11.0295 2.8125 9.65981 3.82725 9 4.41788C8.34019 3.82725 6.9705 2.8125 5.34375 2.8125C3.01725 2.8125 1.125 4.70475 1.125 7.03125C1.125 8.48812 2.45531 9.81394 2.50875 9.86681L9 16.3581L15.4879 9.87019C15.5447 9.81394 16.875 8.48812 16.875 7.03125C16.875 4.70475 14.9828 2.8125 12.6562 2.8125Z" fill="#FFFFFF"/>
          </svg>
          রিমুভ
        </Button>
      </div>

      <hr className="my-4 bg-light"/>
    </Card>
  );


  return (
    <Container>
          <Row>
          {getIsAndroidAPKFromLS() === false &&
            <Col md="3">
              <Header />
            </Col>
            }
            <Col md="6">
              <div className="content-section pt-3 pt-md-4 ps-md-4">
                  {
                    showContentLoader ? 
                      catalogComponents : 
                        rentList.length > 0 ?
                          rentsAll : <NoData />
                  }
              </div>
            </Col>
        </Row>
        <Modal show={show} fullscreen onHide={handleClose} className="bg-blur">
          <Modal.Header className="justify-content-start border-0 position-fixed">
            <Button variant="link" className="p-0" onClick={handleClose}>
              <svg height="24" width="24" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><path d="M 7.21875 5.78125 L 5.78125 7.21875 L 14.5625 16 L 5.78125 24.78125 L 7.21875 26.21875 L 16 17.4375 L 24.78125 26.21875 L 26.21875 24.78125 L 17.4375 16 L 26.21875 7.21875 L 24.78125 5.78125 L 16 14.5625 Z"/></svg>
            </Button>
          </Modal.Header>
          <Modal.Body className="p-0">
            <iframe 
              src={`/bn/rent/${rentId}`}
              title="Rent details loader" 
              width="100%" 
              height="100%"
            />
          </Modal.Body>
        </Modal>
        {showToast &&
          <ToastContainer className="position-fixed">
            <Toast show={showToast} onClose={toggleShowToast}>
              <Toast.Header>
                <img
                  src="holder.js/20x20?text=%20"
                  className="rounded me-2"
                  alt=""
                />
                <strong className="me-auto">রিমুভ হয়েছে</strong>
                {/* <small>এইমাত্র</small> */}
              </Toast.Header>
              <Toast.Body>পোষ্টটি তালিকা থেকে রিমুভ হয়েছে</Toast.Body>
            </Toast>
          </ToastContainer>
        }


    </Container>
  )
}

export default FavRents;
