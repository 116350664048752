import React, { useEffect, useState, useContext, useRef } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import ButtonGoBack from '../../button/ButtonGoBack';
import { getIsAndroidAPKFromLS, getUserDataFromLS } from '../../../helpers/utility'
import Spinner from 'react-bootstrap/Spinner';
import { useParams } from 'react-router-dom';

import watermarkLogo from '../../../../src/logo.png'; // Import your watermark logo
import placeholderImage from '../../../../src/images/placeholder.svg';
import { getRentPostingEditInfo, rentPostingImgDel, rentPostingStepThree, rentPostingStepFour } from '../../api/Api';
import RentPostSuccess from '../../status/RentPostSuccess';
import AppContext from '../../../context/AppContext';

function RentPropertyStepThree() {

  const { state } = useContext(AppContext);

  const compressBase64Image = async (base64, quality = 0.6, watermarkPercentage = 0.2) => {
    return new Promise((resolve, reject) => {
      const image = new Image();
  
      // Load the watermark directly from your local import
      const watermark = new Image();
      watermark.src = watermarkLogo;
  
      image.src = base64;
  
      image.onload = () => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
  
        // Check if the original image width is greater than 2000 pixels
        const maxWidth = 2000;
        let newWidth = image.width;
        let newHeight = image.height;
  
        if (image.width > maxWidth) {
          // Resize the image while maintaining the aspect ratio
          newWidth = maxWidth;
          newHeight = (maxWidth / image.width) * image.height;
        }
  
        // Set the canvas dimensions to the resized image dimensions
        canvas.width = newWidth;
        canvas.height = newHeight;
  
        // Draw the original image on the canvas (resized if necessary)
        ctx.drawImage(image, 0, 0, newWidth, newHeight);
  
        // Calculate watermark size based on the resized image width
        const watermarkWidth = newWidth * watermarkPercentage;
        const watermarkHeight = (watermarkWidth / watermark.width) * watermark.height;
  
        // Position the watermark at the bottom right corner with an offset of 20px
        const offset = 50;
        const watermarkX = canvas.width - watermarkWidth - offset;
        const watermarkY = canvas.height - watermarkHeight - offset;
  
        // Draw the watermark at the bottom right corner with an offset
        ctx.drawImage(watermark, watermarkX, watermarkY, watermarkWidth, watermarkHeight);
  
        // Convert the canvas content to a compressed base64 image
        canvas.toBlob(
          (blob) => {
            const reader = new FileReader();
            reader.readAsDataURL(blob);
            reader.onloadend = () => {
              const compressedBase64 = reader.result;
              resolve(compressedBase64);
            };
          },
          'image/jpeg',
          quality
        );
      };
  
      image.onerror = (error) => {
        reject(error);
      };
    });
  };

  const [show, setShow] = useState(false);
  const [imageidDel, setImageidDel] = useState(null);

  const [images, setImages] = useState([]);
  const fileInputRefs = useRef([React.createRef()]);

  // Spinner
  const [spinner, setSpinner] = useState(false);
  
  const [rentPostSuccess, setRentPostSuccess] = useState(false);

  const handleFileChange = async () => {
    const fileInput = fileInputRefs.current[fileInputRefs.current.length - 1].current;
    const file = fileInput.files[0];

    if (file) {
      const reader = new FileReader();

      reader.onload = async (e) => {
        const compressedBase64 = await compressBase64Image(e.target.result);
        setImages([...images, compressedBase64]);

        // Create a new ref for the next file input
        fileInputRefs.current.push(React.createRef());

        // Clear the file input value
        fileInput.value = '';

        rentPostingStepThree(
          getUserDataFromLS('userid'), 
          compressedBase64,
          rentpostid
        )
        .then((response) => {
          setImageNew([...imagesNew, response.data.data[0]]);
          console.log(response.data.data[0]);
        })
        .catch((error) => {
          console.error('API error:', error);
        });


      };

      reader.readAsDataURL(file);
    }
  };

  const handleShow = (imageid) => {
    setShow(true);
    setImageidDel(imageid);
  };
  const handleClose = () => {
    setShow(false);
  };

  const { rentid } = useParams();
  const [rentpostid, setRentPostId] = useState(null);
  const [imagesNew, setImageNew] = useState(null);

  useEffect(() => {
    fetchPostingEditInfo();
    if(rentid !== undefined) {
      setRentPostId(rentid);
    }
  }, []);

  const handleImgDel = () => {
    rentPostingImgDel(getUserDataFromLS('userid'), imageidDel)
    .then((response) => {
      console.log(response.data);
        // response.data.success === 1 && setImageNew(response.data.data[0].imagesnew);
      handleClose();
      setImageNew(imagesNew.filter(item => item.imageid !== imageidDel));
    })
    .catch((error) => {
        console.error('API error:', error);
    });
  };

  const handleSubmit = () => {
    setSpinner(true);
    rentPostingStepFour(
      getUserDataFromLS('userid'),
      rentid
    )
    .then((response) => {
      console.log(response.data);
      if (response.data.success === 1) {
        setRentPostId(rentid);
        // setRentPostSuccess(true);
        window.location.href = `/rent-posting-success/${rentid}`;
      } else {
        // Handle the case when the response is not successful
        console.error("API request was not successful");
        // You might want to show an error message to the user or take other actions.
      }
    })
    .catch((error) => {
        console.error('API error:', error);
    });
  }

  const fetchPostingEditInfo = () => {
    getRentPostingEditInfo(rentid)
    .then((response) => {
      // console.log(response.data.data[0].imagesnew);
        response.data.success === 1 && setImageNew(response.data.data[0].imagesnew);
    })
    .catch((error) => {
        console.error('API error:', error);
    });
};
  
  return (
    rentPostSuccess === true ?
    <RentPostSuccess rentpostid={rentpostid} /> :
    <Container>
      <div>
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Confirm</Modal.Title>
          </Modal.Header>
          <Modal.Body>Are you sure you want to perform this action?</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              No
            </Button>
            <Button variant="primary" onClick={()=>handleImgDel()}>
              Yes
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
        <Row>
          {getIsAndroidAPKFromLS() === false &&
          <Col md="3">
            <ButtonGoBack/>
          </Col>
          }
          <Col md="6">
            <div className="content-section pt-md-4 ps-md-4">
                <div className="title--wrapper mb-5">
                  <h2 className="fs-18 text-primary">ছবি আপলোড করুন *</h2>
                  <span className="">সর্বনিন্ম ১ টি এবং সর্বচ্চো ৫ টি ছবি আপলোড করতে পারবেন</span>

                  {/* <h6>rentid: {rentpostid}</h6>
                  <h6>userid: {getUserDataFromLS('userid')}</h6>
                  <h6>uid: {getUserDataFromLS('uid')}</h6> */}
                  
                </div>
                {/* <PhotoPlaceholder /> */}

                {imagesNew !== null &&
                  imagesNew.map((item, index) =>
                    <div className="imageWrapper me-4 mb-4 position-relative" key={index}>
                        <img src={item.imageURL} alt={`Preview ${index + 1}`} />
                        <Button variant="light" className="btn-sm btn-outlin position-absolute btn-delete-icon" onClick={()=> handleShow(item.imageid)}>
                          <svg className="icon" width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M6.125 1.75C5.896 1.75 5.66187 1.83032 5.49609 1.99609C5.33032 2.16187 5.25 2.396 5.25 2.625V3.0625H2.1875V3.9375H2.66602L3.5 11.8535L3.54102 12.25H10.459L10.5 11.8535L11.334 3.9375H11.8125V3.0625H8.75V2.625C8.75 2.396 8.66968 2.16187 8.50391 1.99609C8.33813 1.83032 8.104 1.75 7.875 1.75H6.125ZM6.125 2.625H7.875V3.0625H6.125V2.625ZM3.55469 3.9375H10.4453L9.66602 11.375H4.33398L3.55469 3.9375ZM5.25 5.25V10.0625H6.125V5.25H5.25ZM6.5625 5.25V10.0625H7.4375V5.25H6.5625ZM7.875 5.25V10.0625H8.75V5.25H7.875Z" fill="black"/>
                          </svg>
                        </Button>
                    </div>
                  )
                }
                {/* {imagesNew !== null && imagesNew.length} */}
                
                {/* {imagesNew !== null && imagesNew.length < 5 &&
                  <ImageUploader imageLength={imagesNew !== null && imagesNew.length}/>
                } */}
                
                {imagesNew !== null && imagesNew.length < 5 &&
                  <div className="imageWrapper me-4 mb-4 position-relative">
                    <label
                      htmlFor={`fileInput${images.length}`}
                      className="me-4"
                      style={{ cursor: 'pointer' }}
                      onClick={() => fileInputRefs.current[images.length].current.click()}
                    >
                      <img src={placeholderImage} alt={`Placeholder ${images.length + 1}`} className="" />
                      <input
                        type="file"
                        id={`fileInput${images.length}`}
                        ref={fileInputRefs.current[images.length]}
                        onChange={handleFileChange}
                        className="d-none"
                      />
                    </label>
                  </div>
                }
                
                <Button variant="primary" disabled={spinner} className="btn-rounded w-100" onClick={()=> handleSubmit()}>
                  {spinner === true ?
                    <Spinner animation="border" role="status" size="sm">
                      <span className="visually-hidden">Loading...</span>
                    </Spinner>
                    :
                    'পরবর্তী ধাপ'
                  }
                </Button>
              </div>
          </Col>
        </Row>
    </Container>
  )
}

export default RentPropertyStepThree;
