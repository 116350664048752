import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ListGroup from 'react-bootstrap/ListGroup';

// Component
import Logo from '../../../images/logo.svg';
import { Link } from 'react-router-dom';
import ButtonGoBack from '../../button/ButtonGoBack';

function PrivacyPolicy() {
  return (
    <>
    <div className="page-title bg-light">
        <Container fluid className="text-center py-5">
            <Link to="/">
                <img className="img-fluid mb-5" src={Logo} alt="Baribodol" />
            </Link>
            <ButtonGoBack className="p-0 position-fixed btn-pcls btn btn-link bg-transparent" />
            <h1>Privacy Policy</h1>
            <p>
                Please read these Privacy Policy before using this platform for both Mobile App and Web Site.
            </p>
        </Container>
    </div>
    <div className="container p-5 bg-white mt-3 mb-3">
        <h5>General</h5>
        <p className="fs-14">Advertisers and users are responsible for ensuring that advertising content, text, images, graphics, video ("Content") uploaded for inclusion on baribodol.com complies with all applicable laws. baribodol.com &amp; Errorstation assumes no responsibility for any illegality or any inaccuracy of the Content.
            The advertisers and user guarantees that his or her Content do not violate any copyright, intellectual property rights or other rights of any person or entity, and agrees to release baribodol.com and Errorstation from all obligations, liabilities and claims arising in connection with the use of (or the inability to use) the service.
            Advertisers agree that their Content can may be presented through baribodol.com's partner sites under the same Privacy Policy as on baribodol.com.
        </p>
        <h5>Privacy</h5>
        <p className="fs-14">Baribodol.com and Errorstation will collect information from Users and Advertisers. It is a condition of use of the baribodol.com that each User and advertiser consents and authorises baribodol.com and Errorstation to collect and use this information. baribodol.com and Errorstation also reserves the right to disclose it to Company Affiliates and any other person for the purposes of administering, supporting and maintaining baribodol.com, as well as for improving baribodol.com, for example by using the information for research, marketing, product development and planning.</p>
        <h5>Collection</h5>
        <p className="fs-14">Information posted on Baribodol.com is publicly available. If you choose to provide us with personal information, you are consenting to the transfer and storage of that information on our servers. We collect and store the following personal information:</p>
        <ul>
            <li className="fs-14">
                Name, Email address, contact information, and (depending on the service used).
            </li>
            <li className="fs-14">
                Computer sign-on data, statistics on page views, traffic to and from Baribodol.com and response to advertisements.
            </li>
            <li className="fs-14">
                Other information, including users' IP address and standard web log information.
            </li>
            <li className="fs-14">
                Baribodol.com use HTTPS protocol to collect any user data by it’s platform for both mobile app and website.
            </li>
        </ul>
        <h5>Use</h5>
        <p className="fs-14">We use users' personal information to:</p>
        <ul>
            <li className="fs-14">Provide our services</li>
            <li className="fs-14">Resolve disputes, collect fees, and troubleshoot problems</li>
            <li className="fs-14">Encourage safe trading and enforce our policies</li>
            <li className="fs-14">Customize users experience, measure interest in our services</li>
            <li className="fs-14">Improve our services and inform users about services and updates</li>
            <li className="fs-14">Communicate marketing and promotional offers to you according to your preferences</li>
            <li className="fs-14">Do other things for users as described when we collect the information</li>
        </ul>
        <h5>Contact Us</h5>
        <p className="fs-14">For any kind of information, help or privacy concern please email to Baribodol platform at hello@baribodol.com.</p>
        <h5>Data Deletation</h5>
        <p className="fs-14">Baribodol.com stored any data by ensuring high security, user can delete his/ her personal data/account by emailing at hello@baribodol.com.</p>
        <h5>Copyright</h5>
        <p className="fs-14">
            Advertisers grant baribodol.com and Errorstation a perpetual, royalty-free, irrevocable, non-exclusive right and license to use, reproduce, modify, adapt, publish, translate, create derivative works from and distribute such Content or incorporate such Content into any form, medium, or technology now known or later developed.
            The material (including the Content, and any other content, software or services) contained on baribodol.com are the property of Errorstation, its subsidiaries, affiliates and/or third party licensors. Any intellectual property rights, such as copyright, trademarks, service marks, trade names and other distinguishing brands on the Web Site are the property of Errorstation. To be clear: No material on this site may be copied, reproduced, republished, installed, posted, transmitted, stored or distributed without written permission from Errorstation.
        </p>
        <h5>Watermarks</h5>
        <p className="fs-14">All images on baribodol.com are watermarked, which prevents the images to be used for other purposes, without the consent of the advertiser.</p>
        <h5>Safety and images</h5>
        <p className="fs-14">
            baribodol.com and Errorstation reserves the right to change the title of the Content, for editorial purposes. baribodol.com and Errorstation reserves the right not to publish images that are irrelevant or images that violate baribodol.com's rules.
        </p>
        <h5>Personal</h5>
        <p className="fs-14">
            baribodol.com and Errorstation has the right to cooperate with authorities in the case any Content violates the law. The identity of advertisers, users may be determined, for example by an ISP. IP addresses may also be registered in order to ensure compliance with the Privacy Policy.
        </p>
        <h5>Cookies</h5>
        <p className="fs-14">
            This site uses cookies, which means that you must have cookies enabled on your computer in order for all functionality on this site to work properly. A cookie is a small data file that is written to your hard drive when you visit certain Web sites. Cookie files contain certain information, such as a random number user ID that the site assigns to a visitor to track the pages visited. A cookie cannot read data off your hard disk or read cookie files created by other sites. Cookies, by themselves, cannot be used to find out the identity of any user.
        </p>
        <h5>Email address of users</h5>
        <p className="fs-14">
            Users are required to submit a valid email address, before they are allowed to post advertisements. The email address of the User shall not be publicly displayed and other users are permitted to send email to the User through baribodol.com.
        </p>
        <h4>Site availability</h4>
        <p className="fs-14">
            baribodol.com and Errorstation does not guarantee continuous or secure access to the Web Site. The Web Site is provided "as is" and as and when available.
        </p>
        <h5>
            Links to third party websites
        </h5>
        <p className="fs-14"> baribodol.com may contain links or references to other websites ('Third Party Websites'). baribodol.com or Errorstation shall not be responsible for the contents in Third Party Websites. Third Party Websites are not investigated or monitored. In the event the user decides to leave baribodol.com and access Third Party Sites, the user does so at his/her own risk.</p>
        <h5>
            Paid content and services
        </h5>
        <p className="fs-14">
            Users may purchase ad promotions using vouchers issued or sold by baribodol.com. Vouchers may not be redeemed for cash.
            Advertisers and Users may be required to transmit Paid Content information through a third-party service provider, which may be governed by its own terms of use. Linking to any third party service providers is at the Users' and Advertisers' own risk and baribodol.com disclaims all liability related thereto. Under no circumstances shall baribodol.com be obliged to refund any payments made in respect to Paid Content.
        </p>
        <h5>
            Rent and Memberships
        </h5>
        <p className="fs-14">
            As part of a membership package, baribodol.com will create a Shop on behalf of the user. A Shop is a dedicated webpage on baribodol.com, maintained by baribodol.com, with content provided by the user. baribodol.com has the right to any content added to the Shop by the user and has the right to remove or not publish the content if it violates any aspect of the Privacy Policy. Baribodol.com may require payment for certain content and services such as membership packages, rental post, rental search, third-party advertising, posting advertisement in selected class etc.
            baribodol.com reserves the right to modify or change membership packages, including the price and the contents of the membership package or rent post.
            baribodol.com is not obligated to refund money or services if a membership package is cancelled early, for any reason.
        </p>
        <h5>
            Disclaimer
        </h5>
        <p className="fs-14">
            baribodol.com and Errorstation assume no responsibility what so ever for the use of baribodol.com and disclaims all responsibility for any injury, claim, liability, or damage of any kind resulting from or arising out of or any way related to (a) any errors on baribodol.com or the Content, including but not limited to technical errors and typographical errors, (b) any third party web sites or content directly or indirectly accessed through links in baribodol.com, (c) the unavailability of baribodol.com, (d) your use of baribodol.com or the Content, or (e) your use of any equipment (or software) in connection with baribodol.com
        </p>
        <h5>
            Indemnification
        </h5>
        <p className="fs-14">
            Advertisers and users agree to indemnify baribodol.com &amp; Errorstation as well as its officers, directors, employees, agents, from and against all losses, expenses, damages and costs, including attorney's fees, resulting from any violation of this Privacy Policy (including negligent or wrongful conduct).
        </p>
        <h5>Modifications</h5>
        <p className="fs-14">baribodol.com &amp; Errorstation reserves the right to modify these Privacy Policy. Such modifications shall be effective immediately upon posting on baribodol.com. You are responsible for the reviewing of such modifications. Your continued access or use of baribodol.com shall be deemed your acceptance of the modified Privacy Policy.</p>
    </div>
    </>
  );
}

export default PrivacyPolicy;
