import React from 'react'
import ContentLoader from 'react-content-loader'

const Catalog = props => (
  <ContentLoader className="content-loader" viewBox="0 0 520 280" height={280} width="100%" {...props}>
    <rect x="0" y="0" rx="17" ry="17" width="520" height="180" border-radius="20" />
    <rect x="0" y="190" rx="5" ry="5" width="520" height="20" />
    <rect x="0" y="220" rx="5" ry="5" width="400" height="20" />
  </ContentLoader>
)

export default Catalog