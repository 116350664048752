import React, { useEffect, useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ListGroup from 'react-bootstrap/ListGroup';
import Modal from 'react-bootstrap/Modal';
import Toast from 'react-bootstrap/Toast';
import ToastContainer from 'react-bootstrap/ToastContainer';
import Button from 'react-bootstrap/Button';


import AppContext from '../../../context/AppContext';

// Component
import Header from '../../layout/Header';
import NoData from '../../status/NoData';

// Content Loader
import Catalog from '../../loader/Catalog';

import { getUserDataFromLS } from '../../../helpers/utility'

import { getRentPostingType } from '../../api/Api'; // Import your API functions
import ButtonGoBack from '../../button/ButtonGoBack';




function RentPropertyComplete() {
  const [rentPostingTypeList, setRentPostingTypeList] = useState([]);
  const [showContentLoader, setShowContentLoader] = useState(true);
  

  const { state } = useContext(AppContext);

  const [show, setShow] = useState(false);
  const[rentId, setRentId] = useState('');

  // Toast message
  const [showToast, setShowToast] = useState(false);
  const toggleShowToast = () => setShowToast(!showToast);



  function addEllipsis(str, maxLength) {
    if (str.length > maxLength) {
      return str.substring(0, maxLength) + '...';
    }
    return str;
  }


  const catalogComponents = [];

  for (let i = 0; i < 5; i++) {
    catalogComponents.push(<Catalog key={i} />);
  }


  const rentPostingType = () => {
    getRentPostingType()
    .then((response) => {
      // console.log(response.data.data[0].rentposttype);
      setRentPostingTypeList(response.data.data[0].rentposttype);
      setShowContentLoader(false);
    })
    .catch((error) => {
      console.error('API error:', error);
    });
  };

  useEffect(() => {
    rentPostingType();
  }, []);

  
  const handleShow = (rentid) => {
    setShow(true)
    setRentId(rentid)
  };


  return (
    <Container>
          <Row>
            <Col md="3">
              <ButtonGoBack/>
            </Col>
            <Col md="6">
              <div className="content-section pt-md-4 ps-md-4">
                  {/* {
                    showContentLoader ? 
                      catalogComponents : 
                        rentPostingTypeList.length > 0 ?
                          typesAll : <NoData />
                  } */}

                  <div className="filters-all">
                    <h2 className="fs-18 mb-5 text-danger">ভাড়ার ধরন</h2>

                    <div className="filter-flat-type mb-5">
                      <h3 className="fs-14 mb-3 fw-bold">আপনি কি ভাড়া দিতে চান? *</h3>
                      <Button variant="light" className={`fs-14 btn-light me-2 ${'flatTypeTemp' === 'flat,' && 'active'}`} onClick={()=>'handleFilterBtnClickFlatType'('flat,')}>ফ্লাট ভাড়া</Button>
                      <Button variant="light" className={`fs-14 btn-light me-2 active ${'flatTypeTemp' === 'seat,' && 'active'}`} onClick={()=>'handleFilterBtnClickFlatType'('seat,')}>সিট ভাড়া</Button>
                      <Button variant="light" className={`fs-14 btn-light ${'flatTypeTemp' === 'sublet,' && 'active'}`} onClick={()=>'handleFilterBtnClickFlatType'('sublet,')}>সাবলেট ভাড়া</Button>
                    </div>
                    <div className="filter-rent-type mb-4">
                      <h3 className="fs-14 mb-3 fw-bold">আপনি কাদের কাছে ভাড়া দিতে চান? *</h3>
                      <Button variant="light" className={`fs-14 btn-light me-2 mb-2 ${'rentTypeTemp' === 'bachelor,' && 'active'}`} onClick={()=>'handleFilterBtnClickRentType'('bachelor,')}>ব্যাচেলর</Button>
                      <Button variant="light" className={`fs-14 btn-light me-2 mb-2 ${'rentTypeTemp' === 'family,' && 'active'}`} onClick={()=>'handleFilterBtnClickRentType'('family,')}>ফ্যামিলি</Button>
                      <Button variant="light" className={`fs-14 btn-light me-2 mb-2 ${'rentTypeTemp' === 'smallfamily,' && 'active'}`} onClick={()=>'handleFilterBtnClickRentType'('smallfamily,')}>ছোট ফ্যামিলি</Button>
                      <Button variant="light" className={`fs-14 btn-light me-2 mb-2 ${'rentTypeTemp' === 'onlymalestudent,' && 'active'}`} onClick={()=>'handleFilterBtnClickRentType'('onlymalestudent,')}>শুধুমাত্র ছাত্র</Button>
                      <Button variant="light" className={`fs-14 btn-light me-2 mb-2 ${'rentTypeTemp' === 'onlyfemalestudent,' && 'active'}`} onClick={()=>'handleFilterBtnClickRentType'('onlyfemalestudent,')}>শুধুমাত্র ছাত্রী</Button>
                      <Button variant="light" className={`fs-14 btn-light me-2 mb-2 ${'rentTypeTemp' === 'onlymale,' && 'active'}`} onClick={()=>'handleFilterBtnClickRentType'('onlymale,')}>শুধুমাত্র পুরুষ</Button>
                      <Button variant="light" className={`fs-14 btn-light me-2 mb-2 ${'rentTypeTemp' === 'onlyfemale,' && 'active'}`} onClick={()=>'handleFilterBtnClickRentType'('onlyfemale,')}>শুধুমাত্র মহিলা</Button>
                      <Button variant="light" className={`fs-14 btn-light me-2 mb-2 ${'rentTypeTemp' === 'malejobholder,' && 'active'}`} onClick={()=>'handleFilterBtnClickRentType'('malejobholder,')}>পুরুষ চাকরিজীবী</Button>
                      <Button variant="light" className={`fs-14 btn-light me-2 mb-2 ${'rentTypeTemp' === 'femalejobholder,' && 'active'}`} onClick={()=>'handleFilterBtnClickRentType'('femalejobholder,')}>মহিলা চাকরিজীবী</Button>
                    </div>

                    <div className="mb-4 d-flex justify-content-between align-items-center">
                      <h3 className="fs-14 mb-0 fw-bold">বেড রুম *</h3>
                      <div className="d-flex">
                        <Button variant="dark" className="btn-rounded btn-idc">-</Button>
                        <span className="form-control d-inline-block text-center mx-2 btn-rounded px-4 fs-14 idc-val">১ টি</span>
                        <Button variant="dark" className="btn-rounded btn-idc">+</Button>
                      </div>
                    </div>

                    <div className="mb-4 d-flex justify-content-between align-items-center">
                      <h3 className="fs-14 mb-0 fw-bold">ওযাস রুম *</h3>
                      <div className="d-flex">
                        <Button variant="dark" className="btn-rounded btn-idc">-</Button>
                        <span className="form-control d-inline-block text-center mx-2 btn-rounded px-4 fs-14 idc-val">১ টি</span>
                        <Button variant="dark" className="btn-rounded btn-idc">+</Button>
                      </div>
                    </div>

                    <div className="mb-4 d-flex justify-content-between align-items-center">
                      <h3 className="fs-14 mb-0 fw-bold">বারিন্দা সংখ্যা</h3>
                      <div className="d-flex">
                        <Button variant="dark" className="btn-rounded btn-idc">-</Button>
                        <span className="form-control d-inline-block text-center mx-2 btn-rounded px-4 fs-14 idc-val">১ টি</span>
                        <Button variant="dark" className="btn-rounded btn-idc">+</Button>
                      </div>
                    </div>

                    <div className="mb-5 d-flex justify-content-between align-items-center">
                      <h3 className="fs-14 mb-0 fw-bold">ফ্লাটটি কত তলাতে অবস্থিত *</h3>
                      <div className="d-flex">
                        <Button variant="dark" className="btn-rounded btn-idc">-</Button>
                        <span className="form-control d-inline-block text-center mx-2 btn-rounded px-4 fs-14 idc-val">১ তলা</span>
                        <Button variant="dark" className="btn-rounded btn-idc">+</Button>
                      </div>
                    </div>
                    <Button variant="dark" className="btn-rounded w-100">পরবর্তী ধাপ</Button>
                  </div>
                </div>
            </Col>
        </Row>


    </Container>
  )
}

export default RentPropertyComplete;
