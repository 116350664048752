import React, { useEffect, useState, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import Modal from 'react-bootstrap/Modal';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import AppContext from '../../../context/AppContext';

import buildingImage from '../../../../src/images/building.svg';
import houseImage from '../../../../src/images/house.svg';


import LocationSearch from '../../../helpers/LocationSearch';


// Content Loader
import Catalog from '../../loader/Catalog';

import { getUserDataFromLS, convertNumberEn2Bn } from '../../../helpers/utility'

import { getRentPostingEditInfo, rentLookingStepOne, rentLookingStepOneEdit, rentPostingStepFour } from '../../api/Api'; // Import your API functions
import ButtonGoBack from '../../button/ButtonGoBack';
import RentPostSuccess from '../../status/RentPostSuccess';




function RentLookingStepOne() {
  const { rentid } = useParams();
  const [rentpostid, setRentPostId] = useState(null);

  const [show, setShow] = useState(false);


  const [rentPostingTypeList, setRentPostingTypeList] = useState([]);
  const [showContentLoader, setShowContentLoader] = useState(true);

  const [flatType, setFlatType] = useState('');

  const [bachelor, setBachelor] = useState(false);
  const [family, setFamily] = useState(false);
  const [smallfamily, setSmallFamily] = useState(false);
  const [onlymalestudent, setOnlyMaleStudent] = useState(false);
  const [onlyfemalestudent, setOnlyFemaleStudent] = useState(false);
  const [onlymale, setOnlyMale] = useState(false);
  const [onlyfemale, setOnlyFemale] = useState(false);
  const [malejobholder, setMaleJobHolder] = useState(false);
  const [femalejobholder, setFemaleJobHolder] = useState(false);

  const [area, setArea] = useState('');
  const [locationName, setLocationName] = useState('');
  const [locationNameBangla, setLocationNameBangla] = useState('');
  const [locationSearchVisible, setLocationSearchVisible] = useState(false);
  const [address, setAddress] = useState('');

  const [bedrooms, setBedrooms] = useState(1);
  const [washrooms, setWashrooms] = useState(1);
  const [veranda, setVeranda] = useState(1);

  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [rentcost, setRentcost] = useState('');
  const [negotiate, setNegotiate] = useState(false);
  const [floorno, setFloorno] = useState(0);
  const [sqrfeet, setSqrfeet] = useState('');
  const [rentdate, setRentDate] = useState(new Date());
  

  const { state, dispatch } = useContext(AppContext);

  // Toast message
  const [showToast, setShowToast] = useState(false);
  const toggleShowToast = () => setShowToast(!showToast);

  // Spinner
  const [spinner, setSpinner] = useState(false);

  const [rentPostSuccess, setRentPostSuccess] = useState(false);

  
  
  const handleClose = () => setShow(false);

  const handleFilterBtnClickFlatType = (type) => {
    setFlatType(type);
  }

  const handleFilterBtnClickRentType = (type) => {
    const stateSetterMap = {
      'bachelor,': setBachelor,
      'family,': setFamily,
      'smallfamily,': setSmallFamily,
      'onlymalestudent,': setOnlyMaleStudent,
      'onlyfemalestudent,': setOnlyFemaleStudent,
      'onlymale,': setOnlyMale,
      'onlyfemale,': setOnlyFemale,
      'malejobholder,': setMaleJobHolder,
      'femalejobholder,': setFemaleJobHolder,
    };
  
    const toggleState = (stateSetter) => {
      if (typeof stateSetter === 'function') {
        stateSetter((prevState) => !prevState);
      } else {
        console.error('Invalid stateSetter function:', stateSetter);
      }
    };
  
    const setState = stateSetterMap[type];
  
    if (setState) {
      toggleState(setState);
    } else {
      console.error(`State setter not found for type: ${type}`);
    }
  };

  const handleInputChange = (event, type) => {
    
    const newValue = event.target.value;

    const stateSetterMap = {
      'title': setTitle,
      'rentcost': setRentcost,
      'negotiate': setNegotiate,
      'sqrfeet': setSqrfeet,
      'rentdate': setRentDate,
      'area': setArea,
      'locationName': setLocationName,
      'locationNameBangla': setLocationNameBangla,
      'address': setAddress,
      'description': setDescription,
    };

    

    const setState = stateSetterMap[type];

    // Call the state setter function with the toggled value if it exists
    if (setState) {
      setState(newValue);
    }
  };

  const handleNegotiate = () => {
    setNegotiate(!negotiate);
    setRentcost('');
  }


  


  const catalogComponents = [];

  for (let i = 0; i < 5; i++) {
    catalogComponents.push(<Catalog key={i} />);
  }

  const handleRentPosting = () => {
    setSpinner(true);
    const conditions = [];
    if (bachelor === true) {
      conditions.push('bachelor');
    }
    if (family === true) {
      conditions.push('family');
    }
    if (smallfamily === true) {
      conditions.push('small family');
    }
    if (onlymalestudent === true) {
      conditions.push('only male student');
    }
    if (onlyfemalestudent === true) {
      conditions.push('only female student');
    }
    if (onlymale === true) {
      conditions.push('only male');
    }
    if (onlyfemale === true) {
      conditions.push('only female');
    }
    if (malejobholder === true) {
      conditions.push('male jobholder');
    }
    if (femalejobholder === true) {
      conditions.push('female jobholder');
    }
    
    const rentType = conditions.join(',');

    console.log(rentid === undefined, rentid);

    rentid === undefined ?
      rentLookingStepOne(
        getUserDataFromLS('userid'),
        getUserDataFromLS('uid'),
        flatType,
        ','+rentType+',',
        rentcost,
        description,
        state.locationArea,
        rentdate,
        negotiate === true ? 1 : 0,
      )
      .then((response) => {
        console.log(response.data)
        if (response.data.success === 1) {
          setRentPostId(response.data.rentid);
          // setRentPostSuccess(true);
          window.location.href = `/rent-posting-success/${response.data.rentid}`;
          rentPostingStepFour(getUserDataFromLS('userid'), response.data.rentid);
        } else {
          // Handle the case when the response is not successful
          console.error("API request was not successful");
          // You might want to show an error message to the user or take other actions.
        }
      })
      .catch((error) => {
        console.error('API error:', error);
      })
      : 
      rentLookingStepOneEdit(
        getUserDataFromLS('userid'),
        getUserDataFromLS('uid'),
        flatType,
        ','+rentType+',',
        rentcost,
        description,
        state.locationArea,
        rentdate,
        negotiate === true ? 1 : 0,
        rentid
      )
      .then((response) => {
        console.log(response.data)
        if (response.data.success === 1) {
          setRentPostId(response.data.rentid);
          // setRentPostSuccess(true);
          window.location.href = `/rent-posting-success/${response.data.rentid}`;
          rentPostingStepFour(getUserDataFromLS('userid'), response.data.rentid);
        } else {
          // Handle the case when the response is not successful
          console.error("API request was not successful");
          // You might want to show an error message to the user or take other actions.
        }
      })
      .catch((error) => {
        console.error('API error:', error);
      })
  };

  const fetchPostingEditInfo = () => {
    getRentPostingEditInfo(rentid)
      .then((response) => {
        console.log(response.data.data[0]);
        setFlatType(response.data.data[0].type);
        // setBachelor(response.data.data[0].renttype);
        setBedrooms(response.data.data[0].bedrooms);
        setWashrooms(response.data.data[0].washrooms);
        setNegotiate(response.data.data[0].rentnegotiable);

        setRentcost(response.data.data[0].cost);

        setDescription(response.data.data[0].homedesc);
        setRentDate(new Date(response.data.data[0].rentstartdate));

        const renttype = response.data.data[0].renttype;
        const renttypeArray = renttype.split(',').filter(Boolean);

        // console.log(renttypeArray);

        renttypeArray.map((renttype, index) => {
          if (renttype === 'bachelor') {
            setBachelor(true);
          }
          if (renttype === 'family') {
            setFamily(true);
          }
          if (renttype === 'small family') {
            setSmallFamily(true);
          }
          if (renttype === 'only male student') {
            setOnlyMaleStudent(true);
          }
          if (renttype === 'only female student') {
            setOnlyFemaleStudent(true);
          }
          if (renttype === 'only male') {
            setOnlyMale(true);
          }
          if (renttype === 'only female') {
            setOnlyFemale(true);
          }
          if (renttype === 'male jobholder') {
            setMaleJobHolder(true);
          }
          if (renttype === 'female jobholder') {
            setFemaleJobHolder(true);
          }
          return null; // or some other JSX if needed
        })

        dispatch({ type: 'UPDATE_LOCATION_AREA', payload: response.data.data[0].area});
        dispatch({ type: 'UPDATE_LOCATION_AREA_NAME', payload: response.data.data[0].areaname});
          

        
      })
      .catch((error) => {
          console.error('API error:', error);
      });
  };

  useEffect(() => {
    fetchPostingEditInfo();
    if(rentid !== undefined) {
      setRentPostId(rentid);
      // console.log(rentid);
    }
  }, []);



  return (
      rentPostSuccess === true ?
      <RentPostSuccess rentpostid={rentpostid} /> :
      <Container>
            <Row>
              {state.androidAPK === false &&
                <Col md="3">
                  <ButtonGoBack targetPath="/rent-posting-type"/>
                </Col>
              }
              <Col md="6">
                <div className="content-section pt-md-4 ps-md-4 mt-m-big">
                    {/* {
                      showContentLoader ? 
                        catalogComponents : 
                          rentPostingTypeList.length > 0 ?
                            typesAll : <NoData />
                    } */}

                    

                    <Form>
                      <div className="filters-all">
                        <h2 className="fs-18 mb-5 text-primary">ভাড়ার ধরন</h2>
                        <div className="filter-flat-type mb-5">
                          <h3 className="fs-14 mb-3 fw-bold">আপনি কোন ধরনের ভাড়া খুঁজছেন? *</h3>
                          <Button variant="light" className={`fs-14 btn-light me-2 ${flatType === 'flat,' && 'active'}`} onClick={()=>handleFilterBtnClickFlatType('flat,')}>ফ্লাট ভাড়া</Button>
                          <Button variant="light" className={`fs-14 btn-light me-2 ${flatType === 'seat,' && 'active'}`} onClick={()=>handleFilterBtnClickFlatType('seat,')}>সিট ভাড়া</Button>
                          <Button variant="light" className={`fs-14 btn-light ${flatType === 'sublet,' && 'active'}`} onClick={()=>handleFilterBtnClickFlatType('sublet,')}>সাবলেট ভাড়া</Button>
                        </div>
                        <div className="filter-rent-type mb-4">
                          <h3 className="fs-14 mb-3 fw-bold">আপনি কোন ধরনের ভাড়াটিয়া? *</h3>
                          <Button variant="light" className={`fs-14 btn-light me-2 mb-2 ${bachelor && 'active'}`} onClick={()=>handleFilterBtnClickRentType('bachelor,')}>ব্যাচেলর</Button>
                          <Button variant="light" className={`fs-14 btn-light me-2 mb-2 ${family && 'active'}`} onClick={()=>handleFilterBtnClickRentType('family,')}>ফ্যামিলি</Button>
                          <Button variant="light" className={`fs-14 btn-light me-2 mb-2 ${smallfamily && 'active'}`} onClick={()=>handleFilterBtnClickRentType('smallfamily,')}>ছোট ফ্যামিলি</Button>
                          <Button variant="light" className={`fs-14 btn-light me-2 mb-2 ${onlymalestudent && 'active'}`} onClick={()=>handleFilterBtnClickRentType('onlymalestudent,')}>শুধুমাত্র ছাত্র</Button>
                          <Button variant="light" className={`fs-14 btn-light me-2 mb-2 ${onlyfemalestudent && 'active'}`} onClick={()=>handleFilterBtnClickRentType('onlyfemalestudent,')}>শুধুমাত্র ছাত্রী</Button>
                          <Button variant="light" className={`fs-14 btn-light me-2 mb-2 ${onlymale && 'active'}`} onClick={()=>handleFilterBtnClickRentType('onlymale,')}>শুধুমাত্র পুরুষ</Button>
                          <Button variant="light" className={`fs-14 btn-light me-2 mb-2 ${onlyfemale && 'active'}`} onClick={()=>handleFilterBtnClickRentType('onlyfemale,')}>শুধুমাত্র মহিলা</Button>
                          <Button variant="light" className={`fs-14 btn-light me-2 mb-2 ${malejobholder && 'active'}`} onClick={()=>handleFilterBtnClickRentType('malejobholder,')}>পুরুষ চাকরিজীবী</Button>
                          <Button variant="light" className={`fs-14 btn-light me-2 mb-2 ${femalejobholder && 'active'}`} onClick={()=>handleFilterBtnClickRentType('femalejobholder,')}>মহিলা চাকরিজীবী</Button>
                        </div>

                        <h2 className="fs-18 mb-4 text-primary">ভাড়ার বিবরণ</h2>

                        <div className="mb-4">
                            <FloatingLabel
                              controlId="floatingInput"
                              label="কত টাকার মধ্যে ভাড়া খুঁজছেন? *"
                              className="mb-3"
                            >
                              <Form.Control type="text" placeholder="ভাড়ার পরিমাণ লিখুন" disabled={negotiate} value={rentcost} onChange={(event) => handleInputChange(event, 'rentcost')} />
                            </FloatingLabel>
                            <Form.Check
                              type="checkbox"
                              id="Default"
                              label="আলোচনার সাপেক্ষে"
                              checked={negotiate == 1}
                              onChange={()=> handleNegotiate()}
                            />
                          </div>

                          <h2 className="fs-18 mt-5 mb-4 text-primary">ভাড়া সংক্রান্ত তথ্য</h2>

                          <div className="mb-4">
                              <span className="fw-bold">কোন তারিখ থেকে ভাড়া নিবেন? *</span>
                              <DatePicker className="form-control w-100" selected={rentdate} onChange={(date) => setRentDate(date)} />
                          </div>

                          <Modal show={show} onHide={handleClose} className="bg-blur modal-location">
                            <Modal.Body className="p-3">
                              <div className="text-center">
                                <img src={buildingImage} alt={`location search`} className="mb-4 m-auto d-inline-block" width={250} />
                              </div>
                              <LocationSearch className="mt-5"/>
                            </Modal.Body>

                            <Modal.Footer className="d-flex justify-content-between border-0 pt-0" onClick={()=>handleClose()}>
                              <Button variant="primary" className="btn-dark">
                                সেট করুন
                              </Button>
                            </Modal.Footer>
                          </Modal>


                          <div className="mb-4" onClick={() => setShow(true)}>
                              <FloatingLabel
                                controlId="floatingInput"
                                label="এলাকা *"
                                className="mb-3"
                              >
                                <Form.Control type="text" placeholder="" value={state.locationAreaName} />
                              </FloatingLabel>
                            </div>

                          <div className="mb-4">
                            <FloatingLabel
                              controlId="floatingTextarea"
                              label="কেমন বাসা খুঁজছেন তার বর্ণনা লিখুন *"
                              className="mb-3"
                            >
                              <Form.Control as="textarea" placeholder="Leave a comment here" value={description} onChange={(event) => handleInputChange(event, 'description')} />
                            </FloatingLabel>
                          </div>

                        <Button variant="primary" className="btn-rounded w-100" disabled={spinner ||  flatType === '' || (!bachelor && !family && !smallfamily && !onlymalestudent && !onlyfemalestudent && !onlymale && !onlyfemale && !malejobholder && !femalejobholder)} onClick={() => handleRentPosting()}>
                          {spinner === true ?
                            <Spinner animation="border" role="status" size="sm">
                              <span className="visually-hidden">Loading...</span>
                            </Spinner>
                            :
                            'পরবর্তী ধাপ'
                            }
                        </Button>

                        


                      </div>
                    </Form>
                  </div>
              </Col>
          </Row>


      </Container>
  )
}

export default RentLookingStepOne;
