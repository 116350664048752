import axios from 'axios';
import qs from 'qs';

const api = axios.create({
  baseURL: 'https:///baribodol.com/',
});

export const getUserPublishedRents = (userid) => {
    return api.get('api/userPublishedRents.php?userid='+userid);
};
  
export const createPost = (data) => {
    return api.post('/posts', data);
};

// My Account
export const rentPublish = (userid, rentpostid) => {
    return api.get(`api/rentpublish.php?userid=${userid}&rentpostid=${rentpostid}`);
};

export const rentUnpublish = (userid, rentpostid, reason) => {
    return api.get(`api/rentunpublish.php?userid=${userid}&rentpostid=${rentpostid}&unpublished=1&deleted=0&reason=${reason}`);
};

export const rentDelete = (userid, rentpostid, reason) => {
    return api.get(`api/rentunpublish.php?userid=${userid}&rentpostid=${rentpostid}&unpublished=0&deleted=1&reason=${reason}`);
};

// My fav rents
export const getFavRents = (userid) => {
    return api.get(`api/favoritelist.php?userid=${userid}`);
};

export const addToFavRent = (userid, rentpostid, action) => {
    return api.get(`api/addtofavorite.php?userid=${userid}&rentpostid=${rentpostid}&status=${action}`);
};

// Rent Details
export const getRentDetails = (userid, rentpostid) => {
    return api.get(`api/rentdetails.php?userid=${userid}&rentpostid=${rentpostid}`);
};

// Profile
export const updatePersonalInfo = (userid, uname, uphone, uemail) => {
    // Assuming api is an axios instance or you can use axios directly
    return api.post('api/updatePersonalInfo.php', qs.stringify({
      userid: userid,
      name: uname,
      phone: uphone,
      email: uemail,
    }));
};

// Location List Posting
export const getLocationList = () => {
    return api.get(`api/getlocationlist.php`);
};

// Rent Posting
export const getRentPostingType = () => {
    return api.get(`api/rentposttype.php`);
};

export const rentPostingStepOne = (userid, uid, flattype, renttype, bedrooms, washrooms, veranda, floorno) => {
    return api.post('api/rent-posting-step1.php', qs.stringify({
        userid,
        uid,
        flattype,
        renttype,
        bedrooms,
        washrooms,
        veranda,
        floorno,
      }));
    // return api.get(`api/https://baribodol.com/api/rentpostingeditinfo.php?rentpostid=`);
    // let url = `${""+rentpostid+"&development="+isDevelopment+"&edit=1"}`
};

export const rentPostingStepOneEdit = (userid, uid, flattype, renttype, bedrooms, washrooms, veranda, floorno, rentpostid) => {
    return api.post('api/rent-posting-step1.php', qs.stringify({
        userid,
        uid,
        flattype,
        renttype,
        bedrooms,
        washrooms,
        veranda,
        floorno,
        rentpostid
      }));
    // return api.get(`api/https://baribodol.com/api/rentpostingeditinfo.php?rentpostid=`);
    // let url = `${""+rentpostid+"&development="+isDevelopment+"&edit=1"}`
};

export const rentPostingCommonStepOne = (
    userid,
    uid,
    flattype,
    rentsegment,
    description,
    area,
    sqrfeet,
    rentstartdate,
    rentenddate,
    contactno,
    floorno,
    title,
    approximaterent,
    locationdesc,
    rentcostnegotiation
) => {
    return api.post('api/rent-posting-common-step1.php', qs.stringify({
        userid,
        uid,
        flattype,
        rentsegment,
        description,
        area,
        sqrfeet,
        rentstartdate,
        rentenddate,
        contactno,
        floorno,
        title,
        approximaterent,
        locationdesc,
        rentcostnegotiation
      }));
};

export const rentPostingCommonStepOneEdit = (
    userid,
    uid,
    flattype,
    rentsegment,
    description,
    area,
    sqrfeet,
    rentstartdate,
    rentenddate,
    contactno,
    floorno,
    title,
    approximaterent,
    locationdesc,
    rentcostnegotiation,
    rentpostid
) => {
    return api.post('api/rent-posting-common-step1.php', qs.stringify({
        userid,
        uid,
        flattype,
        rentsegment,
        description,
        area,
        sqrfeet,
        rentstartdate,
        rentenddate,
        contactno,
        floorno,
        title,
        approximaterent,
        locationdesc,
        rentcostnegotiation,
        rentpostid
      }));
};

export const rentPostingStepTwo = (userid, uid, rentpostid, lift, generator, parkfacility, securityguard, wifi, gas, electricbill, waterbill, gasbill, servicecharge, approximaterent, locationdesc, area, rentcostnegotiation, rentstartdate, rentenddate, religion, description, sqrfeet) => {
    return api.post('api/rent-posting-step2.php', qs.stringify({
        userid,
        uid,
        rentpostid,
        lift,
        generator,
        parkfacility,
        securityguard,
        wifi,
        gas,
        electricbill,
        waterbill,
        gasbill,
        servicecharge,
        approximaterent,
        locationdesc,
        area,
        rentcostnegotiation,
        rentstartdate,
        rentenddate,
        religion,
        description,
        sqrfeet
      }));
};

export const rentPostingStepThree = (userid, img, rentpostid) => {
    return api.post('api/rent-posting-step3.php', qs.stringify({
        userid,
        img,
        rentpostid
    }));
};

export const rentPostingStepFour = (userid, rentpostid) => {
    return api.get(`api/rent-posting-step4.php?userid=${userid}&rentpostid=${rentpostid}`);
};

// Rent posting photos
export const getRentPostingEditInfo = (rentpostid) => {
    return api.get(`api/rentpostingeditinfo.php?rentpostid=${rentpostid}`);
};

// Looking for
export const rentLookingStepOne = (
    userid,
    uid,
    flattype,
    renttype,
    approximaterent,
    homedesc,
    area,
    rentstartdate,
    rentcostnegotiation,
) => {
    return api.post('api/rent-looking-step1.php', qs.stringify({
        userid,
        uid,
        flattype,
        renttype,
        approximaterent,
        homedesc,
        area,
        rentstartdate,
        rentcostnegotiation,
      }));
};

export const rentLookingStepOneEdit = (
    userid,
    uid,
    flattype,
    renttype,
    approximaterent,
    homedesc,
    area,
    rentstartdate,
    rentcostnegotiation,
    rentpostid
) => {
    return api.post('api/rent-looking-step1.php', qs.stringify({
        userid,
        uid,
        flattype,
        renttype,
        approximaterent,
        homedesc,
        area,
        rentstartdate,
        rentcostnegotiation,
        rentpostid
      }));
};


export const rentPostingImgDel = (userid, imageid) => {
    return api.post('api/rent-posting-step3.php', qs.stringify({
        userid,
        imageid
    }));
};