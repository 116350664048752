import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import axios from 'axios';
import qs from 'qs';
import { Link } from 'react-router-dom';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';
import Spinner from 'react-bootstrap/Spinner';

// Image
import doorImage from '../../../images/doorImage.svg';
import ButtonGoBack from '../../button/ButtonGoBack';
import ButtonGoBackRoute from '../../button/ButtonGoBackRoute';
import Registration from './Registration';

// Content Loader
// import DevtoCard from '../loader/DevtoCard';

function LogIn() {

  // const { rentid } = useParams();
  const [phoneNumber, setPhoneNumber] = useState('');
  const [password, setPassword] = useState('');
  const [forgotPassword, setForgotPassword] = useState(false);
  
  const [registered, setRegistered] = useState(0);
  const [activity, setActivity] = useState(1);
  const [checkedTerms, setCheckedTerms] = useState(true);
  const [isInvalidPassword, setIsInvalidPassword] = useState(false);

  const [isRegistrationActive, setIsRegistrationActive] = useState(false);

  const [loader, setLoader] = useState(false);


  const [device, setDevice] = useState(null);


  const saveUserDataToLocalStorage = (userData) => {
    try {
      // Convert the user data to a JSON string
      const userDataString = JSON.stringify(userData);
  
      // Store the JSON string in LocalStorage with a specific key
      localStorage.setItem('userData', userDataString);
    } catch (error) {
      // Handle errors, e.g., when LocalStorage is full or inoperative
      console.error('Error saving user data to LocalStorage:', error);
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  async function authorizeUser() {
    try {
      // Make an HTTP GET request using Axios
      const URL = `https://baribodol.com/api/v2/loginAPI.php?phone_number=88${phoneNumber}`;

      const response = await axios.get(URL);
      // Check if the response status code is 200 (OK)
      if (response.status === 200) {
        // Access the data from the response
        const data = response.data;
        setRegistered(data.registered);

        data.registered === 1 && setActivity(2);
        setLoader(false);

        data.registered === 0 && (
          // window.location.href = '/registration'
          // setIsRegistrationActive(true)
          window.location.href = `https://baribodol.com/registration_v2.php?phone_number=88${phoneNumber}`
        )
      } else {
        console.error('Unexpected response status:', response.status);
      }
    } catch (error) {
      // Handle errors, including network errors and HTTP errors
      console.error('An error occurred:', error);
    }
  }

  async function authorizeUserLogin(uphoneValue, upValue) {
    try {
      // Define the request payload
      // console.log(uphoneValue === null, upValue);
      const data = {
        phone_number: '88'+phoneNumber,
        password: password
      };

      const dataURL = {
        phone_number: uphoneValue,
        password: upValue
      };
  
      // // Make an HTTP POST request using Axios
      const URL = 'https://baribodol.com/api/login_submitAPI.php';
  
      const response = await axios.post(
        URL,
        qs.stringify(
          (uphoneValue !== undefined) ||
          (upValue !== undefined) ?
          dataURL : data
        )
      );

      // console.log(uphoneValue, upValue);
  
      if (response.status === 200) {
        console.log(response.data);
          if(response.data.success === 1) {
            saveUserDataToLocalStorage(response.data);
            
            if (device === 'android') {
              // const currentURL = window.location.href;
              const targetURL = 'https://baribodol.com/useronboard.html';
              const encodedPhone = encodeURIComponent(response.data.uphone);
              const encodedPassword = encodeURIComponent(response.data.upassword);
              
              if (encodedPhone && encodedPassword) {
                  // const newURL = `${currentURL}&uphone=${encodedPhone}&upass=${encodedPassword}`;
                  const newURL = `${targetURL}?uphone=${encodedPhone}&upass=${encodedPassword}`;
                  window.location.href = newURL;
              } else {
                  console.error('Phone number or password is empty.');
                  // Handle the case where phone number or password is empty
              }
          } else {
              window.location.href = '/';
          }
          
            
          } else {
            setIsInvalidPassword(true);
            setLoader(false);
          }
        
      } else {
        console.error('Unexpected response status:', response.status);
      }
    } catch (error) {
      // Handle errors, including network errors and HTTP errors
      console.error('An error occurred:', error);
    }
  }

  const [userData, setUserData] = useState(null);

  
  

  useEffect(() => {
    return () => {
      const queryParams = new URLSearchParams(window.location.search);
      
      // Get specific parameter values
      const uphoneValue = queryParams.get('uphone');
      const upValue = queryParams.get('up');
      const dValue = queryParams.get('d');

      // Log the values to the console
      console.log(`uphone: ${uphoneValue}`);
      console.log(`up: ${upValue}`);
      console.log(`d: ${dValue}`);

      // setPhoneNumber(uphoneValue);
      // setPassword(upValue);
       
      if (uphoneValue !== null && upValue !== null) {
        // authorizeUserLogin(uphoneValue, upValue);
      }

      if (dValue === null) {
        setDevice(dValue);
      } else {
        setDevice(null);
      }
      // console.log(getUserDataFromLS('userid'))
      // if(getUserDataFromLS('userid') !== null) {
      //   window.location.href = '/'
      // }
      // Cleanup code goes here
      // It will run when the component unmounts or when dependencies change
    };

    // function getQueryParams(url) {
    //   const queryString = url.split('?')[1];
    //   const queryParams = {};
    //   queryString.split('&').forEach(param => {
    //     const [key, value] = param.split('=');
    //     queryParams[key] = decodeURIComponent(value);
    //   });
    //   return queryParams;
    // }
    
    // // Usage
    // const queryParams = getQueryParams(window.location.href);
    // console.log(queryParams);


  }, []);

 


  // const handlePhoneNumber= (event ) => {
  //   const newValue = event.target.value;
  //   setPhoneNumber(newValue);
  // };

  const handleSubmit= () => {
    setLoader(true);
    if (activity === 1) {
      authorizeUser();
    } else if (activity === 2) {
      authorizeUserLogin();
    } else {
      // Handle other cases or do nothing
    }

  };

  const handleInputChange = (event, inputType) => {
    const newValue = event.target.value;

    if (inputType === 'phoneNumber') {
      setPhoneNumber(newValue);
    } else if (inputType === 'password') {
      setPassword(newValue);
    }
  };

  const handleChecked = () => {
    setCheckedTerms(!checkedTerms);
  }



  return (
    <>
      <Container>
        <Row>
          {/* <Col md={3}>
            
          </Col> */}
          {device}
          {device !== 'android' &&
            <ButtonGoBackRoute className="p-0 position-fixed btn-pcls btn btn-link" targetPath="/"/>
          }

          {!isRegistrationActive === true ?
            <>
            <Col md={{ span: 4, offset: 4}}>
              <Card className="p-4 border-0">
                <div className="m-auto my-4">
                  <img src={doorImage} alt="door" width={220} />
                </div>

                <h1 className="mb-2 mt-3 fs-18">
                  {activity === 1 ? 'আপনার ফোন নম্বর লিখুন' : activity === 2 ? 'পাসওয়ার্ড লিখুন' : null}
                </h1>

                {activity === 1 &&
                  <>
                    <FloatingLabel
                      controlId="floatingInput"
                      label="ফোন নম্বর"
                      className="mb-3"
                    >
                      <Form.Control type="text" placeholder="আপনার ফোন নম্বর লিখুন" value={phoneNumber} onChange={(event) => handleInputChange(event, 'phoneNumber')} />
                    </FloatingLabel>
                    
                    <Form.Group className="" controlId="formBasicCheckbox" >
                      <Form.Check type="checkbox" label="আমি সব শর্ত মেনে নিয়েছি" checked={checkedTerms} onChange={handleChecked} />
                    </Form.Group>

                    <Link className="fs-12 my-3" to="/privacy-policy">শর্তাবলী এবং নীতিমালা</Link>
                  </>
                }

                {registered === 1 && activity === 2 &&
                  <FloatingLabel
                    controlId="floatingInput"
                    label="পাসওয়ার্ড"
                    className="mb-3"
                  >
                  <Form.Control isInvalid={isInvalidPassword} type="password" placeholder="পাসওয়ার্ড" value={password} onChange={(event) => handleInputChange(event, 'password')} />
                  <Form.Control.Feedback type="invalid" className="mb-2">
                    ভুল পাসওয়ার্ড, আবার চেষ্টা করুন
                  </Form.Control.Feedback>
                  <Button variant="link" className="fs-12 p-0 mt-2" 
                    onClick={() => 
                      window.location.href = `https://baribodol.com/registration_v2.php?phone_number=88${phoneNumber}`
                    }>
                    পাসওয়ার্ড রিসেট করুন
                  </Button>
                </FloatingLabel>
                }
                

                
                  

                
                <Button variant="primary" 
                  disabled={
                    (activity === 1 && (phoneNumber.length < 11 || !checkedTerms)) ||
                    (activity === 2 && password.length < 6) ||
                    loader
                  } onClick={handleSubmit}>
                  {!loader ? 
                    'সাবমিট' :
                    <Spinner animation="border" role="status" size="sm">
                      <span className="visually-hidden">Loading...</span>
                    </Spinner>
                  }
                  
                </Button>
              </Card>
            </Col>
            </>
          :
          <Registration phone_number={phoneNumber} />
          }

        </Row>
        
      </Container>
    </>
  );
}

export default LogIn;
