import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ListGroup from 'react-bootstrap/ListGroup';
import Form from 'react-bootstrap/Form';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Spinner from 'react-bootstrap/Spinner';
import Alert from 'react-bootstrap/Alert';
import axios from 'axios';
import qs from 'qs';

// Component
import Logo from '../../../images/logo.svg';
import { Link } from 'react-router-dom';
import ButtonGoBack from '../../button/ButtonGoBack';



function DeleteAccount() {
    const [phoneNumber, setPhoneNumber] = useState('');
    const [password, setPassword] = useState('');

    const [success, setSuccess] = useState('');

    const [show, setShow] = useState(true);
    const [spinner, setSpinner] = useState(false);

    const handleSubmit = (event, type) => {
        setSpinner(true);
        authorizeUserLogin(phoneNumber, password);
    }

    const handleInputChange = (event, type) => {
        const newValue = event.target.value;
    
        const stateSetterMap = {
          'phoneNumber': setPhoneNumber,
          'password': setPassword,
        };
        
        const setState = stateSetterMap[type];
    
        // Call the state setter function with the toggled value if it exists
        if (setState) {
          setState(newValue);
        }
      };

      async function authorizeUserLogin(phoneNumber, password) {
        try {
          // Define the request payload
          // console.log(uphoneValue === null, upValue);
          const data = {
            phone_number: '88'+phoneNumber,
            password: password,
            del: true,
          };
    
          const dataURL = {
            phone_number: phoneNumber,
            password: password,
            del: true,
          };
      
          // // Make an HTTP POST request using Axios
          const URL = 'https://baribodol.com/api/login_submitAPI.php';
      
          const response = await axios.post(
            URL,
            qs.stringify(
              (phoneNumber !== undefined) ||
              (password !== undefined) ?
              dataURL : data
            )
          );
    
          // console.log(uphoneValue, upValue);
      
          if (response.status === 200) {
            console.log(response.data);
            setSuccess(response.data.success);
            setSpinner(false);
          } else {
            console.error('Unexpected response status:', response.status);
          }
        } catch (error) {
          // Handle errors, including network errors and HTTP errors
          console.error('An error occurred:', error);
        }
      }

  return (
    <>
    <div className="page-title bg-light">
        <Container fluid className="text-center py-5">
            <Link to="/">
                <img className="img-fluid mb-5" src={Logo} alt="Baribodol" />
            </Link>
            <ButtonGoBack className="p-0 position-fixed btn-pcls btn btn-link bg-transparent" />
            <h1>Delete Account</h1>
            <p>
                Please provide your details to delete your account.
            </p>
        </Container>
    </div>
    <div className="container p-5 bg-white mt-3 mb-3">
        <Alert className="w-50 m-auto mb-4" show={success === 0} variant="danger">
            <p className='m-0'>
                Invalid credentials!
            </p>
        </Alert>

        {success !== 1 &&
            <Form className="w-50 m-auto">
                <FloatingLabel
                    controlId="floatingInput"
                    label="Phone Number *"
                    className="mb-3"
                >
                    <Form.Control type="text" placeholder="Phone Number" disabled={false} value={phoneNumber} onChange={(event) => handleInputChange(event, 'phoneNumber')} />
                </FloatingLabel>

                <FloatingLabel
                    controlId="floatingInput"
                    label="Password *"
                    className="mb-3"
                >
                    <Form.Control type="password" placeholder="Password" disabled={false} value={password} onChange={(event) => handleInputChange(event, 'password')} />
                </FloatingLabel>
                <Button variant="danger" disabled={phoneNumber.length !== 11 || password.length < 6 || spinner === true} className={`fs-14 btn-danger me-2 ${phoneNumber.length === 11 && password.length < 6 && 'active'}`} onClick={()=>handleSubmit()}>
                    {spinner === true &&
                        <Spinner size="sm" className="me-2"/>
                    }
                    Delete your account
                </Button>
            </Form>
        }

        <Alert className="w-50 m-auto" show={success === 1} variant="danger">
            <Alert.Heading>Delete request has been sent</Alert.Heading>
            <p>
                Your account delete request has been submited. We will process it as soon as possible.
            </p>
            <hr />
            <div className="d-flex justify-content-end">
            <Link className="btn btn-dark" to="/" variant="outline-danger">
                Go to home
            </Link>
            </div>
        </Alert>
    </div>
    </>
  );
}

export default DeleteAccount;
