import React, { useEffect, useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useParams } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';

import AppContext from '../../../context/AppContext';

import buildingImage from '../../../../src/images/building.svg';

// Content Loader
import Catalog from '../../loader/Catalog';

import { formattedDate, getUserDataFromLS, convertNumberEn2Bn, getIsAndroidAPKFromLS } from '../../../helpers/utility';

import { getRentPostingType, rentPostingCommonStepOne, getRentPostingEditInfo, rentPostingCommonStepOneEdit } from '../../api/Api'; // Import your API functions
import ButtonGoBack from '../../button/ButtonGoBack';
import LocationSearch from '../../../helpers/LocationSearch';




function RentPropertyStepTwo() {

  const { rentid, rentsegment, flattype } = useParams();

  const [rentPostingTypeList, setRentPostingTypeList] = useState([]);
  const [showContentLoader, setShowContentLoader] = useState(true);
  const [startDate, setStartDate] = useState(new Date());

  // Spinner
  const [spinner, setSpinner] = useState(false);
  

  const { state, dispatch } = useContext(AppContext);

  const [show, setShow] = useState(false);
  const [rentpostid, setRentPostId] = useState('');

  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [rentcost, setRentcost] = useState('');
  const [negotiate, setNegotiate] = useState(false);
  const [floorno, setFloorno] = useState(0);
  const [sqrfeet, setSqrfeet] = useState('');
  const [rentdate, setRentDate] = useState(new Date());
  const [rentenddate, setRentEndDate] = useState(new Date());

  const [area, setArea] = useState('');
  const [locationName, setLocationName] = useState('');
  const [locationNameBangla, setLocationNameBangla] = useState('');
  const [locationSearchVisible, setLocationSearchVisible] = useState(false);

  const [address, setAddress] = useState('');




  // Toast message
  const [showToast, setShowToast] = useState(false);
  const toggleShowToast = () => setShowToast(!showToast);

  const fetchPostingEditInfo = () => {
    getRentPostingEditInfo(rentid)
      .then((response) => {
        console.log(response.data);

        setFloorno(response.data.data[0].floorno);
        setTitle(response.data.data[0].title);
        setDescription(response.data.data[0].description);
        setRentcost(response.data.data[0].cost);
        setNegotiate(response.data.data[0].rentnegotiable);
        setSqrfeet(response.data.data[0].sqrfeet);
        setRentDate(new Date(response.data.data[0].rentstartdate));
        setRentEndDate(new Date(response.data.data[0].postdeadline));
        dispatch({ type: 'UPDATE_LOCATION_AREA', payload: response.data.data[0].area});
        dispatch({ type: 'UPDATE_LOCATION_AREA_NAME', payload: response.data.data[0].areaname});
        
        setAddress(response.data.data[0].location);

      })
      .catch((error) => {
          console.error('API error:', error);
      });
  };

  const handleInputChange = (event, type) => {
    
    const newValue = event.target.value;

    const stateSetterMap = {
      'title': setTitle,
      'rentcost': setRentcost,
      'negotiate': setNegotiate,
      'sqrfeet': setSqrfeet,
      'rentdate': setRentDate,
      'rentenddate': setRentEndDate,
      'area': setArea,
      'locationName': setLocationName,
      'locationNameBangla': setLocationNameBangla,
      'address': setAddress,
      'description': setDescription,
    };

    const setState = stateSetterMap[type];

    // Call the state setter function with the toggled value if it exists
    if (setState) {
      setState(newValue);
    }
  };






  const catalogComponents = [];

  for (let i = 0; i < 5; i++) {
    catalogComponents.push(<Catalog key={i} />);
  }



  useEffect(() => {
    setRentPostId(rentid);
    fetchPostingEditInfo(rentid);
  }, []);

  const handleNegotiate = () => {
    setNegotiate(!negotiate);
    setRentcost('');
  }

  
  

  const handleRentPosting = () => {
    setSpinner(true);
    // console.log(
    //     getUserDataFromLS('userid'),
    //     getUserDataFromLS('uid'),
    //     flattype,
    //     rentsegment,
    //     description,
    //     state.locationArea,
    //     sqrfeet,
    //     formattedDate(rentdate),
    //     formattedDate(rentenddate),
    //     getUserDataFromLS('uphone'),
    //     floorno,
    //     title,
    //     rentcost,
    //     address,
    //     negotiate === true ? 1 : 0,
    //   )
    rentid === undefined ?
      rentPostingCommonStepOne(
        getUserDataFromLS('userid'),
        getUserDataFromLS('uid'),
        flattype,
        rentsegment,
        description,
        state.locationArea,
        sqrfeet,
        formattedDate(rentdate),
        formattedDate(rentenddate),
        getUserDataFromLS('uphone'),
        floorno,
        title,
        rentcost,
        address,
        negotiate === true ? 1 : 0,
      )
      .then((response) => {
        console.log(response.data);
        if (response.data.success === 1) {
          window.location.href = `/rent-posting-photos/${response.data.rentid}`;
        } else {
          // Handle the case when the response is not successful
          console.error("API request was not successful");
          // You might want to show an error message to the user or take other actions.
        }
      })
      .catch((error) => {
        console.error('API error:', error);
      }) :
      rentPostingCommonStepOneEdit(
        getUserDataFromLS('userid'),
        getUserDataFromLS('uid'),
        flattype,
        rentsegment,
        description,
        state.locationArea,
        sqrfeet,
        formattedDate(rentdate),
        formattedDate(rentenddate),
        getUserDataFromLS('uphone'),
        floorno,
        title,
        rentcost,
        address,
        negotiate === true ? 1 : 0,
        rentid
      )
      .then((response) => {
        console.log(response.data);
        if (response.data.success === 1) {
          window.location.href = `/rent-posting-photos/${response.data.rentid}`;
        } else {
          // Handle the case when the response is not successful
          console.error("API request was not successful");
          // You might want to show an error message to the user or take other actions.
        }
      })
      .catch((error) => {
        console.error('API error:', error);
      })
  }

  const handleRentDate = (date) => {
    const fDate = formattedDate(date);
    console.log(fDate);
  }

  const handleClose = () => setShow(false);
  const handleShow = (rentid) => {
    setShow(true);
  };

  const handleIncDec = (type, action) => {
    const updateTemp = (setter, currentValue, minValue = 0) => {
      if (action === "inc") {
        setter(currentValue + 1);
      } else if (action === "dec" && currentValue > minValue) {
        setter(currentValue - 1);
      }
    };
    if (type === "floorno") {
      updateTemp(setFloorno, floorno);
    }
  };

  return (
    <Container>
          <Row>
            {getIsAndroidAPKFromLS() === false &&
              <Col md="3">
              <ButtonGoBack targetPath="/rent-posting-type"/>
              </Col>
            }
            <Col md="6">
              <div className="content-section pt-md-4 ps-md-4 mt-m-big">
                  {/* {console.log(negotiate)} */}
                  {/* {rentpostid}, {getUserDataFromLS('userid')} {getUserDataFromLS('uid')} */}
                  <Form>
                    <div className="filters-all">
                    <h2 className="fs-18 mb-4 text-primary">ভাড়ার বিবরণ</h2>

                    <div className="mb-3 d-flex justify-content-between align-items-center">
                        <h3 className="fs-14 mb-0 fw-bold">কত তলাতে অবস্থিত *</h3>
                        <div className="d-flex">
                          <Button variant="light" className="btn-rounded btn-idc" onClick={() => handleIncDec("floorno", "dec")}>-</Button>
                          <span className="form-control d-inline-block text-center mx-2 btn-rounded px-4 fs-14 idc-val">
                            {floorno === 0 ? ('নিচ তলা') : (
                                convertNumberEn2Bn(`${floorno} তলা`)
                            )}
                          </span>
                          <Button variant="light" className="btn-rounded btn-idc" onClick={() => handleIncDec("floorno", "inc")}>+</Button>
                        </div>
                      </div>
                    
                      <div className="mb-4">
                        <FloatingLabel
                          controlId="floatingInput"
                          label="টাইটেল/ শিরোনাম লিখুন"
                          className="mb-3"
                        >
                          <Form.Control type="text" placeholder="" value={title} onChange={(event) => handleInputChange(event, 'title')} />
                        </FloatingLabel>
                      </div>

                      <div className="mb-4">
                        <FloatingLabel
                          controlId="floatingTextarea"
                          label="ভাড়ার বর্ণনা *"
                          className="mb-3"
                        >
                          <Form.Control as="textarea" placeholder="Leave a comment here" value={description} onChange={(event) => handleInputChange(event, 'description')} />
                        </FloatingLabel>
                      </div>


                      <div className="mb-4">
                        <FloatingLabel
                          controlId="floatingInput"
                          label="ভাড়ার পরিমান *"
                          className="mb-3"
                        >
                          <Form.Control type="text" placeholder="ভাড়ার পরিমাণ লিখুন" disabled={negotiate} value={rentcost} onChange={(event) => handleInputChange(event, 'rentcost')} />
                        </FloatingLabel>
                        <Form.Check
                          type="checkbox"
                          id="Default"
                          label="আলোচনার সাপেক্ষে"
                          checked={negotiate == 1}
                          onChange={()=> handleNegotiate()}
                        />
                      </div>

                      <div className="mb-4">
                        <FloatingLabel
                          controlId="floatingInput"
                          label="স্কয়ার ফিট"
                          className="mb-3"
                        >
                          <Form.Control type="text" placeholder="" value={sqrfeet} onChange={(event) => handleInputChange(event, 'sqrfeet')} />
                        </FloatingLabel>
                      </div>

                      <h2 className="fs-18 mt-5 mb-4 text-primary">ভাড়া সংক্রান্ত তথ্য</h2>

                      <div className="mb-4">
                          <span class="fw-bold">কোন তারিখ থেকে ভাড়া হবে? *</span>
                          <DatePicker className="form-control w-100" selected={rentdate} onChange={(date) => setRentDate(date)} />
                      </div>

                      <div className="mb-4">
                          <span class="fw-bold">কোন তারিখ পর্যন্ত বিজ্ঞাপনটি সাইটে থাকবে? *</span>
                          <DatePicker className="form-control w-100" selected={rentenddate} onChange={(date) => setRentEndDate(date)} />
                      </div>

                      <Modal show={show} onHide={handleClose} className="bg-blur modal-location">
                        <Modal.Body className="p-3">
                          <div className="text-center">
                            <img src={buildingImage} alt={`location search`} className="mb-4 m-auto d-inline-block" width={250} />
                          </div>
                          <LocationSearch className="mt-5"/>
                        </Modal.Body>

                        <Modal.Footer className="d-flex justify-content-between border-0 pt-0" onClick={()=>handleClose()}>
                          <Button variant="primary" className="btn-dark">
                            সেট করুন
                          </Button>
                        </Modal.Footer>
                      </Modal>


                      <div className="mb-4" onClick={() => setShow(true)}>
                          <FloatingLabel
                            controlId="floatingInput"
                            label="এলাকা *"
                            className="mb-3"
                          >
                            <Form.Control type="text" placeholder="" value={state.locationAreaName} />
                          </FloatingLabel>
                        </div>

                      <div className="mb-4">
                        <FloatingLabel
                          controlId="floatingTextarea"
                          label=" বাসার ঠিকানা *"
                          className="mb-3"
                        >
                          <Form.Control as="textarea" placeholder="Leave a comment here" value={address} onChange={(event) => handleInputChange(event, 'address')} />
                        </FloatingLabel>
                      </div>



                      <Button variant="primary" disabled={spinner} className="btn-rounded w-100" onClick={()=> handleRentPosting()}>
                        {spinner === true ?
                          <Spinner animation="border" role="status" size="sm">
                            <span className="visually-hidden">Loading...</span>
                          </Spinner>
                          :
                          'পরবর্তী ধাপ'
                        }
                      </Button>
                    </div>
                  </Form>
                </div>
            </Col>
        </Row>


    </Container>
  )
}

export default RentPropertyStepTwo;
