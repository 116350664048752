import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './styles/main.scss';
import RentList from './components/pages/RentList';
import RentDetails from './components/pages/RentDetails';

// Auth screens
import { AuthProvider, useAuth } from './context/AuthContext';
// import PrivateRoute from './components/pages/auth/PrivateRoute';

// Context API
import { AppProvider } from './context/AppContext';

// React router dom
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

// Auth screens
import LogIn from './components/pages/auth/LogIn';
import Registration from './components/pages/auth/Registration';

import MyAccount from './components/pages/user/MyAccount';
import FavRents from './components/pages/user/FavRents';
import Profile from './components/pages/user/Profile';
import PrivateRoute from './components/pages/auth/PrivateRoute';

// Rent Posting
import RentPostingType from './components/pages/rentpost/RentPostingType';
import RentPropertyStepOne from './components/pages/rentpost/RentPropertyStepOne';
import RentPropertyStepTwo from './components/pages/rentpost/RentPropertyStepTwo';
import RentPropertyCommonStepTwo from './components/pages/rentpost/RentPropertyCommonStepTwo';
import RentPropertyComplete from './components/pages/rentpost/RentPropertyComplete';
import RentPropertyStepThree from './components/pages/rentpost/RentPropertyStepThree';
import RentLookingStepOne from './components/pages/rentpost/RentLookingStepOne';

import RentPostSuccess from './components/status/RentPostSuccess';

// Static Pages
import PrivacyPolicy from './components/pages/staticPages/PrivacyPolicy';
import DeleteAccount from './components/pages/staticPages/DeleteAccount';
import MoreNavigation from './components/pages/user/MoreNavigation';
import LogInD from './components/pages/auth/LoginD';
import LogOut from './components/pages/auth/LogOut';


function App() {

  // const { isAuthenticated } = useAuth();
  const isAuthenticated = !!localStorage.getItem('userData');


  return (
    
      <div className="App">
        <Router>
            <AppProvider>
              <Routes>
                <Route path="/" element={<RentList />} />
                <Route path="/:device" element={<RentList />} />
                <Route path="/registration" element={<Registration />} />
                <Route path="/bn/rent/:rentid" element={<RentDetails />} />
                <Route path="/bn/rent/:rentid/:title" element={<RentDetails />} />
                <Route
                  path="/login"
                  element={<PrivateRoute element={<LogIn />} isAuthenticated={!isAuthenticated} />}
                />
                <Route
                  path="/login-d"
                  element={<PrivateRoute element={<LogInD />} isAuthenticated={!isAuthenticated} />}
                />
                <Route path="/logout" element={<LogOut />} />
                <Route
                  path="/my-account"
                  element={<PrivateRoute element={<MyAccount />} isAuthenticated={isAuthenticated} />}
                />
                <Route
                  path="/favorites"
                  element={<PrivateRoute element={<FavRents />} isAuthenticated={isAuthenticated} />}
                />
                <Route
                  path="/profile"
                  element={<PrivateRoute element={<Profile />} isAuthenticated={isAuthenticated} />}
                />
                <Route
                  path="/rent-posting-type"
                  element={<PrivateRoute element={<RentPostingType />} isAuthenticated={isAuthenticated} />}
                />
                <Route
                  path="/rent-posting-type/:device"
                  element={<PrivateRoute element={<RentPostingType />} isAuthenticated={isAuthenticated} />}
                />
                <Route
                  path="/rent-posting-property-details"
                  element={<PrivateRoute element={<RentPropertyStepOne />} isAuthenticated={isAuthenticated} />}
                />
                <Route
                  path="/rent-posting-property-details/:rentid"
                  element={<PrivateRoute element={<RentPropertyStepOne />} isAuthenticated={isAuthenticated} />}
                />

                <Route
                  path="/rent-posting-looking-details"
                  element={<PrivateRoute element={<RentLookingStepOne />} isAuthenticated={isAuthenticated} />}
                />
                <Route
                  path="/rent-posting-looking-details/:rentid"
                  element={<PrivateRoute element={<RentLookingStepOne />} isAuthenticated={isAuthenticated} />}
                />


                <Route
                  path="/rent-posting-common-amenities"
                  element={<PrivateRoute element={<RentPropertyCommonStepTwo />} isAuthenticated={isAuthenticated} />}
                />
                <Route
                  path="/rent-posting-common-amenities/:rentsegment/:flattype"
                  element={<PrivateRoute element={<RentPropertyCommonStepTwo />} isAuthenticated={isAuthenticated} />}
                />
                <Route
                  path="/rent-posting-common-amenities/:rentsegment/:flattype/:rentid"
                  element={<PrivateRoute element={<RentPropertyCommonStepTwo />} isAuthenticated={isAuthenticated} />}
                />
                 <Route
                  path="/rent-posting-amenities/:rentid"
                  element={<PrivateRoute element={<RentPropertyStepTwo />} isAuthenticated={isAuthenticated} />}
                />
                <Route
                  path="/rent-posting-photos/:rentid"
                  element={<PrivateRoute element={<RentPropertyStepThree />} isAuthenticated={isAuthenticated} />}
                />
                <Route
                  path="/rent-posting-complete/:rentid"
                  element={<PrivateRoute element={<RentPropertyComplete />} isAuthenticated={isAuthenticated} />}
                />

                <Route
                  path="/rent-posting-success/:rentid"
                  element={<PrivateRoute element={<RentPostSuccess />} isAuthenticated={isAuthenticated} />}
                />

                {/* Static Pages */}
                <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                <Route path="/delete-account" element={<DeleteAccount />} />
                <Route path="/more" element={<MoreNavigation />} />
              </Routes>

              
            </AppProvider>
          
        </Router>
      </div>
    
  );
}

export default App;
