import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ListGroup from 'react-bootstrap/ListGroup';

// Component
import Logo from '../../../images/logo.svg';
import { Link } from 'react-router-dom';
import ButtonGoBack from '../../button/ButtonGoBack';

function Keywords() {
  return (
    <>
    {/* <div className="page-title bg-light">
        Text
    </div> */}
    </>
  );
}

export default Keywords;
