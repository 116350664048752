import React, { useEffect, useState, useContext } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ListGroup from 'react-bootstrap/ListGroup';
import Form from 'react-bootstrap/Form';
import AppContext from '../context/AppContext';
import { getLocationList } from '../components/api/Api';


function LocationSearch() {

  const { state, dispatch } = useContext(AppContext);
  const [searchText, setSearchText] = useState('');

  // Visibility for location list while searching
  const [locationArea, setLocationArea] = useState('');
  const [locationAreaName, setLocationAreaName] = useState('');
  const [visibleLocationList, setVisibleLocationList] = useState(false);


  useEffect(() => {
    const locationList = localStorage.getItem('locationList');
    if (locationList === null) {
      fetchDataLocationList();
    } else {
      dispatch({ type: 'UPDATE_LOCATIONLIST', payload: JSON.parse(locationList) });
    }
  }, []);
  
  const saveLocationListToLocalStorage = (data) => {
    try {
      // Convert the user data to a JSON string
      const userDataString = JSON.stringify(data);
  
      // Store the JSON string in LocalStorage with a specific key
      localStorage.setItem('locationList', userDataString);
    } catch (error) {
      // Handle errors, e.g., when LocalStorage is full or inoperative
      console.error('Error saving user data to LocalStorage:', error);
    }
  };


    const fetchDataLocationList = () => {
        getLocationList().then((response) => {
            if (response.status === 200) {
                saveLocationListToLocalStorage(response.data.data);
                dispatch({ type: 'UPDATE_LOCATIONLIST', payload: response.data.data });
            } else {
                console.error('Unexpected response status:', response.status);
            }
        })
        .catch((error) => {
            console.error('API error:', error);
        });
    };

  const handleInputChange = (e) => {
    setSearchText(e.target.value);
    // console.log(e.target.value.length);
    e.target.value.length > 2 && setVisibleLocationList(true);
  };

  const handleLocationArea = (locationid, locationNameBangla) => {
    setSearchText(locationNameBangla);

    dispatch({ type: 'UPDATE_LOCATION_AREA', payload: locationid});
    dispatch({ type: 'UPDATE_LOCATION_AREA_NAME', payload: locationNameBangla});
    setVisibleLocationList(false);
  };

  const locationList = state.locationList;

  const filteredLocations = locationList.filter((location) =>
    location.locationName.toLowerCase().includes(searchText.toLowerCase()) ||
    location.locationNameBangla.includes(searchText)
  );

  return (
    <>
    <div className="position-relative">
    <Form>
        <Form.Group className="location-search big inner-icon position-relative" controlId="exampleForm.ControlInput1">
            <svg className="icon position-absolute" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M7.4707 1.6875C10.571 1.6875 13.0957 4.21216 13.0957 7.3125C13.0957 8.65942 12.6233 9.89429 11.8301 10.8633L16.3125 15.3457L15.5039 16.1543L11.0215 11.6719C10.0525 12.4651 8.81763 12.9375 7.4707 12.9375C4.37036 12.9375 1.8457 10.4128 1.8457 7.3125C1.8457 4.21216 4.37036 1.6875 7.4707 1.6875ZM7.4707 2.8125C4.979 2.8125 2.9707 4.8208 2.9707 7.3125C2.9707 9.8042 4.979 11.8125 7.4707 11.8125C9.9624 11.8125 11.9707 9.8042 11.9707 7.3125C11.9707 4.8208 9.9624 2.8125 7.4707 2.8125Z" fill="black"/>
            </svg>
            <Form.Control 
            type="text"
            className="location lg"
            placeholder="এলাকা *" 
            value={searchText}
            onChange={handleInputChange}
            />
        </Form.Group>
    </Form>
    {visibleLocationList &&
        <ListGroup as="ul" className="location-list lg shadow mt-1 position-absolute w-100">
          {filteredLocations.map((location) => (
            <ListGroup.Item as="li" className="border-0" key={location.locationid} onClick={() => handleLocationArea(location.locationid, location.locationNameBangla)}>
              <svg className="icon me-3" width="13" height="18" viewBox="0 0 13 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M6.05607 0C2.7205 0 0 2.7205 0 6.05608C0 7.00234 0.383762 8.08791 0.904206 9.27337C1.42465 10.4588 2.09492 11.7179 2.7757 12.8902C4.13727 15.2374 5.50935 17.2009 5.50935 17.2009L6.05607 18L6.6028 17.2009C6.6028 17.2009 7.97488 15.2374 9.33645 12.8902C10.0172 11.7179 10.6875 10.4588 11.2079 9.27337C11.7284 8.08791 12.1121 7.00234 12.1121 6.05608C12.1121 2.7205 9.39165 0 6.05607 0ZM6.05607 1.34579C8.66618 1.34579 10.7664 3.44597 10.7664 6.05608C10.7664 6.59492 10.4772 7.61478 9.98832 8.72664C9.49942 9.83849 8.82389 11.0713 8.15888 12.2173C7.10222 14.0415 6.44509 15.0061 6.05607 15.5818C5.66706 15.0061 5.00993 14.0415 3.95327 12.2173C3.28826 11.0713 2.61273 9.83849 2.12383 8.72664C1.63493 7.61478 1.34579 6.59492 1.34579 6.05608C1.34579 3.44597 3.44597 1.34579 6.05607 1.34579ZM6.05607 4.71028C5.31221 4.71028 4.71028 5.31221 4.71028 6.05608C4.71028 6.79994 5.31221 7.40187 6.05607 7.40187C6.79994 7.40187 7.40187 6.79994 7.40187 6.05608C7.40187 5.31221 6.79994 4.71028 6.05607 4.71028Z" fill="black"/>
              </svg>
              {location.locationNameBangla}
              </ListGroup.Item>
          ))}
        </ListGroup>
      }
      </div>
      </>
  );
}

export default LocationSearch;
