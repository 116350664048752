import React, { useEffect, useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import { useParams } from 'react-router-dom';
import AppContext from '../../../context/AppContext';

// Content Loader
import Catalog from '../../loader/Catalog';

import { getUserDataFromLS, convertNumberEn2Bn, getIsAndroidAPKFromLS } from '../../../helpers/utility'

import { getRentPostingEditInfo, rentPostingStepOne, rentPostingStepOneEdit } from '../../api/Api'; // Import your API functions
import ButtonGoBack from '../../button/ButtonGoBack';



function RentPropertyStepOne() {
  const { rentid } = useParams();


  const [rentPostingTypeList, setRentPostingTypeList] = useState([]);
  const [showContentLoader, setShowContentLoader] = useState(true);

  const [flatType, setFlatType] = useState('');

  const [bachelor, setBachelor] = useState(false);
  const [family, setFamily] = useState(false);
  const [smallfamily, setSmallFamily] = useState(false);
  const [onlymalestudent, setOnlyMaleStudent] = useState(false);
  const [onlyfemalestudent, setOnlyFemaleStudent] = useState(false);
  const [onlymale, setOnlyMale] = useState(false);
  const [onlyfemale, setOnlyFemale] = useState(false);
  const [malejobholder, setMaleJobHolder] = useState(false);
  const [femalejobholder, setFemaleJobHolder] = useState(false);


  const [bedrooms, setBedrooms] = useState(1);
  const [washrooms, setWashrooms] = useState(1);
  const [veranda, setVeranda] = useState(1);
  const [floorno, setFloorno] = useState(0);
  

  const { state } = useContext(AppContext);

  const [show, setShow] = useState(false);
  const[rentId, setRentId] = useState('');

  // Toast message
  const [showToast, setShowToast] = useState(false);
  const toggleShowToast = () => setShowToast(!showToast);

  // Spinner
  const [spinner, setSpinner] = useState(false);


  const handleFilterBtnClickFlatType = (type) => {
    setFlatType(type);
  }

  const handleFilterBtnClickRentType = (type) => {
    const stateSetterMap = {
      'bachelor,': setBachelor,
      'family,': setFamily,
      'smallfamily,': setSmallFamily,
      'onlymalestudent,': setOnlyMaleStudent,
      'onlyfemalestudent,': setOnlyFemaleStudent,
      'onlymale,': setOnlyMale,
      'onlyfemale,': setOnlyFemale,
      'malejobholder,': setMaleJobHolder,
      'femalejobholder,': setFemaleJobHolder,
    };
  
    const toggleState = (stateSetter) => {
      if (typeof stateSetter === 'function') {
        stateSetter((prevState) => !prevState);
      } else {
        console.error('Invalid stateSetter function:', stateSetter);
      }
    };
  
    const setState = stateSetterMap[type];
  
    if (setState) {
      toggleState(setState);
    } else {
      console.error(`State setter not found for type: ${type}`);
    }
  };


  const handleIncDec = (type, action) => {
    const updateTemp = (setter, currentValue, minValue = 0) => {
      if (action === "inc") {
        setter(currentValue + 1);
      } else if (action === "dec" && currentValue > minValue) {
        setter(currentValue - 1);
      }
    };
  
    if (type === "bedrooms") {
      updateTemp(setBedrooms, bedrooms);
    }

    if (type === "washrooms") {
      updateTemp(setWashrooms, washrooms);
    }
  
    if (type === "veranda") {
      updateTemp(setVeranda, veranda);
    }

    if (type === "floorno") {
      updateTemp(setFloorno, floorno);
    }
  };
  
  


  const catalogComponents = [];

  for (let i = 0; i < 5; i++) {
    catalogComponents.push(<Catalog key={i} />);
  }

  const handleRentPosting = () => {
    setSpinner(true);
    const conditions = [];
    if (bachelor === true) {
      conditions.push('bachelor');
    }
    if (family === true) {
      conditions.push('family');
    }
    if (smallfamily === true) {
      conditions.push('small family');
    }
    if (onlymalestudent === true) {
      conditions.push('only male student');
    }
    if (onlyfemalestudent === true) {
      conditions.push('only female student');
    }
    if (onlymale === true) {
      conditions.push('only male');
    }
    if (onlyfemale === true) {
      conditions.push('only female');
    }
    if (malejobholder === true) {
      conditions.push('male jobholder');
    }
    if (femalejobholder === true) {
      conditions.push('female jobholder');
    }
    
    const rentType = conditions.join(',');

    // console.log(rentType+',',);

    rentid === undefined ?

      rentPostingStepOne(
        getUserDataFromLS('userid'),
        getUserDataFromLS('uid'),
        flatType,
        ','+rentType+',',
        bedrooms,
        washrooms,
        veranda,
        floorno,
      )
      .then((response) => {
        console.log(response.data)
        if (response.data.success === 1) {
          window.location.href = `/rent-posting-amenities/${response.data.rentid}`;
        } else {
          // Handle the case when the response is not successful
          console.error("API request was not successful");
          // You might want to show an error message to the user or take other actions.
        }
      })
      .catch((error) => {
        console.error('API error:', error);
      })
      : 
      rentPostingStepOneEdit(
        getUserDataFromLS('userid'),
        getUserDataFromLS('uid'),
        flatType,
        ','+rentType+',',
        bedrooms,
        washrooms,
        veranda,
        floorno,
        rentid
      )
      .then((response) => {
        console.log(response.data)
        if (response.data.success === 1) {
          window.location.href = `/rent-posting-amenities/${response.data.rentid}`;
        } else {
          // Handle the case when the response is not successful
          console.error("API request was not successful");
          // You might want to show an error message to the user or take other actions.
        }
      })
      .catch((error) => {
        console.error('API error:', error);
      })
  };

  const fetchPostingEditInfo = () => {
    getRentPostingEditInfo(rentid)
      .then((response) => {
        // console.log(response.data.data[0]);
        setFlatType(response.data.data[0].type);
        // setBachelor(response.data.data[0].renttype);
        setBedrooms(response.data.data[0].bedrooms);
        setWashrooms(response.data.data[0].washrooms);
        setVeranda(response.data.data[0].veranda);
        setFloorno(response.data.data[0].floorno);

        const renttype = response.data.data[0].renttype;
        const renttypeArray = renttype.split(',').filter(Boolean);

        console.log(renttypeArray);

        {renttypeArray.map((renttype, index) => {
          if (renttype === 'bachelor') {
            setBachelor(true);
          }
          if (renttype === 'family') {
            setFamily(true);
          }
          if (renttype === 'small family') {
            setSmallFamily(true);
          }
          if (renttype === 'only male student') {
            setOnlyMaleStudent(true);
          }
          if (renttype === 'only female student') {
            setOnlyFemaleStudent(true);
          }
          if (renttype === 'only male') {
            setOnlyMale(true);
          }
          if (renttype === 'only female') {
            setOnlyFemale(true);
          }
          if (renttype === 'male jobholder') {
            setMaleJobHolder(true);
          }
          if (renttype === 'female jobholder') {
            setFemaleJobHolder(true);
          }
          return null; // or some other JSX if needed
        })}

        // {religionArray.map((religion, index) => {
        //   if (religion === 'islam') {
        //     setIslam('islam,');
        //   }
        //   if (religion === 'hindu') {
        //     setHindu('hindu,');
        //   }
        //   if (religion === 'christian') {
        //     setChristian('christian,');
        //   }
        //   if (religion === 'budda') {
        //     setBudda('budda,');
        //   }
        //   if (religion === 'any') {
        //     setAnyReligion('any,');
        //   }
        //   return null; // or some other JSX if needed
        // })}
          

        
      })
      .catch((error) => {
          console.error('API error:', error);
      });
  };

  useEffect(() => {
    // console.log(getUserDataFromLS('userid'));
    // console.log(getUserDataFromLS('uid'));
    fetchPostingEditInfo();
  }, []);



  return (
    <Container>
          <Row>
            <Col md="3">
              {/* {console.log(window.location.pathname)} */}
              {getIsAndroidAPKFromLS() === false &&
                <ButtonGoBack targetPath="/rent-posting-type"/>
              }
            </Col>
            <Col md="6">
              <div className="content-section pt-md-4 ps-md-4 mt-m-big">
                  {/* {
                    showContentLoader ? 
                      catalogComponents : 
                        rentPostingTypeList.length > 0 ?
                          typesAll : <NoData />
                  } */}

                  <div className="filters-all">
                    <h2 className="fs-18 mb-5 text-primary">ভাড়ার ধরন</h2>
                    <div className="filter-flat-type mb-5">
                      <h3 className="fs-14 mb-3 fw-bold">আপনি কি ভাড়া দিতে চান? *</h3>
                      <Button variant="light" className={`fs-14 btn-light me-2 ${flatType === 'flat,' && 'active'}`} onClick={()=>handleFilterBtnClickFlatType('flat,')}>ফ্লাট ভাড়া</Button>
                      <Button variant="light" className={`fs-14 btn-light me-2 ${flatType === 'seat,' && 'active'}`} onClick={()=>handleFilterBtnClickFlatType('seat,')}>সিট ভাড়া</Button>
                      <Button variant="light" className={`fs-14 btn-light ${flatType === 'sublet,' && 'active'}`} onClick={()=>handleFilterBtnClickFlatType('sublet,')}>সাবলেট ভাড়া</Button>
                    </div>
                    <div className="filter-rent-type mb-4">
                      <h3 className="fs-14 mb-3 fw-bold">আপনি কাদের কাছে ভাড়া দিতে চান? *</h3>
                      <Button variant="light" className={`fs-14 btn-light me-2 mb-2 ${bachelor && 'active'}`} onClick={()=>handleFilterBtnClickRentType('bachelor,')}>ব্যাচেলর</Button>
                      <Button variant="light" className={`fs-14 btn-light me-2 mb-2 ${family && 'active'}`} onClick={()=>handleFilterBtnClickRentType('family,')}>ফ্যামিলি</Button>
                      <Button variant="light" className={`fs-14 btn-light me-2 mb-2 ${smallfamily && 'active'}`} onClick={()=>handleFilterBtnClickRentType('smallfamily,')}>ছোট ফ্যামিলি</Button>
                      <Button variant="light" className={`fs-14 btn-light me-2 mb-2 ${onlymalestudent && 'active'}`} onClick={()=>handleFilterBtnClickRentType('onlymalestudent,')}>শুধুমাত্র ছাত্র</Button>
                      <Button variant="light" className={`fs-14 btn-light me-2 mb-2 ${onlyfemalestudent && 'active'}`} onClick={()=>handleFilterBtnClickRentType('onlyfemalestudent,')}>শুধুমাত্র ছাত্রী</Button>
                      <Button variant="light" className={`fs-14 btn-light me-2 mb-2 ${onlymale && 'active'}`} onClick={()=>handleFilterBtnClickRentType('onlymale,')}>শুধুমাত্র পুরুষ</Button>
                      <Button variant="light" className={`fs-14 btn-light me-2 mb-2 ${onlyfemale && 'active'}`} onClick={()=>handleFilterBtnClickRentType('onlyfemale,')}>শুধুমাত্র মহিলা</Button>
                      <Button variant="light" className={`fs-14 btn-light me-2 mb-2 ${malejobholder && 'active'}`} onClick={()=>handleFilterBtnClickRentType('malejobholder,')}>পুরুষ চাকরিজীবী</Button>
                      <Button variant="light" className={`fs-14 btn-light me-2 mb-2 ${femalejobholder && 'active'}`} onClick={()=>handleFilterBtnClickRentType('femalejobholder,')}>মহিলা চাকরিজীবী</Button>
                    </div>

                    <div className="mb-4 d-flex justify-content-between align-items-center">
                      <h3 className="fs-14 mb-0 fw-bold">বেড রুম *</h3>
                      <div className="d-flex">
                        <Button variant="light" className="btn-rounded btn-idc" onClick={() => handleIncDec("bedrooms", "dec")}>-</Button>
                        <span className="form-control d-inline-block text-center mx-2 btn-rounded px-4 fs-14 idc-val">
                          {bedrooms === 0 ? ('নাই') : (
                              convertNumberEn2Bn(`${bedrooms} টি`)
                          )}
                        </span>
                        <Button variant="light" className="btn-rounded btn-idc" onClick={() => handleIncDec("bedrooms", "inc")}>+</Button>
                      </div>
                    </div>

                    <div className="mb-4 d-flex justify-content-between align-items-center">
                      <h3 className="fs-14 mb-0 fw-bold">ওয়াস রুম *</h3>
                      <div className="d-flex">
                        <Button variant="light" className="btn-rounded btn-idc" onClick={() => handleIncDec("washrooms", "dec")}>-</Button>
                        <span className="form-control d-inline-block text-center mx-2 btn-rounded px-4 fs-14 idc-val">
                          {washrooms === 0 ? ('নাই') : (
                              convertNumberEn2Bn(`${washrooms} টি`)
                          )}
                        </span>
                        <Button variant="light" className="btn-rounded btn-idc" onClick={() => handleIncDec("washrooms", "inc")}>+</Button>
                      </div>
                    </div>

                    <div className="mb-4 d-flex justify-content-between align-items-center">
                      <h3 className="fs-14 mb-0 fw-bold">বারিন্দা সংখ্যা</h3>
                      <div className="d-flex">
                        <Button variant="light" className="btn-rounded btn-idc" onClick={() => handleIncDec("veranda", "dec")}>-</Button>
                        <span className="form-control d-inline-block text-center mx-2 btn-rounded px-4 fs-14 idc-val">
                          {veranda === 0 ? ('নাই') : (
                              convertNumberEn2Bn(`${veranda} টি`)
                          )}
                        </span>
                        <Button variant="light" className="btn-rounded btn-idc" onClick={() => handleIncDec("veranda", "inc")}>+</Button>
                      </div>
                    </div>

                    <div className="mb-5 d-flex justify-content-between align-items-center">
                      <h3 className="fs-14 mb-0 fw-bold">ফ্লাটটি কত তলাতে অবস্থিত *</h3>
                      <div className="d-flex">
                        <Button variant="light" className="btn-rounded btn-idc" onClick={() => handleIncDec("floorno", "dec")}>-</Button>
                        <span className="form-control d-inline-block text-center mx-2 btn-rounded px-4 fs-14 idc-val">
                          {floorno === 0 ? ('নিচ তলা') : (
                              convertNumberEn2Bn(`${floorno} তলা`)
                          )}
                        </span>
                        <Button variant="light" className="btn-rounded btn-idc" onClick={() => handleIncDec("floorno", "inc")}>+</Button>
                      </div>
                    </div>
                    <Button variant="primary" className="btn-rounded w-100" disabled={spinner ||  flatType === '' || (!bachelor && !family && !smallfamily && !onlymalestudent && !onlyfemalestudent && !onlymale && !onlyfemale && !malejobholder && !femalejobholder)} onClick={() => handleRentPosting()}>
                      {spinner === true ?
                        <Spinner animation="border" role="status" size="sm">
                          <span className="visually-hidden">Loading...</span>
                        </Spinner>
                        :
                        'পরবর্তী ধাপ'
                        }
                    </Button>

                    


                  </div>
                </div>
            </Col>
        </Row>


    </Container>
  )
}

export default RentPropertyStepOne;
