import React, { useEffect, useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Image from 'react-bootstrap/Image';
import Spinner from 'react-bootstrap/Spinner';
import ListGroup from 'react-bootstrap/ListGroup';

import AppContext from '../../../context/AppContext';

// Component
import Header from '../../layout/Header';

import { getUserDataFromLS, handleLogOut, updateLocalStorageProperty } from '../../../helpers/utility';
import { updatePersonalInfo } from '../../api/Api'; // Import your API functions
import { Button } from 'react-bootstrap';


function MoreNavigation() {
  const [userid, setUserId] = useState('');
  const [uname, setUname] = useState('');
  const [unameTemp, setUnameTemp] = useState('');
  const [uphone, setUphone] = useState('');
  const [uphoto, setUphoto] = useState('');
  const [uemail, setUemail] = useState('');
  const [spinner, setSpinner] = useState(false);

  const { state } = useContext(AppContext);

  const getUserData = () => {
    setUserId(getUserDataFromLS("userid"));
    setUname(getUserDataFromLS("uname"));
    setUnameTemp(getUserDataFromLS("uname"));
    setUphone(getUserDataFromLS("uphone"));
    setUphoto(getUserDataFromLS("uphoto"));
    setUemail(getUserDataFromLS("uemail"));
    spinner && setSpinner(false);
  };

  useEffect(() => {
    getUserData();
  }, []);

  const handleInputChange = (e, type) => {
    if(type === "uname") {
      setUname(e.target.value);
    } else if (type === "uemail") {
      setUemail(e.target.value);
    }
  };

  const handleSubmit = async (event) => {
    // event.preventDefault(); // Prevent the default form submission behavior
    setSpinner(true);
    try {
      // Assuming userid, uname, uphone, and uemail are state variables
      await updatePersonalInfo(userid, uname, uphone, uemail);
      setSpinner(false);
      updateLocalStorageProperty('uname', uname);
      updateLocalStorageProperty('uemail', uemail);
    } catch (error) {
      // Handle errors, e.g., show an error message
      console.error('Error updating personal info:', error);
    }
  };



  return (
    <Container>
          <Row>
            <Col md="3">
              <Header />
            </Col>
            <Col md="6">
              <div className="content-section pt-4 profile">
                <div className="d-flex justify-content-center align-items-center flex-column">
                  {getUserDataFromLS('userid') !== null &&
                  <>
                    <Image className="uphoto sm mb-3" src={`https://baribodol.com/images/profilephoto/${uphoto}`} fluid roundedCircle />
                    <h2 className="greetings mb-0">{unameTemp}</h2>
                    <p>{uphone}</p>
                  </>
                  }
                </div>
                <span className="mb-3 d-block">
                  <small>অ্যাকাউন্ট</small>
                </span>
                <ListGroup className="more-menu-group" variant="flush">
                  <ListGroup.Item className="ps-0 border-0">
                    <Link to="/privacy-policy" className="btn btn-link no-underline p-0 py-1 fs-14">
                      <svg className="icon d-inline-block me-3" height={20} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><path d="M 6 3 L 6 29 L 26 29 L 26 9.59375 L 25.71875 9.28125 L 19.71875 3.28125 L 19.40625 3 Z M 8 5 L 18 5 L 18 11 L 24 11 L 24 27 L 8 27 Z M 20 6.4375 L 22.5625 9 L 20 9 Z"/></svg>
                      প্রাইভেসি পলিসি
                    </Link>
                  </ListGroup.Item>
                  {getUserDataFromLS('userid') !== null ?
                  <>
                  <ListGroup.Item className="ps-0 border-0">
                    <Link to="/my-account" className="btn btn-link no-underline p-0 py-1 fs-14">
                      <svg className="icon d-inline-block me-3" height={20} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><path d="M 4 4 L 4 24 L 11 24 L 11 22 L 6 22 L 6 6 L 18 6 L 18 7 L 20 7 L 20 4 Z M 12 8 L 12 28 L 28 28 L 28 8 Z M 14 10 L 26 10 L 26 26 L 14 26 Z"/></svg>
                      আমার বিজ্ঞাপন সমূহ
                    </Link>
                  </ListGroup.Item>
                  <ListGroup.Item className="ps-0 border-0">
                    <Link to="/profile" className="btn btn-link no-underline p-0 py-1 fs-14">
                      <svg className="icon d-inline-block me-3" height={20} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><path d="M 16 5 C 12.144531 5 9 8.144531 9 12 C 9 14.410156 10.230469 16.550781 12.09375 17.8125 C 8.527344 19.34375 6 22.882813 6 27 L 8 27 C 8 22.570313 11.570313 19 16 19 C 20.429688 19 24 22.570313 24 27 L 26 27 C 26 22.882813 23.472656 19.34375 19.90625 17.8125 C 21.769531 16.550781 23 14.410156 23 12 C 23 8.144531 19.855469 5 16 5 Z M 16 7 C 18.773438 7 21 9.226563 21 12 C 21 14.773438 18.773438 17 16 17 C 13.226563 17 11 14.773438 11 12 C 11 9.226563 13.226563 7 16 7 Z"/></svg>
                      আমার প্রোফাইল
                    </Link>
                  </ListGroup.Item>
                  <ListGroup.Item className="ps-0 border-0">
                      <Button variant="link" className="btn btn-link no-underline p-0 py-1 fs-14" onClick={() => handleLogOut()}>
                        <svg className="icon d-inline-block me-3" height={20} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><path d="M 16 4 C 9.382813 4 4 9.382813 4 16 C 4 22.617188 9.382813 28 16 28 C 20.050781 28 23.640625 25.988281 25.8125 22.90625 L 24.1875 21.75 C 22.378906 24.320313 19.390625 26 16 26 C 10.464844 26 6 21.535156 6 16 C 6 10.464844 10.464844 6 16 6 C 19.390625 6 22.375 7.679688 24.1875 10.25 L 25.8125 9.09375 C 23.640625 6.011719 20.050781 4 16 4 Z M 23.34375 11.28125 L 21.90625 12.71875 L 24.1875 15 L 12 15 L 12 17 L 24.1875 17 L 21.90625 19.28125 L 23.34375 20.71875 L 27.34375 16.71875 L 28.03125 16 L 27.34375 15.28125 Z"/></svg>
                        লগ আউট
                      </Button>
                  </ListGroup.Item>
                  {/* <ListGroup.Item className="ps-0 border-0">
                    <Link to="/change-password" className="btn btn-link no-underline p-0 py-1 fs-14">
                      <svg className="icon d-inline-block me-3" height={20} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><path d="M 16 3 C 12.15625 3 9 6.15625 9 10 L 9 13 L 6 13 L 6 29 L 26 29 L 26 13 L 23 13 L 23 10 C 23 6.15625 19.84375 3 16 3 Z M 16 5 C 18.753906 5 21 7.246094 21 10 L 21 13 L 11 13 L 11 10 C 11 7.246094 13.246094 5 16 5 Z M 8 15 L 24 15 L 24 27 L 8 27 Z"/></svg>
                      পাসওয়ার্ড পরিবর্তন
                    </Link>
                  </ListGroup.Item> */}
                  </> 
                  :
                  <>
                    
                    <ListGroup.Item className="ps-0 border-0">
                      <Link to="/login" className="btn btn-link no-underline p-0 py-1 fs-14">
                        <svg className="icon d-inline-block me-3" height={20} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><path d="M 16 4 C 10.421875 4 5.742188 7.832031 4.40625 13 L 6.46875 13 C 7.746094 8.945313 11.53125 6 16 6 C 21.515625 6 26 10.484375 26 16 C 26 21.515625 21.515625 26 16 26 C 11.53125 26 7.746094 23.054688 6.46875 19 L 4.40625 19 C 5.742188 24.167969 10.421875 28 16 28 C 22.617188 28 28 22.617188 28 16 C 28 9.382813 22.617188 4 16 4 Z M 15.34375 11.28125 L 13.90625 12.71875 L 16.1875 15 L 4 15 L 4 17 L 16.1875 17 L 13.90625 19.28125 L 15.34375 20.71875 L 19.34375 16.71875 L 20.03125 16 L 19.34375 15.28125 Z"/></svg>
                        লগ ইন
                      </Link>
                    </ListGroup.Item>
                    </>
                    }
                </ListGroup>
              </div>
            </Col>
        </Row>
    </Container>
  )
}

export default MoreNavigation;
