import React, { useEffect, useState, useContext } from 'react';
import { Link, useParams } from 'react-router-dom';
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import AppContext from '../../../context/AppContext';

// Component
import NoData from '../../status/NoData';

// Content Loader
import Catalog from '../../loader/Catalog';

import { getRentPostingType } from '../../api/Api';
import ButtonGoBack from '../../button/ButtonGoBack';
import { getIsAndroidAPKFromLS } from '../../../helpers/utility';



function RentPostType() {

  const { device } = useParams();


  const [rentPostingTypeList, setRentPostingTypeList] = useState([]);
  const [showContentLoader, setShowContentLoader] = useState(true);
  

  const { state } = useContext(AppContext);

  const [show, setShow] = useState(false);
  const[rentId, setRentId] = useState('');

  const[isLoadedFromAndroid, setIsLoadedFromAndroid] = useState(false);

  // Toast message
  const [showToast, setShowToast] = useState(false);
  const toggleShowToast = () => setShowToast(!showToast);

  const catalogComponents = [];

  for (let i = 0; i < 5; i++) {
    catalogComponents.push(<Catalog key={i} />);
  }

  const saveAndroidAPKTOLS = (androidData) => {
    try {
      // Convert the user data to a JSON string
      const androidDataString = JSON.stringify(androidData);
  
      // Store the JSON string in LocalStorage with a specific key
      localStorage.setItem('androidAPK', androidDataString);
    } catch (error) {
      // Handle errors, e.g., when LocalStorage is full or inoperative
      console.error('Error saving user data to LocalStorage:', error);
    }
  };

  const rentPostingType = () => {
    getRentPostingType()
    .then((response) => {
      console.log(response.data.data[0].rentposttype);
      setRentPostingTypeList(response.data.data[0].rentposttype);
      setShowContentLoader(false);
    })
    .catch((error) => {
      console.error('API error:', error);
    });
  };

    // Check if the user agent contains "Android"
    const isWebViewOnAndroid = () => {
        const userAgent = navigator.userAgent || navigator.vendor || window.opera;
        return userAgent.includes('Android');
    };

    // Usage
    // if (isWebViewOnAndroid()) {
    //     console.log('Loaded inside WebView on Android device');
    // } else {
    //     console.log('Not loaded inside WebView on Android device');
    // }

    useEffect(() => {
      setIsLoadedFromAndroid(isWebViewOnAndroid());
    },[]);

  useEffect(() => {
    rentPostingType();
  }, []);

  useEffect(() => {
    // Check if the custom HTTP header 'X-From-APK' is present in the request headers
    const fromApk = !!navigator.userAgent.includes('Android') && navigator.userAgent.includes('wv');

    if (fromApk) {
      // dispatch({ type: 'SET_ANDROID_APK', payload: true });
      // Perform actions specific to APK loading
      saveAndroidAPKTOLS(true);
    } else {
      // setAndroidAPK('Website is loaded from a mobile browser');
      // Perform actions specific to mobile browser loading
      saveAndroidAPKTOLS(false);
    }
  }, []);

  const typesAll = rentPostingTypeList.map((item) =>
    <Card className="text-start rent-posting-type mb-3 mb-md-4 py-2 px-3 overflow-hidden" key={item.rentsegment}>
      <Link className="p-0 text-start border-0 btn-card position-relative" to={
        `${item.rentsegment === 0 ? "/rent-posting-property-details/" 
          : item.rentsegment === 1 ? "/rent-posting-looking-details/" 
          : item.rentsegment === 2 || 3 || 4 || 5 || 10 ? `/rent-posting-common-amenities/${item.rentsegment}/${item.flattype}/`
          : null}`
      }>
        <div className="d-flex justify-content-between align-items-center">
            <div className="d-flex justify-content-center align-items-center position-relative">
              <Card.Img className="w-auto me-3 icon" src={item.imgfg} />
              <img className="position-absolute card-bg" alt="bg img" src={item.imgbg} />
              <Card.Body className="ms-2">
                <Card.Title className="mb-2">{item.title}</Card.Title>
                <Card.Subtitle className='mb-0'>{item.subtitle}</Card.Subtitle>
              </Card.Body>
            </div>
            <div>
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="12" cy="12" r="12" fill="#F04B5B"/>
                <path d="M13.0135 16L12.3895 15.3761L15.3317 12.4339L6.62664 12.4339L6.62664 11.5661L15.3317 11.5661L12.3895 8.62393L13.0134 8L16.7015 11.688L17 12L16.7015 12.312L13.0135 16Z" fill="white"/>
              </svg>
            </div>
          </div>
        </Link>
    </Card>
  );


  return (
    <Container>
          <Row>
            <Col md="3">
              {getIsAndroidAPKFromLS() === false &&
                <ButtonGoBack targetPath="/"/>
              }
            </Col>
            <Col md="6">
              <div className={`content-section pt-0 pt-md-4 ps-0 ps-md-4 ${device === undefined ? 'mt-m-big' : 'mt-3'}`}>
                  {
                    showContentLoader ? 
                      catalogComponents : 
                        rentPostingTypeList.length > 0 ?
                          typesAll : <NoData />
                  }
              </div>
            </Col>
        </Row>


    </Container>
  )
}

export default RentPostType;
