import { Button } from 'react-bootstrap';
// export const addEllipsis = (str, maxLength) => {
//   if (str.length > maxLength) {
//     return str.substring(0, maxLength) + '...';
//   }
//   return str;
// };

export const getUserDataFromLS = (property) => {
  const userDataString = localStorage.getItem('userData');

  if (userDataString) {
    const parsedUserData = JSON.parse(userDataString);

    // Check if the requested property exists in the user data
    if (parsedUserData.hasOwnProperty(property)) {
      return parsedUserData[property];
    }
  }

  // If userDataString is not present or the property is not found, return null
  return null;
};

// Update user data
export const updateLocalStorageProperty = (propertyName, newValue) => {
  // Retrieve the user data from localStorage
  const userDataString = localStorage.getItem('userData');
  
  // Check if user data exists in localStorage
  if (userDataString) {
    // Parse the JSON string back into an object
    const userData = JSON.parse(userDataString);

    // Update the specified property with the new value
    userData[propertyName] = newValue;

    // Convert the updated userData object back to a JSON string
    const updatedUserDataString = JSON.stringify(userData);

    // Store the updated data back in localStorage
    localStorage.setItem('userData', updatedUserDataString);
  }
};

// Example usage to update 'uname'
// updateLocalStorageProperty('uname', 'NewUsername');

// Example usage to update 'uphoto'
// updateLocalStorageProperty('uphoto', 'NewPhoto.jpg');

export const handleLogOut = () => {
  localStorage.removeItem('userData');
  window.location.href = '/';
};

export const convertNumberEn2Bn = (input) => {
  var numbers = {
    0: "০",
    1: "১",
    2: "২",
    3: "৩",
    4: "৪",
    5: "৫",
    6: "৬",
    7: "৭",
    8: "৮",
    9: "৯"
  };

  var output = [];
  for (var i = 0; i < input.length; ++i) {
    var char = input.charAt(i);
    output.push(isNaN(char) ? char : numbers[char]);
  }
  return output.join("");
};


export const getCroppedImg = async (imageSrc, pixelCrop) => {
  const image = new Image();
  image.src = imageSrc;

  return new Promise((resolve, reject) => {
    image.onload = () => {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');

      canvas.width = pixelCrop.width;
      canvas.height = pixelCrop.height;

      ctx.drawImage(
        image,
        pixelCrop.x,
        pixelCrop.y,
        pixelCrop.width,
        pixelCrop.height,
        0,
        0,
        pixelCrop.width,
        pixelCrop.height
      );

      canvas.toBlob(
        (blob) => {
          const reader = new FileReader();
          reader.readAsDataURL(blob);
          reader.onloadend = () => {
            const base64data = reader.result;
            resolve(base64data);
          };
        },
        'image/jpeg',
        1
      );
    };

    image.onerror = (error) => {
      reject(error);
    };
  });
};


export const formattedDate = (date) => {
  return date.toISOString().split('T')[0];
}

export const getIsAndroidAPKFromLS = (property) => {
  const androidDataString = localStorage.getItem('androidAPK');

  if (androidDataString) {
    const parsedAndroidData = JSON.parse(androidDataString);
    return parsedAndroidData;
  }

  // If userDataString is not present or the property is not found, return null
  return null;
};