import React from 'react';
import Button from 'react-bootstrap/Button';
import { Link } from 'react-router-dom';

function ButtonGoBack(props) {
    const goBack = () => {
      // console.log(props.targetPath);
      if (props.targetPath !== undefined) {
        if (props.rentid !== undefined) {
            window.location.href = `${props.targetPath}/${props.rentid}`;
        } else {
          window.location.href = props.targetPath;
        }
      } else {
        window.history.back();
      }
    };
  return (
    <Button variant="link" className="p-0 position-fixed btn-pcls bg-transparent" onClick={goBack}>
        <svg class="icon" width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect width="36" height="36" rx="18" fill="#EBEBEB"/>
          <path d="M20.2734 9.21094L12.0234 17.4609L11.5078 18L12.0234 18.5391L20.2734 26.7891L21.3516 25.7109L13.6406 18L21.3516 10.2891L20.2734 9.21094Z" fill="black"/>
        </svg>
    </Button>
    // <Link 
    //   to={`${
    //     props.targetPath !== undefined && 
    //     props.rentid !== undefined ?
    //     props.targetPath+'/'+props.rentid :
    //     props.targetPath
    //   }`}
    //   variant="link" className="p-0 position-fixed btn-pcls">
    //     <svg class="icon" width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    //       <rect width="36" height="36" rx="18" fill="#EBEBEB"/>
    //       <path d="M20.2734 9.21094L12.0234 17.4609L11.5078 18L12.0234 18.5391L20.2734 26.7891L21.3516 25.7109L13.6406 18L21.3516 10.2891L20.2734 9.21094Z" fill="black"/>
    //     </svg>
    // </Link>
  )
}

export default ButtonGoBack;
