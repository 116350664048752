import React, { useEffect, useState, useContext } from 'react';
import { Link } from 'react-router-dom';

import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ListGroup from 'react-bootstrap/ListGroup';
import Badge from 'react-bootstrap/Badge';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';

import AppContext from '../../../context/AppContext';

// Component
import Header from '../../layout/Header';
import NoData from '../../status/NoData';
import Modal from 'react-bootstrap/Modal';

// Content Loader
import Catalog from '../../loader/Catalog';

import { getUserDataFromLS } from '../../../helpers/utility'

import { getUserPublishedRents, rentUnpublish, rentPublish, rentDelete } from '../../api/Api'; // Import your API functions
import { Button } from 'react-bootstrap';



function MyAccount() {
  const [rentList, setRentList] = useState([]);
  const [showContentLoader, setShowContentLoader] = useState(true);
  const[rentId, setRentId] = useState('');
  const[reason, setReasone] = useState(1);

  const[androidAPK, setAndroidAPK] = useState('');

  const { state, dispatch } = useContext(AppContext);

  const handleSelectChangeReason = (event) => {
    const selectedValue = event.target.value;
    setReasone(selectedValue);
  };

  // Modal control
  const [modalAction, setModalAction] = useState('');
  const [show, setShow] = useState(false);
  const [showModalRP, setShowModalRP] = useState(false);
  const [showModalRD, setShowModalRD] = useState(false);

  function addEllipsis(str, maxLength) {
    if (str.length > maxLength) {
      return str.substring(0, maxLength) + '...';
    }
    return str;
  }


  const catalogComponents = [];

  for (let i = 0; i < 5; i++) {
    catalogComponents.push(<Catalog key={i} />);
  }

  const handleClose = () =>  {
    show && setShow(false);
    showModalRP && setShowModalRP(false);
  }
  

  const userPublishedRents = () => {
    getUserPublishedRents(getUserDataFromLS("userid"))
    .then((response) => {
      response.data.success !== 0 ? setRentList(response.data.data) : setRentList([]);
      setShowContentLoader(false);
    })
    .catch((error) => {
      console.error('API error:', error);
    });
  };

  useEffect(() => {
    userPublishedRents();
  }, []);

  // useEffect(() => {
  //   // Check if the custom HTTP header 'X-Device-Type' is present in the request headers
  //   const fromApk = document.head.querySelector('meta[name="X-Device-Type"]')?.content;

  //   if (fromApk === 'Android APK') {
  //     console.log('Website is loaded from the Android APK');
  //     setAndroidAPK(fromApk);
  //     // Perform actions specific to APK loading
  //   } else {
  //     console.log('Website is loaded from a mobile browser');
  //     // Perform actions specific to mobile browser loading
  //   }
  // }, []);

    useEffect(() => {
      // Check if the custom HTTP header 'X-From-APK' is present in the request headers
      const fromApk = !!navigator.userAgent.includes('Android') && navigator.userAgent.includes('wv');

      if (fromApk) {
        setAndroidAPK('Website is loaded from the Android APK');
        dispatch({ type: 'SET_ANDROID_APK', payload: true });
        // Perform actions specific to APK loading
      } else {
        // setAndroidAPK('Website is loaded from a mobile browser');
        // Perform actions specific to mobile browser loading
      }
    }, []);


  const handleRent = (type, rentid) => {
    if (type === "unpublish") {
      setShow(true);
      setRentId(rentid);
      setModalAction("unpublish");
    }

    if (type === "publish") {
      setShow(true)
      setRentId(rentid);
      setModalAction("publish")
    }

    if (type === "delete") {
      setShow(true)
      setRentId(rentid);
      setModalAction("delete")
    }
  }

  const handleSubmit = (type) => {
    setShowContentLoader(true);

    type === 'unpublish' &&
    rentUnpublish(getUserDataFromLS("userid"), rentId, reason)
      .then((response) => {
        handleClose();
        userPublishedRents();
      })
      .catch((error) => {
        console.error('API error:', error);
      });

      type === 'publish' &&
      rentPublish(getUserDataFromLS("userid"), rentId)
      .then((response) => {
        handleClose();
        userPublishedRents();
      })
      .catch((error) => {
        console.error('API error:', error);
      });

      type === 'delete' &&
      rentDelete(getUserDataFromLS("userid"), rentId, reason)
      .then((response) => {
        handleClose();
        userPublishedRents();
      })
      .catch((error) => {
        console.error('API error:', error);
      });

  }


  const rentsAll = rentList.map((item) =>
    <Card className="border-0 text-start rounded-0 rent-default" key={item.rentid}>
    {/* <Link className="p-0 text-start border-0 btn-card" variant="" to={`/bn/rent/${item.rentid}`}> */}
    <Link className="p-0 text-start border-0 btn-card position-relative" variant="">
      
      <div className="img-wrapper position-relative">
        <Card.Img variant="top" src={item.thumb} />

        <div className="thumb-wrapper position-absolute">
          <ListGroup className="d-inline-block">
            {item.totalimages !== 0 &&
              <ListGroup.Item className="bg-transparent border-0 p-0 d-inline-block me-2">
                <div className="thumb-count text-white">
                <svg className="icon me-1" height="16" width="16" viewBox="0 0 32 32"><path fill="#ffffff" d="M 2 5 L 2 27 L 30 27 L 30 5 Z M 4 7 L 28 7 L 28 20.90625 L 22.71875 15.59375 L 22 14.875 L 17.46875 19.40625 L 11.71875 13.59375 L 11 12.875 L 4 19.875 Z M 24 9 C 22.894531 9 22 9.894531 22 11 C 22 12.105469 22.894531 13 24 13 C 25.105469 13 26 12.105469 26 11 C 26 9.894531 25.105469 9 24 9 Z M 11 15.71875 L 20.1875 25 L 4 25 L 4 22.71875 Z M 22 17.71875 L 28 23.71875 L 28 25 L 23.03125 25 L 18.875 20.8125 Z"/></svg>
                  {item.totalimages}
                </div>
              </ListGroup.Item>
            }

            <ListGroup.Item className="bg-transparent border-0 p-0 d-inline-block">
              <div className="thumb-count text-white">
                <svg className="icon me-1" height="16" width="16" viewBox="0 0 24 24"><path fill="#ffffff" d="M23.5,17L18.5,22L15,18.5L16.5,17L18.5,19L22,15.5L23.5,17M12,9A3,3 0 0,1 15,12A3,3 0 0,1 12,15A3,3 0 0,1 9,12A3,3 0 0,1 12,9M12,4.5C17,4.5 21.27,7.61 23,12C22.75,12.65 22.44,13.26 22.08,13.85C21.5,13.5 20.86,13.25 20.18,13.12L20.82,12C19.17,8.64 15.76,6.5 12,6.5C8.24,6.5 4.83,8.64 3.18,12C4.83,15.36 8.24,17.5 12,17.5L13.21,17.43C13.07,17.93 13,18.46 13,19V19.46L12,19.5C7,19.5 2.73,16.39 1,12C2.73,7.61 7,4.5 12,4.5Z"></path></svg>
                {item.viewcount}
              </div>
            </ListGroup.Item>
          </ListGroup>

          
          
          </div>
      </div>
      
      
      <Card.Body className="px-0 pb-0 mb-3">
        {item.status === 'live' ?
            <Badge bg="danger" className="mb-2">লাইভ</Badge> :
            item.status === 'unpublished' ? <Badge bg="dark" className="mb-2">অপ্রকাশিত</Badge>: 
            item.status === 'drafted' ? <Badge bg="dark" className="mb-2">অসম্পূর্ণ</Badge>: 
            null
        }
        <Card.Title className="mb-2">{addEllipsis(item.title, 50)}</Card.Title>
        <Card.Subtitle className='mb-2'>{item.location !== null && addEllipsis(item.location, 40)}</Card.Subtitle>
        <Card.Subtitle className='mb-2'>{item.month}</Card.Subtitle>
        <Card.Text>{item.cost} {item.costduration}</Card.Text>
      </Card.Body>
      </Link>
      
      <div className="d-flex">
        <Link className="btn btn-light btn-sm me-2" 
        to={`${
            item.rentsegment === "0" ? `/rent-posting-property-details/${item.rentid}`
          : item.rentsegment === "1" ? `/rent-posting-looking-details/${item.rentid}` 
          : item.rentsegment === "2" || "3" || "4" || "5" || "10" ? `/rent-posting-common-amenities/${item.rentsegment}/${item.typeenglish}/${item.rentid}`
          : null}`}>
          <svg className="icon" width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10.9375 1.76367C10.6025 1.76367 10.2744 1.89355 10.0215 2.14648L5.6875 6.4668L5.5918 6.5625L5.56445 6.69922L5.26367 8.23047L5.12695 8.87305L5.76953 8.73633L7.30078 8.43555L7.4375 8.4082L7.5332 8.3125L11.8535 3.97852C12.3577 3.47437 12.3577 2.65063 11.8535 2.14648C11.6006 1.89355 11.2725 1.76367 10.9375 1.76367ZM10.9375 2.61133C11.04 2.61133 11.1409 2.66431 11.2383 2.76172C11.4331 2.95654 11.4331 3.16846 11.2383 3.36328L7 7.60156L6.24805 7.75195L6.39844 7L10.6367 2.76172C10.7341 2.66431 10.835 2.61133 10.9375 2.61133ZM1.75 3.5V12.25H10.5V6.48047L9.625 7.35547V11.375H2.625V4.375H6.64453L7.51953 3.5H1.75Z" fill="black"/>
          </svg>
          এডিট করুন 
          {/* {console.log(item.rentsegment === "1")} */}
        </Link>
        {item.status === 'live' &&
          
          <Button variant="light" className="btn-sm btn-outline me-2" onClick={()=> handleRent("unpublish", item.rentid)}>
            <svg className="icon" width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M2.625 2.625V11.375H11.375V2.625H2.625ZM3.5 3.5H10.5V10.5H3.5V3.5Z" fill="black"/>
            </svg> 
            আন পাবলিশ
          </Button>
        }

        {item.status === 'unpublished' &&
          <Button variant="light" className="btn-sm btn-outline me-2" onClick={()=> handleRent("publish", item.rentid)}>
            <svg className="icon" width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M8.4082 2.31055L7.7793 2.93945L10.5273 5.6875H4.8125C3.13086 5.6875 1.75 7.06836 1.75 8.75V11.8125H2.625V8.75C2.625 7.54517 3.60767 6.5625 4.8125 6.5625H10.5273L7.7793 9.31055L8.4082 9.93945L11.9082 6.43945L12.209 6.125L11.9082 5.81055L8.4082 2.31055Z" fill="black"/>
            </svg>
            পুনঃ পাবলিশ করুন
          </Button>
        }
        
        <Button variant="light" className="btn-sm btn-outline" onClick={()=> handleRent("delete", item.rentid)}>
          <svg className="icon" width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.125 1.75C5.896 1.75 5.66187 1.83032 5.49609 1.99609C5.33032 2.16187 5.25 2.396 5.25 2.625V3.0625H2.1875V3.9375H2.66602L3.5 11.8535L3.54102 12.25H10.459L10.5 11.8535L11.334 3.9375H11.8125V3.0625H8.75V2.625C8.75 2.396 8.66968 2.16187 8.50391 1.99609C8.33813 1.83032 8.104 1.75 7.875 1.75H6.125ZM6.125 2.625H7.875V3.0625H6.125V2.625ZM3.55469 3.9375H10.4453L9.66602 11.375H4.33398L3.55469 3.9375ZM5.25 5.25V10.0625H6.125V5.25H5.25ZM6.5625 5.25V10.0625H7.4375V5.25H6.5625ZM7.875 5.25V10.0625H8.75V5.25H7.875Z" fill="black"/>
          </svg>
          মুছে ফেলুন
        </Button>
      </div>
      
      <hr className="my-4 bg-light"/>
    </Card>
  );


  return (
    <Container>
          {/* {androidAPK !== '' &&
            <Row>
              <p>{androidAPK}</p>
            </Row>
          } */}
          <Row>
            {state.androidAPK === false &&
              <Col md="3">
                <Header />
              </Col>
            }
            <Col md="6">
                <div className="content-section pt-3 pt-md-4 ps-md-4">
                  {
                    showContentLoader ? 
                      catalogComponents : 
                        rentList.length > 0 ?
                          rentsAll : <NoData />
                  }
              </div>
            </Col>
        </Row>
        <Modal show={show} onHide={handleClose}>
          <Modal.Header>
            <Button variant="link" className="p-0" onClick={handleClose}>
              <svg height="24" width="24" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><path d="M 7.21875 5.78125 L 5.78125 7.21875 L 14.5625 16 L 5.78125 24.78125 L 7.21875 26.21875 L 16 17.4375 L 24.78125 26.21875 L 26.21875 24.78125 L 17.4375 16 L 26.21875 7.21875 L 24.78125 5.78125 L 16 14.5625 Z"/></svg>
            </Button>
            <Modal.Title className="fs-20 m-auto w-100 text-center">
              {
                modalAction === "unpublish" ? "বিজ্ঞাপনটি আন পাবলিশ করুন" :
                modalAction === "publish" ? "বিজ্ঞাপনটি পাবলিশ করুন" :
                modalAction === "delete" ? "বিজ্ঞাপনটি মুছে ফেলুন" : null
              }
              
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {
              modalAction === "unpublish" || modalAction === "delete" ?
              <>
              <label className="fs-14 fw-md mb-1" for="reasone">একটি কারণ নির্বাচন করুন</label>
              <FloatingLabel
                controlId="floatingSelectGrid"
                label="কারণ"
              >
                <Form.Select className="mb-4" aria-label="Default select example" id="reasone" value={reason} onChange={handleSelectChangeReason}>
                  <option value="1">ভাড়া হয়ে গিয়েছে</option>
                  <option value="2">অনন্যা</option>
                </Form.Select>
              </FloatingLabel>
              </>
              :
              modalAction === "publish" ? 
              <Alert variant="info">
                <p className="m-0">
                  বিজ্ঞাপনটি পুনঃ পাবলিশ করার জন্য আপনি কি নিশ্চিত?
                </p>
              </Alert>
              :
              modalAction === "delete" ? 
              <Alert variant="info">
                <p className="m-0">
                  বিজ্ঞাপনটি মুছে ফেলার জন্য আপনি কি নিশ্চিত?
                </p>
              </Alert>
              : null
            }
          
          </Modal.Body>

          <Modal.Footer>
            <Button variant="dark" onClick={() => 
              modalAction === "unpublish" ? handleSubmit('unpublish') :
              modalAction === "publish" ? handleSubmit('publish') :
              modalAction === "delete" ? handleSubmit('delete') : null
            }
            >
              সাবমিট
            </Button>
          </Modal.Footer>
        </Modal>
    </Container>
  )
}

export default MyAccount;
