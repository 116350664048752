import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import Container from 'react-bootstrap/Container';

import houseImage from '../../../src/images/house.svg';


function RentPostSuccess(props) {

  const { rentid } = useParams();
  

  useEffect(() => {
  }, []);

  return (
    <Container>
        <div className="text-center">
            <img src={houseImage} alt={`location search`} className="mb-4 m-auto d-inline-block" width={180} />
            <h2 className="fs-24">অভিনন্দন!</h2>
            <p className="mb-4">আপনার বিজ্ঞাপনটি সফল ভাবে পাবলিশ করা হয়েছে</p>
            <Link to={`/bn/rent/${rentid}`} className="btn-rounded btn-primary">
                বিজ্ঞাপনটি দেখুন
            </Link>
        </div>
    </Container>
  )
}

export default RentPostSuccess;
