import React, { useEffect, useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useParams } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';

import AppContext from '../../../context/AppContext';

import buildingImage from '../../../../src/images/building.svg';

// Content Loader
import Catalog from '../../loader/Catalog';

import { formattedDate, getUserDataFromLS, getIsAndroidAPKFromLS } from '../../../helpers/utility';

import { getRentPostingType, rentPostingStepTwo, getRentPostingEditInfo } from '../../api/Api'; // Import your API functions
import ButtonGoBack from '../../button/ButtonGoBack';
import LocationSearch from '../../../helpers/LocationSearch';




function RentPropertyStepTwo() {

  const { rentid } = useParams();

  const [rentPostingTypeList, setRentPostingTypeList] = useState([]);
  const [showContentLoader, setShowContentLoader] = useState(true);
  const [startDate, setStartDate] = useState(new Date());

  // Spinner
  const [spinner, setSpinner] = useState(false);
  

  const { state, dispatch } = useContext(AppContext);

  const [show, setShow] = useState(false);
  const [rentpostid, setRentPostId] = useState('');

  const [lift, setLift] = useState(0);
  const [generator, setGenerator] = useState(0);
  const [guard, setGuard] = useState(0);
  const [parking, setParking] = useState(0);
  const [gas, setGas] = useState(0);
  const [wifi, setWifi] = useState(0);
  
  const [rentcost, setRentcost] = useState('');
  const [negotiate, setNegotiate] = useState(false);
  const [sqrfeet, setSqrfeet] = useState('');
  const [electricbill, setElectricBill] = useState('');
  const [gasbill, setGasBill] = useState('');
  const [waterbill, setWaterBill] = useState('');
  const [servicecharge, setServiceCharge] = useState('');
  const [rentdate, setRentDate] = useState(new Date());
  const [rentenddate, setRentEndDate] = useState(new Date());
  const [area, setArea] = useState('');
  const [locationName, setLocationName] = useState('');
  const [locationNameBangla, setLocationNameBangla] = useState('');
  const [locationSearchVisible, setLocationSearchVisible] = useState(false);
  const [address, setAddress] = useState('');
  const [description, setDescription] = useState('');
  // Religion
  const [islam, setIslam] = useState('');
  const [hindu, setHindu] = useState('');
  const [christian, setChristian] = useState('');
  const [budda, setBudda] = useState('');
  const [anyreligion, setAnyReligion] = useState('');

  // Toast message
  const [showToast, setShowToast] = useState(false);
  const toggleShowToast = () => setShowToast(!showToast);

  const fetchPostingEditInfo = () => {
    getRentPostingEditInfo(rentid)
      .then((response) => {
        // console.log(response.data.data[0].facilities[0].value);
        setLift(response.data.data[0].facilities[0].value);
        setGenerator(response.data.data[0].facilities[1].value);
        setParking(response.data.data[0].facilities[2].value);
        setGuard(response.data.data[0].facilities[3].value);
        setGas(response.data.data[0].facilities[4].value);
        setWifi(response.data.data[0].facilities[5].value);
        setRentcost(response.data.data[0].cost);
        setNegotiate(response.data.data[0].rentnegotiable);
        setSqrfeet(response.data.data[0].sqrfeet);
        setElectricBill(response.data.data[0].electricbill);
        setGasBill(response.data.data[0].gasbill);
        setWaterBill(response.data.data[0].waterbill);
        setServiceCharge(response.data.data[0].servicecharge);
        setRentDate(
          response.data.data[0].rentstartdate === null ?
          new Date() :
          new Date(response.data.data[0].rentstartdate)
        );
        setRentEndDate(
          response.data.data[0].rentstartdate === null ?
          new Date() :
          new Date(response.data.data[0].postdeadline)
        );
        setArea(response.data.data[0].areaname);
        setAddress(response.data.data[0].location);
        setDescription(response.data.data[0].description);
        // setAnyReligion(response.data.data[0].religion);
        const religion = response.data.data[0].religion;
        const religionArray = religion.split(',').filter(Boolean);

        {religionArray.map((religion, index) => {
          if (religion === 'islam') {
            setIslam('islam,');
          }
          if (religion === 'hindu') {
            setHindu('hindu,');
          }
          if (religion === 'christian') {
            setChristian('christian,');
          }
          if (religion === 'budda') {
            setBudda('budda,');
          }
          if (religion === 'any') {
            setAnyReligion('any,');
          }
          return null; // or some other JSX if needed
        })}


        
      
          dispatch({ type: 'UPDATE_LOCATION_AREA', payload: response.data.data[0].area});
          dispatch({ type: 'UPDATE_LOCATION_AREA_NAME', payload: response.data.data[0].areaname});
          

        
      })
      .catch((error) => {
          console.error('API error:', error);
      });
  };

  const handleInputChange = (event, type) => {
    
    const newValue = event.target.value;

    const stateSetterMap = {
      'rentcost': setRentcost,
      'negotiate': setNegotiate,
      'sqrfeet': setSqrfeet,
      'electricbill': setElectricBill,
      'gasbill': setGasBill,
      'waterbill': setWaterBill,
      'servicecharge': setServiceCharge,
      'rentdate': setRentDate,
      'rentenddate': setRentEndDate,
      'area': setArea,
      'locationName': setLocationName,
      'locationNameBangla': setLocationNameBangla,
      'address': setAddress,
      'description': setDescription,
    };

    const setState = stateSetterMap[type];

    // Call the state setter function with the toggled value if it exists
    if (setState) {
      setState(newValue);
    }
  };


  function handleBtnToggle(type) {

    const stateGetterMap = {
      'lift': lift,
      'generator': generator,
      'guard': guard,
      'parking': parking,
      'gas': gas,
      'wifi': wifi,
    };

    const stateSetterMap = {
      'lift': setLift,
      'generator': setGenerator,
      'guard': setGuard,
      'parking': setParking,
      'gas': setGas,
      'wifi': setWifi,
    };

    const currentState = stateGetterMap[type];
    const toggleValue = currentState === 1 ? 0 : 1;

    const setState = stateSetterMap[type];

    // Call the state setter function with the toggled value if it exists
    if (setState) {
      setState(toggleValue);
    }
  }

  const handleBtnAnyReligion = () => {
    setAnyReligion(1);
    setIslam(0);
    setHindu(0);
    setChristian(0);
    setBudda(0);
  };

  const handleChip = (value) => {
    // eslint-disable-next-line default-case
    switch (value) {
        // Rent type chip controls
        case 'islam':
            islam === '' ? setIslam(`${value},`) : setIslam('');
            setAnyReligion('');
            break;
        case 'hindu':
            hindu === '' ? setHindu(`${value},`) : setHindu('');
            setAnyReligion('');
            break;
        case 'christian':
            christian === '' ? setChristian(`${value},`) : setChristian('');
            setAnyReligion('');
            break;
        case 'budda':
            budda === '' ? setBudda(`${value},`) : setBudda('');
            setAnyReligion('');
            break;
        case 'any':
            anyreligion === '' ? setAnyReligion(`${value},`) : setAnyReligion('');
            setIslam('');
            setHindu('');
            setChristian('');
            setBudda('');
            break;
        // No need for default case, as you're handling specific cases above
        // default: null
    }
}



  const catalogComponents = [];

  for (let i = 0; i < 5; i++) {
    catalogComponents.push(<Catalog key={i} />);
  }


  const rentPostingType = () => {
    getRentPostingType()
    .then((response) => {
      // console.log(response.data.data[0].rentposttype);
      setRentPostingTypeList(response.data.data[0].rentposttype);
      setShowContentLoader(false);
    })
    .catch((error) => {
      console.error('API error:', error);
    });
  };



  useEffect(() => {
    setRentPostId(rentid);
    fetchPostingEditInfo(rentid);
  }, []);

  const handleNegotiate = () => {
    setNegotiate(!negotiate);
    setRentcost('');
  }

  const conditions = [];
    if (islam === true) {
      conditions.push('islam');
    }
    if (hindu === true) {
      conditions.push('hindu');
    }
    if (christian === true) {
      conditions.push('christian');
    }
    if (budda === true) {
      conditions.push('budda');
    }
    
    const rentType = conditions.join(',');
  

  const handleRentPosting = () => {
    setSpinner(true);
    console.log(
        getUserDataFromLS('userid'),
        getUserDataFromLS('uid'),
        rentpostid, 
        lift,
        generator,
        parking,
        guard,
        wifi,
        gas,
        electricbill,
        waterbill,
        gasbill,
        servicecharge,
        rentcost,
        address,
        state.locationArea,
        negotiate === true ? 1 : 0,
        formattedDate(rentdate),
        formattedDate(rentenddate),
        anyreligion !== '' ? anyreligion : islam + hindu + christian + budda,
        description,
        sqrfeet
      )

      rentPostingStepTwo(
        getUserDataFromLS('userid'), 
        getUserDataFromLS('uid'),
        rentpostid, 
        lift,
        generator,
        parking,
        guard,
        wifi,
        gas,
        electricbill,
        waterbill,
        gasbill,
        servicecharge,
        rentcost,
        address,
        state.locationArea,
        negotiate === true ? 1 : 0,
        formattedDate(rentdate),
        formattedDate(rentenddate),
        anyreligion !== '' ? anyreligion : islam + hindu + christian + budda,
        description,
        sqrfeet
      )
      .then((response) => {
        if (response.data.success === 1) {
          window.location.href = `/rent-posting-photos/${response.data.rentid}`;
        } else {
          // Handle the case when the response is not successful
          console.error("API request was not successful");
          // You might want to show an error message to the user or take other actions.
        }
      })
      .catch((error) => {
        console.error('API error:', error);
      });
  }

  const handleRentDate = (date) => {
    const fDate = formattedDate(date);
    console.log(fDate);
  }

  const handleClose = () => setShow(false);
  const handleShow = (rentid) => {
    setShow(true);
  };

  return (
    <Container>
          <Row>
          {getIsAndroidAPKFromLS() === false &&
              <Col md="3">
                <ButtonGoBack rentid={rentid} targetPath="/rent-posting-property-details"/>
              </Col>
            }
            <Col md="6">
              <div className="content-section pt-md-4 ps-md-4">
                  {/* {console.log(negotiate)} */}
                  {/* {rentpostid}, {getUserDataFromLS('userid')} {getUserDataFromLS('uid')} */}
                  <Form>
                    <div className="filters-all">
                      <h2 className="fs-18 mb-5 text-primary">অন্যান্য সুবিধা সমুহ</h2>
                      
                      <Row>
                        <Col md={4}>
                          <div className="mb-4 d-flex d-md-block justify-content-between align-items-center">
                            <h3 className="fs-14 fw-bold">লিফট সুবিধা *</h3>
                            <ButtonGroup aria-label="Lift toggle button" className="btn-toggle-group">
                              <Button variant="light" className={`fs-14 p-2 ${lift === 1 && 'active'}`} onClick={()=> handleBtnToggle('lift', 1)}>
                                  <svg className="icon me-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><path fill="#FFFFFF" d="M 28.28125 6.28125 L 11 23.5625 L 3.71875 16.28125 L 2.28125 17.71875 L 10.28125 25.71875 L 11 26.40625 L 11.71875 25.71875 L 29.71875 7.71875 Z"/></svg>
                                  আছে
                              </Button>
                              <Button variant="light" className={`fs-14 p-2 ${lift === 0 && 'active'}`} onClick={()=> handleBtnToggle('lift', 0)}>
                                  <svg className="icon me-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><path fill="#FFFFFF" d="M 28.28125 6.28125 L 11 23.5625 L 3.71875 16.28125 L 2.28125 17.71875 L 10.28125 25.71875 L 11 26.40625 L 11.71875 25.71875 L 29.71875 7.71875 Z"/></svg>
                                  নাই
                              </Button>
                            </ButtonGroup>
                          </div>
                        </Col>

                        <Col md={4}>
                          <div className="mb-4 d-flex d-md-block justify-content-between align-items-center">
                            <h3 className="fs-14 fw-bold">জেনেরেটর সুবিধা *</h3>
                            <ButtonGroup aria-label="Lift toggle button" className="btn-toggle-group">
                              <Button variant="light" className={`fs-14 p-2 ${generator === 1 && 'active'}`} onClick={()=> handleBtnToggle('generator', 1)}>
                                  <svg className="icon me-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><path fill="#FFFFFF" d="M 28.28125 6.28125 L 11 23.5625 L 3.71875 16.28125 L 2.28125 17.71875 L 10.28125 25.71875 L 11 26.40625 L 11.71875 25.71875 L 29.71875 7.71875 Z"/></svg>
                                  আছে
                              </Button>
                              <Button variant="light" className={`fs-14 p-2 ${generator === 0 && 'active'}`} onClick={()=> handleBtnToggle('generator', 0)}>
                                  <svg className="icon me-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><path fill="#FFFFFF" d="M 28.28125 6.28125 L 11 23.5625 L 3.71875 16.28125 L 2.28125 17.71875 L 10.28125 25.71875 L 11 26.40625 L 11.71875 25.71875 L 29.71875 7.71875 Z"/></svg>
                                  নাই
                              </Button>
                        </ButtonGroup>
                          </div>
                        </Col>

                        <Col md={4}>
                          <div className="mb-4 d-flex d-md-block justify-content-between align-items-center">
                            <h3 className="fs-14 fw-bold">সিকিউরিটি গার্ড *</h3>
                            <ButtonGroup aria-label="Lift toggle button" className="btn-toggle-group">
                              <Button variant="light" className={`fs-14 p-2 ${guard === 1 && 'active'}`} onClick={()=> handleBtnToggle('guard', 1)}>
                                  <svg className="icon me-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><path fill="#FFFFFF" d="M 28.28125 6.28125 L 11 23.5625 L 3.71875 16.28125 L 2.28125 17.71875 L 10.28125 25.71875 L 11 26.40625 L 11.71875 25.71875 L 29.71875 7.71875 Z"/></svg>
                                  আছে
                              </Button>
                              <Button variant="light" className={`fs-14 p-2 ${guard === 0 && 'active'}`} onClick={()=> handleBtnToggle('guard', 0)}>
                                  <svg className="icon me-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><path fill="#FFFFFF" d="M 28.28125 6.28125 L 11 23.5625 L 3.71875 16.28125 L 2.28125 17.71875 L 10.28125 25.71875 L 11 26.40625 L 11.71875 25.71875 L 29.71875 7.71875 Z"/></svg>
                                  নাই
                              </Button>
                            </ButtonGroup>
                          </div>
                        </Col>
                      </Row>

                      <Row>
                        <Col md={4}>
                          <div className="mb-4 d-flex d-md-block justify-content-between align-items-center">
                            <h3 className="fs-14 fw-bold">পার্কিং সুবিধা *</h3>
                            <ButtonGroup aria-label="Lift toggle button" className="btn-toggle-group">
                              <Button variant="light" className={`fs-14 p-2 ${parking === 1 && 'active'}`} onClick={()=> handleBtnToggle('parking', 1)}>
                                  <svg className="icon me-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><path fill="#FFFFFF" d="M 28.28125 6.28125 L 11 23.5625 L 3.71875 16.28125 L 2.28125 17.71875 L 10.28125 25.71875 L 11 26.40625 L 11.71875 25.71875 L 29.71875 7.71875 Z"/></svg>
                                  আছে
                              </Button>
                              <Button variant="light" className={`fs-14 p-2 ${parking === 0 && 'active'}`} onClick={()=> handleBtnToggle('parking', 0)}>
                                  <svg className="icon me-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><path fill="#FFFFFF" d="M 28.28125 6.28125 L 11 23.5625 L 3.71875 16.28125 L 2.28125 17.71875 L 10.28125 25.71875 L 11 26.40625 L 11.71875 25.71875 L 29.71875 7.71875 Z"/></svg>
                                  নাই
                              </Button>
                            </ButtonGroup>
                          </div>
                        </Col>
                        <Col md={4}>
                          <div className="mb-4 d-flex d-md-block justify-content-between align-items-center">
                            <h3 className="fs-14 fw-bold">গ্যাস সুবিধা *</h3>
                            <ButtonGroup aria-label="Lift toggle button" className="btn-toggle-group">
                              <Button variant="light" className={`fs-14 p-2 ${gas === 1 && 'active'}`} onClick={()=> handleBtnToggle('gas', 1)}>
                                  <svg className="icon me-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><path fill="#FFFFFF" d="M 28.28125 6.28125 L 11 23.5625 L 3.71875 16.28125 L 2.28125 17.71875 L 10.28125 25.71875 L 11 26.40625 L 11.71875 25.71875 L 29.71875 7.71875 Z"/></svg>
                                  আছে
                              </Button>
                              <Button variant="light" className={`fs-14 p-2 ${gas === 0 && 'active'}`} onClick={()=> handleBtnToggle('gas', 0)}>
                                  <svg className="icon me-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><path fill="#FFFFFF" d="M 28.28125 6.28125 L 11 23.5625 L 3.71875 16.28125 L 2.28125 17.71875 L 10.28125 25.71875 L 11 26.40625 L 11.71875 25.71875 L 29.71875 7.71875 Z"/></svg>
                                  নাই
                              </Button>
                            </ButtonGroup>
                          </div>
                        </Col>
                        <Col md={4}>
                          <div className="mb-4 d-flex d-md-block justify-content-between align-items-center">
                          <h3 className="fs-14 fw-bold">Wi-Fi সুবিধা *</h3>
                          <ButtonGroup aria-label="Lift toggle button" className="btn-toggle-group">
                            <Button variant="light" className={`fs-14 p-2 ${wifi === 1 && 'active'}`} onClick={()=> handleBtnToggle('wifi', 1)}>
                                <svg className="icon me-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><path fill="#FFFFFF" d="M 28.28125 6.28125 L 11 23.5625 L 3.71875 16.28125 L 2.28125 17.71875 L 10.28125 25.71875 L 11 26.40625 L 11.71875 25.71875 L 29.71875 7.71875 Z"/></svg>
                                আছে
                            </Button>
                            <Button variant="light" className={`fs-14 p-2 ${wifi === 0 && 'active'}`} onClick={()=> handleBtnToggle('wifi', 0)}>
                                <svg className="icon me-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><path fill="#FFFFFF" d="M 28.28125 6.28125 L 11 23.5625 L 3.71875 16.28125 L 2.28125 17.71875 L 10.28125 25.71875 L 11 26.40625 L 11.71875 25.71875 L 29.71875 7.71875 Z"/></svg>
                                নাই
                            </Button>
                          </ButtonGroup>
                          </div>
                        </Col>
                      </Row>
                      <div className="mb-4">
                        <FloatingLabel
                          controlId="floatingInput"
                          label="ভাড়ার পরিমান *"
                          className="mb-3"
                        >
                          <Form.Control type="text" placeholder="ভাড়ার পরিমাণ লিখুন" disabled={negotiate} value={rentcost} onChange={(event) => handleInputChange(event, 'rentcost')} />
                        </FloatingLabel>
                        <Form.Check
                          type="checkbox"
                          id="Default"
                          label="আলোচনার সাপেক্ষে"
                          checked={negotiate == 1}
                          onChange={()=> handleNegotiate()}
                        />
                      </div>

                      <div className="mb-4">
                        <FloatingLabel
                          controlId="floatingInput"
                          label="স্কয়ার ফিট"
                          className="mb-3"
                        >
                          <Form.Control type="text" placeholder="" value={sqrfeet} onChange={(event) => handleInputChange(event, 'sqrfeet')} />
                        </FloatingLabel>
                      </div>
                      <Row>
                        <Col md={6}>
                          <div className="mb-4">
                            <FloatingLabel
                              controlId="floatingInput"
                              label="বিদ্যুৎ বিল"
                              className="mb-3"
                            >
                              <Form.Control type="text" placeholder="" value={electricbill} onChange={(event) => handleInputChange(event, 'electricbill')} />
                            </FloatingLabel>
                          </div>
                        </Col>
                        <Col md={6}>
                          <div className="mb-4">
                            <FloatingLabel
                              controlId="floatingInput"
                              label="গ্যাস বিল"
                              className="mb-3"
                            >
                              <Form.Control type="text" placeholder="" value={gasbill} onChange={(event) => handleInputChange(event, 'gasbill')} />
                            </FloatingLabel>
                          </div>
                        </Col>
                        <Col md={6}>
                          <div className="mb-4">
                            <FloatingLabel
                              controlId="floatingInput"
                              label="পানির বিল"
                              className="mb-3"
                            >
                              <Form.Control type="text" placeholder="" value={waterbill} onChange={(event) => handleInputChange(event, 'waterbill')} />
                            </FloatingLabel>
                          </div>
                        </Col>
                        <Col md={6}>
                          <div className="mb-4">
                            <FloatingLabel
                              controlId="floatingInput"
                              label="সার্ভিস চার্জ"
                              className="mb-3"
                            >
                              <Form.Control type="text" placeholder="" value={servicecharge} onChange={(event) => handleInputChange(event, 'servicecharge')} />
                            </FloatingLabel>
                          </div>
                        </Col>
                      </Row>

                      <div className="mb-4">
                          <span class="fw-bold">কোন তারিখ থেকে ভাড়া হবে? *</span>
                          <DatePicker className="form-control w-100" selected={rentdate} onChange={(date) => setRentDate(date)} />
                          {console.log(rentdate)}
                      </div>

                      <div className="mb-4">
                          <span class="fw-bold">কোন তারিখ পর্যন্ত বিজ্ঞাপনটি সাইটে থাকবে? *</span>
                          <DatePicker className="form-control w-100" selected={rentenddate} onChange={(date) => setRentEndDate(date)} />
                      </div>

                      <Modal show={show} onHide={handleClose} className="bg-blur modal-location">
                        <Modal.Body className="p-3">
                          <div className="text-center">
                            <img src={buildingImage} alt={`location search`} className="mb-4 m-auto d-inline-block" width={250} />
                          </div>
                          <LocationSearch className="mt-5"/>
                        </Modal.Body>

                        <Modal.Footer className="d-flex justify-content-between border-0 pt-0" onClick={()=>handleClose()}>
                          <Button variant="primary" className="btn-dark">
                            সেট করুন
                          </Button>
                        </Modal.Footer>
                      </Modal>


                      <div className="mb-4" onClick={() => setShow(true)}>
                          <FloatingLabel
                            controlId="floatingInput"
                            label="এলাকা *"
                            className="mb-3"
                          >
                            <Form.Control type="text" placeholder="" value={state.locationAreaName} />
                          </FloatingLabel>
                        </div>

                      <div className="mb-4">
                        <FloatingLabel
                          controlId="floatingTextarea"
                          label=" বাসার ঠিকানা *"
                          className="mb-3"
                        >
                          <Form.Control as="textarea" placeholder="Leave a comment here" value={address} onChange={(event) => handleInputChange(event, 'address')} />
                        </FloatingLabel>
                      </div>

                      <div className="mb-4">
                        <FloatingLabel
                          controlId="floatingTextarea"
                          label=" বাসার বর্ণনা *"
                          className="mb-3"
                        >
                          <Form.Control as="textarea" placeholder="Leave a comment here" value={description} onChange={(event) => handleInputChange(event, 'description')} />
                        </FloatingLabel>
                      </div>

                      <div className="mb-5">
                        <h3 className="fs-14 mb-3 fw-bold">কোন ধর্মের ভাড়াটিয়া চান? *</h3>
                        <Button variant="light" className={`fs-14 btn-light me-2 ${islam === "islam," && 'active'}`} onClick={()=>handleChip('islam')}>ইসলাম</Button>
                        <Button variant="light" className={`fs-14 btn-light me-2 ${hindu === "hindu," && 'active'}`} onClick={()=>handleChip('hindu')}>হিন্দু</Button>
                        <Button variant="light" className={`fs-14 btn-light me-2 ${christian === "christian," && 'active'}`} onClick={()=>handleChip('christian')}>খ্রিষ্টান</Button>
                        <Button variant="light" className={`fs-14 btn-light me-2 ${budda === "budda," && 'active'}`} onClick={()=>handleChip('budda')}>বৌদ্ধ</Button>
                        <Button variant="light" className={`fs-14 btn-light ${anyreligion === "any," && 'active'}`} onClick={()=>handleChip('any')}>যেকোনো</Button>
                      </div>

                      


                      <Button variant="primary" disabled={spinner} className="btn-rounded w-100" onClick={()=> handleRentPosting()}>
                        {spinner === true ?
                          <Spinner animation="border" role="status" size="sm">
                            <span className="visually-hidden">Loading...</span>
                          </Spinner>
                          :
                          'পরবর্তী ধাপ'
                        }
                      </Button>
                    </div>
                  </Form>
                </div>
            </Col>
        </Row>


    </Container>
  )
}

export default RentPropertyStepTwo;
