import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import { Link } from 'react-router-dom';
import Spinner from 'react-bootstrap/Spinner';
import { handleLogOut } from '../../../helpers/utility';

function LogOut() {

  useEffect(() => {
    handleLogOut();
    return () => {
    };
  }, []);



  return (
    <>
      <Container className="spinner-fixed-container d-flex justify-content-center align-items-center text-center h-100">
            <Spinner animation="border" role="status" size="sm">
                <span className="visually-hidden">Loading...</span>
            </Spinner>
      </Container>
    </>
  );
}

export default LogOut;
